import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import moment from "moment";
import { getVoucherList } from "@/api/beacon";

const statusList: any = {
  waitValid: "待生效",
  valid: "生效中",
  expired: "已过期",
  used: "已使用",
};
const columns: ColumnsType<any> = [
  {
    title: "客户Id",
    key: "grantTo",
    dataIndex: "grantTo",
    width: 100,
  },
  {
    title: "代金券id",
    key: "id",
    dataIndex: "id",
    width: 100,
  },
  // {
  //   title: '代金模板券id',
  //   key: 'voucherTemplate',
  //   dataIndex: "voucherTemplate",
  //   width: 100
  // },
  {
    title: "代金券名称",
    key: "name",
    dataIndex: "name",
    width: 100,
  },
  {
    title: "生效日期",
    key: "effectDate",
    dataIndex: "effectDate",
    width: 100,
    render: (_: any, record: any) => {
      return (
        <div>
          {record.effectDate && !isNaN(Number(record.effectDate))
            ? moment
                .unix(Number(record.effectDate))
                .format("YYYY-MM-DD HH:mm:ss")
            : ""}
        </div>
      );
    },
  },
  {
    title: "失效日期",
    key: "expiryDate",
    dataIndex: "expiryDate",
    width: 100,
    render: (_: any, record: any) => {
      return (
        <div>
          {record.expiryDate && !isNaN(Number(record.expiryDate))
            ? moment
                .unix(Number(record.expiryDate))
                .format("YYYY-MM-DD HH:mm:ss")
            : ""}
        </div>
      );
    },
  },
  {
    title: "余额",
    key: "balance",
    dataIndex: "balance",
    width: 100,
    render: (_: any, record: any) => {
      return <div>${Number(record.balance) / 10000}</div>;
    },
  },
  {
    title: "原始金额",
    key: "originalValue",
    dataIndex: "originalValue",
    width: 100,
    render: (_: any, record: any) => {
      return <div>${Number(record.originalValue) / 10000}</div>;
    },
  },
  {
    title: "状态",
    key: "status",
    dataIndex: "status",
    width: 100,
    render: (_: any, record: any) => {
      return <div>{statusList[record.status] || ""}</div>;
    },
  },
  {
    title: "发放人",
    key: "grantor",
    dataIndex: "grantor",
    width: 100,
  },
  {
    title: "发放时间",
    key: "grantTime",
    dataIndex: "grantTime",
    width: 100,
    render: (_: any, record: any) => {
      return (
        <div>
          {record.grantTime && !isNaN(Number(record.grantTime))
            ? moment
                .unix(Number(record.grantTime))
                .format("YYYY-MM-DD HH:mm:ss")
            : ""}
        </div>
      );
    },
  },
];

export default function VoucherList({
  templateId = null,
}: {
  templateId: any;
}) {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    getVoucherList({ templateId }).then((res: any) => {
      console.log("templateId res:", res);
      setTableData(res.data || []);
    });
  }, []);
  return (
    <div style={{ width: "100%", overflow: "auto" }}>
      <Table
        columns={columns}
        dataSource={tableData}
        scroll={{ x: 1300 }}
      ></Table>
    </div>
  );
}
