export const Feishu_Uid_Map: Record<string, string> = {
  ou_3941d4cce0bc4f3fd28c2903b529b19f: "桃子",
  ou_75a6102ce61be3d62be9336506b875a6: "蛮吉",
  ou_9f732c2d5d90246bf2524ba4dcdbf44c: "莓玲",
  ou_c58ffd455535f39e72c150ae1d26d87b: "晓星尘",
  ou_43caa51916320e2123a62ea6bad5dd7a: "熊大",
  ou_a1de62c7503bc2de40e1eedf6488c8ec: "超人",
  ou_67ddffa4ecb14dbaec519dcdbb78b3f2: "迪诺",
  ou_18cdee01d6ea22c1736f556c0aaefe94: "亚可",
  ou_62018afb2a725ea463847873f885dc4c: "七七",
  ou_acb1ed33e602e4983dee2644c5e59f4b: "苏兮",
  ou_7f75ebcb7d659b1f2205f77645cb7f51: "承太郎",
  ou_f43aba17fdbfa2198454906f220045f6: "杨绍运",
  ou_14676d4fba01fe792481cd50c4a56478: "李豪",
  ou_2446c17c09db881362c3abe7865d51b7: "艾莎",
  ou_d6eb040c8c9827f283c14c910a70323a: "布鲁克",
  ou_ed49c392f2ecbf521c61fd8aab300303: "艾伦",
  ou_5ee35333cb69488b96e4e24b2d61360c: "韩非",
  ou_e28510be6578b69798d2426c08035275: "九厥",
  ou_e013951a436c610d32bacaab2d98f2dd: "月野兔",
  ou_d754d9e91b56c6194d05b6cf74633cf0: "鸣人",
  ou_b681604725dcbacc1fced7c07b8bf997: "小黎",
  ou_59e673c3c471eba9ec1a228ea00480e5: "奥利佛",
  ou_3a96d76d1e53e5038a7e8837aebed0ee: "佩奇",
  ou_c647dea438fc102ad75f4b2df1848ac6: "可可露",
  ou_e87b30d0894f800a0ac986dd9c5a99c3: "竹清",
  ou_28a51f5f3fba6d17d42217c8254c8b2f: "阿斯兰",
  ou_a0ff89e494442974c8800c602078caeb: "可莉",
  ou_85ca550d19a295bfca8f60730cb2e94d: "汤姆",
  ou_806e74e34fe90440444815f5c6381ed6: "天津饭",
  ou_689055dec795a5fea5308fc90d113745: "奉眠",
  ou_3d423c3f4b428e0cb909483cde401f86: "莎夏",
  ou_f917e6b506dd14169557f007c498c600: "小明",
  ou_b095f1ef1d3aeefd2483098e68177be3: "幻视",
  ou_a9b643bef3a6c689a752513bebb30cc6: "瓦力",
  ou_c38c80bcb5c3c9af5613ed94bad83f03: "风之痕",
  ou_a3e1f0f494cd76edf9931a041192b94f: "爱德华",
  ou_dfd8758d9ab7cc7232641e543b444f6f: "雷格",
  ou_523689c8354982d810cec87013a241b6: "犬夜叉",
  ou_fe9a31893cd59d6ff105054269413196: "大铁锤",
  ou_ad72ccf3e33af1b13d725be26668432a: "橘右京",
  ou_50dbe5fbc81947158d13563c27778750: "海波东",
  ou_bc19fc62e77ae5b3043e9a691b232e22: "弗莱",
  ou_f6dbcd2cc13f96c8df696db3d2af8270: "智深",
  ou_8cb1a104db463362f9e0657c421e1b47: "石昊",
  ou_a79908974ca87f50ca1efd3d60e4e05e: "星马烈",
  ou_ee1c2c722080952c25c356054ff4871d: "星马豪",
  ou_2079f3e9fdb68d29e0da027c2bcf444b: "纳兹",
  ou_a4df29dc58218f64b24c4102df042f19: "三笠",
  ou_e2318a06d4b94897327edce58e8828af: "杰瑞",
  ou_410b625fec96eb6d14694c4c4e85e5ea: "王也",
  ou_9546e05b3cf095a7f3bbd2ff0b68fbd7: "赛罗",
  ou_65586fea564eaa5b40ffcb421e7137d9: "迪路",
  ou_0aae677b083f2cab45afa02b312cc17a: "无限",
  ou_8d57a84be68dd635404f06766010d2ec: "徐钰",
  ou_b0ec05c75fd4916cb1e75895695a0ed5: "火野丽",
  ou_97e45b29ccf1b05d9c4033d1cc7c27ce: "西卡",
  ou_73d347c28f5c836e227ffef28c194588: "小甜甜",
  ou_288c8d23829db4e660a2f1269a64e2b6: "吉娜",
  ou_0103d3903a44ae210d1d0d13fb84c1f8: "长歌",
  ou_16670d0a18e6376772efca45f44c7b19: "桔梗",
  ou_357899d393b0657c09088ee8ee642e86: "温蒂",
  ou_3f77b73d09d33bc91481ec27b5a8f150: "赤犬",
  ou_ef38c0322875609414f40a342e4879f5: "黑子",
  ou_7842077fbe58e95ee112c818cf14aabb: "雷泽",
  ou_f38df0aeb2b49d7aa2b9e8ce238e0d5c: "章鱼哥",
  ou_1453196682adafa19c74010062ff9645: "银时",
  ou_ea600d93371c459cf9b8b4fbd33263c0: "一护",
  ou_539de482f69f74e44ebf5794e9944e05: "克比",
  ou_f132e8090dcf95211cb1462d10f04f60: "泡泡",
  ou_df15eef25701bba8c0d7c0850d6da87f: "彭彭",
  ou_01d83529d5cc92ef67d44e4e1fe65028: "我爱罗",
  ou_bdff838eb0b2626b2f70e91da974c4df: "宗介",
  ou_8ede8a66255e1b5f022ccb2e33d284c5: "哪吒",
  ou_fb861c52f973f2b6f18e0cdf27a65409: "林御",
  ou_5e4f38af58226136e5b0e3b05455bb7d: "扶苏",
  ou_eee8fe18e05570b69d778cf728640822: "亚里亚",
  ou_bde6c99628d580af0fb89eb50963dc61: "熊二",
  ou_6652b8289274ca110edde0e72c2c83f7: "耿鬼",
  ou_fad408ec808fd2b8f9084237a9d79672: "七夜",
  ou_895d542ec084c0156bb9e79d743853cc: "马良",
  ou_c0e1fc1443ea7ef8e102195cb9f3ca68: "千寻",
  ou_a122d45f19958a97807b422247c19de0: "小青",
  ou_76540fea46a72d4244855553df4c4621: "休斯",
  ou_574cd8fbeceb3dcd66b4f97f7b227a43: "格温",
  ou_043a8681bbea97812ce2551e12d08211: "童虎",
  ou_a21a733c2ccc2cfc23c887aa50495642: "君莫笑",
  ou_9e261e203cbb269391081d25385b3308: "凯特",
  ou_5f71eb75f07c8de0557cab1cc13ba6b4: "闪电",
  ou_f5e559c3703f48e45eaa56c98201e274: "杏寿郎",
  ou_19ce29af54065ca55046ef4316829210: "夏目",
  ou_0d7f3e4b143ca8f64a0cd07e5aa03aba: "镜悬",
  ou_eb4bc00d579219e5a2b14efc389f6d13: "弥勒",
  ou_7168afb2bbd46571ed5b184141465dcc: "荣光",
  ou_46c0258ba1eed5f3871d41ba4896d04c: "药尘",
  ou_d5fe9c65813135e48a2ba4645702c8a3: "平次",
  ou_244944ccc9dc31008e24c48ac396ff74: "天火",
  ou_1eea576bdbae630d3d813b67f2e57d0f: "江厌离",
  ou_874542f1cb3e74b4d5cae3aed7ddd0b2: "大和",
  ou_78212e557f5112709cde799be552cfbd: "贝贝",
  ou_a96516bbbf5d05ebc6735658d5ff7000: "夜翼",
  ou_f40c53888f9db9893767aa3e16ede624: "小二",
  ou_a67c4eca70dae6752276d2d599290130: "千空",
  ou_3752125ffbc11804ca8a75bfaa3bf228: "乙骨",
  ou_baa9ce1e1fc2ddbbe23593dd35b212b6: "少羽",
  ou_5e1c5fef95efa57dd4f17e87929f74c4: "弗特",
  ou_336fdf58e74c716a581300cea905cc2a: "罗杰",
  ou_812b435bac85681b1df6aaede9faca20: "莱德",
  ou_baddc0ce36916ebac93181ecdec810b5: "布罗利",
  ou_b98fb61cdfdf2c50dd8c4ff1cd89b310: "姜子牙",
  ou_cedede28a685e9cc803ceadf2749f142: "海森",
  ou_f23908d30ea90382f5df08aace270262: "麦昆",
  ou_316f9646f79060666c37d419bb267c9a: "后羿",
  ou_a4ff5d7088d63ef72e07c02a175a2315: "小新",
  ou_08d32fce4c5ee771159bb0161e5a741f: "风间彻",
  ou_96562813ed593434b1b0b550ec7bc42f: "翊悬",
  ou_3ad7dce32735858c198a74c67bfe885a: "踏岚",
  ou_7da02c15910b017109b2032e87786b37: "小圆",
  ou_ba145cc8f6765ef365ecd6af2e195973: "晴子",
  ou_bbcbedbbb0232a4337a8dcf720e7a26f: "路飞",
  ou_ba1ec27151461811d47bcf112a43511c: "雷神",
  ou_fdffa65b85d19b5a8db8f6ad2830a59e: "机器猫",
  ou_c8bb27466635a8b9cec658a1c559493a: "波杰克",
  ou_37999163f6e1de7e9da7f207a8d7c637: "蚁人",
  ou_0e05f67dc1594165b7ff2ac224032c61: "欧布",
  ou_f550f7191f0db20bb1a68f15001f98d3: "王舞",
  ou_387c9d322cb1812ae2b02c479d5fe3f2: "摩诘",
  ou_28d085608589a8125ed0894b091ca414: "庖丁",
  ou_73e90c19d27bc4493515561f22b22588: "林克",
  ou_1b00fa691137bb2d98fcadc208d8ef90: "藤丸",
  ou_380e5248d69068144b22b98c2eddb11c: "卡妙",
  ou_b63488871a1a8dbae9fee2ae87c1837a: "进藤光",
  ou_dd466fc6a564ab0b829aedf25285938a: "姬发",
  ou_fa02d70edccfee3134421511079fc3f1: "布莱泽",
  ou_dab30ad990e746ed70d55735e680b06f: "阿宝",
  ou_2765b117fe2e9bdcdcb6b5d062bd3d90: "卡西法",
  ou_ae8e82b7ef54f879e4fac71763860cb4: "夜斗",
  ou_a9be66364a0f3580688bdf2ad3b4645c: "一休",
  ou_5d1abb932fd0a5e4debaf201a3497c2d: "大仓",
  ou_4a9eaad9280ed187083e61c41ed00290: "飞影",
  ou_72619a1cd94ecdcf887ff8381caa4a4c: "星矢",
  ou_efacd30f613db47859ad6d9193c38553: "景天",
  ou_7ec2a7163cf4457f87d387c9fb669823: "胡迪",
  ou_1afc6b31fe01bfc6e9ddb28c45900d4c: "杀生丸",
  ou_af51b7c87e61001dcc000ebc465273c5: "大脸猫",
  ou_ca60896e9998bff30bc78e1a59e3faa0: "玄离",
  ou_242770fe90654b7959a8eaa6a55ec99b: "五条悟",
  ou_eb83e23c497e1f12d82d258d160c9067: "风间俊",
  ou_d200ad6bb971308e89d14ea4f6692a30: "琪琪",
  ou_eb9ec90195159dc6e5f4148b69d27721: "金木研",
  ou_1fbada8ed0f0c4073f0ee56538983025: "高月",
  ou_bd9335156b0ac6132284ca2288c2e637: "天蓬",
  ou_f1cf167f3b535cace8c4dff2a72b4d54: "昆卡",
  ou_3d6b122633eb8bff16fea0c501d863df: "美琪",
  ou_d9b9ac15623c632e46d40b08ad73ef31: "米菲",
  ou_4d0ab6a432bd9059573d192b4757bc67: "基德",
  ou_fff8c297337c12d442a47bd36f3993bd: "辛德拉",
  ou_9c98fe1488fc55c427431aea5a23fc93: "赛诺",
  ou_9cee61a48dbdddd8d0318de0a4666486: "玛力露",
  ou_4822eaae3b5e90cf6ad35ed0d7bc0c6e: "青雉",
  ou_ee09ec8923d63fc78dce0f1a66dee23a: "但丁",
  ou_3845b8319abe4f286ba28cbd396346a5: "小凡",
  ou_71563cb5706fd58a4d69531d6ac2ebb6: "卫庄",
  ou_7a9873babf826244834ef50a61c417aa: "摩纳",
  ou_c665a8b43c0f47177f8ff8456913c86f: "沸羊羊",
  ou_de13bc6d045ac55e233a9a58892d87e7: "龙葵",
  ou_087f267c1b1614217c647331ebf3939e: "米格",
  ou_38cdfa474115de4e09dc9320fca86eea: "刘备",
  ou_e104bfe115e966e8ecee3622db0c89ea: "菲菲",
  ou_c7875e7e09a35184ae308ee8f0fcc52c: "许多鱼",
  ou_efcea27e68c7b0f7834a5f4f908d7e0b: "奈奈生",
  ou_10e63a92de684094ebcdea59a6878f01: "知世",
  ou_dcfda11eca1bb0f4471919bae9f5eef9: "米雅",
  ou_5acdeedf0514aea3866404fe053d6b56: "李慕婉",
  ou_f235d6322aadfcbc193349de0ea0a460: "擎天柱",
  ou_926f194fc015dba407ed1e72277208de: "大黄蜂",
  ou_a234aa99c89d7ba39f333d906a40320a: "妙子",
  ou_1e38eb8a7e0b2500484582725ad5ed0a: "塞拉维",
  ou_62a6c2bc1fe5352b6f03421b3ca9b81e: "泽塔",
  ou_dd5cd267846c2cbe823fffc5be2410bf: "艾露莎",
  ou_c61fbe78701599d89814e2c27a9f9526: "晴空",
  ou_5a023fe6b57ee5d931a1f0e55a512610: "亚当",
  ou_8415692cf4f47bfed6bbe595303eb363: "珍珍",
  ou_fc5bbb6bb697387c2efe43d862b3be31: "艾达",
  ou_14e5899814c1a800c6b6821068d5ede5: "尼布尔",
  ou_046f92ec015ca9d2d2bf962d7b37b66e: "云宝",
  ou_4c5bf090bf40be33082162b49d4be834: "希丝",
  ou_c76ee141bd0a263bd0a85868748c85f4: "萨尔娜",
  ou_0dd4d78b164545f77d89b8d1ac11ea7d: "柯南",
  ou_fba242445288ab1e32a376f9b1c8082f: "卢娜",
  ou_8c940a31ee4a6152dbfaf3ce002c2529: "千落",
  ou_41b7dfbce7a10b9a03a470dc1f10b1b6: "春丽",
  ou_390ed90327488ad6489d01756c9ad854: "小刚",
  ou_51c0b7b20d2e433a7626e6ba6d444d9b: "李洛克",
  ou_554b476996b2e1f016dea26b54cda1a5: "达达",
  ou_6e43f3da2f33d841cfd2633590bc9a6a: "齐木",
  ou_184cd793ff0e19fced1163b949ad8870: "万叶",
  ou_71dff4e9099c51b8b78e5eab7eccb2d7: "viktor",
  ou_86d766a6a52af5364b419e68280c0a38: "叶修",
  ou_57dc96f33a363737499112aa40996082: "白月初",
  ou_a99776835f0ddda413c0abf5e8fefb02: "普尔",
  ou_e8d702543bac5ba3786baf38d95bda04: "悟饭",
  ou_3f2731ee8ccfb7b4fdac10997774e796: "蝙蝠侠",
  ou_572f0bb3316d0827c3ae7b59dccd1d03: "花子君",
  ou_e00c202a4c4c8704f342cc677785f979: "小克",
  ou_19917f8cf9e4edeb0e71dd66b1e331d3: "火娃",
  ou_0572b4a2dee09cd74324b1baa96770c1: "琥珀川",
  ou_ead81403302bebb28fd7670729ff1ee9: "黑豹",
  ou_08ff6de730179bdfd1a9e3964b03a2f7: "若林",
  ou_c4d35cfbd8740e83024f49c827d9446a: "梅里",
  ou_a4137e3c839cb995d1379cfda3213069: "马鲁克",
  ou_2da96a36c2b47d5cdb25fad53ea6c3d8: "宁次",
  ou_b2f4b1a2489f1f2ab046d1c3502cdc22: "江流儿",
};
