import { useState } from "react";
import { Button, Form, Input, Modal} from "antd";

export default function EditGpuModel({type="Add", showModal=false, gpuModelInfo={}, finishOperate, btnLoading}: {
    type: string, // "Add" | "Edit",
    showModal: boolean,
    gpuModelInfo: any,
    finishOperate: (success: boolean, type: string, gpuModelInfo: any) => void,
    btnLoading: boolean
}) {
    let dataTmp: any = {
        name: "",
        accuracy: ""
    };
    if (type !== "Add") {
        dataTmp = {...gpuModelInfo};
    }
    const [gpuModelForm, setGpuModelForm] = useState<{
        id?: any,
        name: any,
        accuracy: any}>(dataTmp)
    function cancelOperate() {
        finishOperate(false, "", {});
    }
    function operateData() {
        finishOperate(true, type, gpuModelForm);
    }
    function inputGpuModelFormText(item: string, e: any, valueType=""){
        const gpuModelFormTmp: any = {...gpuModelForm};
        gpuModelFormTmp[item] = valueType === "value" ? e : e.target.value;
        setGpuModelForm({...gpuModelFormTmp});
    }
    return <>
        <Modal open={ showModal } onCancel={cancelOperate} title={type === "Add" ? "添加显卡型号" : "修改显卡型号"} footer={[
            <Button onClick={cancelOperate}>取消</Button>,
            <Button type="primary" loading={btnLoading} onClick={operateData}>确定</Button>
        ]}>
            <div className="flex flex-row">
                <div style={{width: "100%"}}>
                    <Form className="mr-10" label-position="right" labelCol={{span: 5}}>
                        <Form.Item label="显卡名称" required>
                            <Input maxLength={26} onChange={(e: any) => inputGpuModelFormText("name", e)} value={gpuModelForm.name}/>
                        </Form.Item>
                        <Form.Item label="描述" required>
                        <Input maxLength={200} onChange={(e: any) => inputGpuModelFormText("accuracy", e)} value={gpuModelForm.accuracy}/>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    </>
}
