import { getUserResource } from "@/api/fetch";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
export interface UsersState {
  isLogin: boolean;
  email: string;
  resource: string[];
  resourceLoading: boolean;
  userInfo: any;
}

const initialState: UsersState = {
  isLogin: false,
  email: "",
  resource: [],
  resourceLoading: false,
  userInfo: null,
};

export const getResource = createAsyncThunk("user/getResource", async () => {
  const res = await getUserResource();
  return res;
});

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setResource: (state, action: PayloadAction<string[]>) => {
      state.resource = action.payload;
    },
    logout: (state) => {
      state.isLogin = false;
      state.email = "";
      Cookies.remove("token");
    },
    setUserInfo: (state, action: PayloadAction<any>) => {
      state.userInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getResource.pending, (state) => {
      state.resourceLoading = true;
    });
    builder.addCase(getResource.fulfilled, (state, action) => {
      state.resource = action.payload?.resource || [];
      localStorage.setItem("user_resource", JSON.stringify(action.payload));
      state.resourceLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setEmail, setIsLogin, logout, setResource, setUserInfo } = userSlice.actions;

export default userSlice.reducer;
