// 产品类型
export const productTypeList = [
  {
    value: "instance",
    label: "实例",
  },
  {
    value: "storage",
    label: "本地存储",
  },
  {
    value: "network_storage",
    label: "云存储",
  },
  { value: "", label: "全部" },
];

// 计费模式
export const billingModeList = [
  {
    value: "afterusage",
    label: "按量计费",
  },
  {
    value: "prepaidByDay",
    label: "包日",
  },
  {
    value: "prepaidByWeek",
    label: "包周",
  },
  {
    value: "prepaidByMonth",
    label: "包月",
  },
  {
    value: "prepaidByYear",
    label: "包年",
  },
  { value: "", label: "全部" },
];

// 账单状态
export const billStateList = [
  {
    value: "paying",
    label: "账单中",
  },
  {
    value: "payed",
    label: "已支付",
  },
  {
    value: "refund",
    label: "已退款",
  },
  { value: "", label: "全部" },
];

// 交易类型
export const transactionTypeList = [
  {
    value: "recharge",
    label: "充值",
  },
  {
    value: "consume",
    label: "消费",
  },
  {
    value: "refund",
    label: "退费",
  },
  { value: "", label: "全部" },
];

// 交易渠道
export const transactionChannelList = [
  {
    value: "AliPay",
    label: "支付宝",
  },
  {
    value: "WechatPay",
    label: "微信支付",
  },
  {
    value: "Enterprise",
    label: "对公汇款",
  },
  {
    value: "Stripe",
    label: "Stripe",
  },
  {
    value: "USDT",
    label: "USDT",
  },
  {
    value: "Coupon",
    label: "优惠券",
  },
  { value: "", label: "全部" },
];

// 交易渠道-zh
export const transactionChannelList_zh = [
  {
    value: "AliPay",
    label: "支付宝",
  },
  {
    value: "WeixinPay",
    label: "微信支付",
  },
  {
    value: "PublicRemittance",
    label: "对公汇款",
  },
  {
    value: "Coupon",
    label: "优惠券",
  },
];

export const stateList = [
  {
    value: "WaitingPay",
    label: "支付中",
  },
  {
    value: "Success",
    label: "成功",
  },
  {
    value: "Failed",
    label: "失败",
  },
  {
    value: "Canceled",
    label: "已取消",
  },
  {
    value: "",
    label: "全部",
  },
];

export const stateList_zh = [
  {
    value: "pending",
    label: "支付中",
  },
  {
    value: "success",
    label: "成功",
  },
  {
    value: "expired",
    label: "已过期",
  },
  {
    value: "refund",
    label: "已退款",
  },
  {
    value: "",
    label: "全部",
  },
];

export const voucherBusinessTypeList = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "gpu_instance",
    value: "gpu_instance",
  },
  {
    label: "model_api",
    value: "model_api",
  },
  {
    label: "serverless",
    value: "serverless",
  },
];

export const BusinessLineList = [
  {
    value: "gpu_instance",
    label: "GPU Instance",
  },
  {
    value: "model_api",
    label: "Model API",
  },
  {
    value: "serverless",
    label: "Serverless",
  },
];

export enum BusinessType {
  GPU_INSTANCE = "gpu_instance",
  MODEL_API = "model_api",
  SERVERLESS = "serverless",
}
