import { IS_DEV } from "@/config";

// 修改产品价格权限
export const AUTH_PRICING = "product-pricing-edit";
// 编辑折扣权限
export const AUTH_DISCOUNT = "product-discount-edit";
// 产品管理权限
export const AUTH_PRODUCT_EDIT = "product-edit";
// 产品删除权限
export const AUTH_PRODUCT_DELETE = "product-delete";
// 产品折扣删除权限
export const AUTH_DISCOUNT_DELETE = "product-discount-delete";
// 产品折扣是否允许配置活动折扣
export const AUTH_DISCOUNT_ALLOW_ACTIVITY = "product-discount-allow-activity";
// 对公账单/对公充值
export const AUTH_PUBLIC_Bill = "pricing-public-bill";

// 判断是否有权限
export const hasResource = (resource: string[], key: string) => {
  // 开发环境默认有权限
  if (IS_DEV) {
    return true;
  }
  return resource.includes(key) || resource.some((item: string) => item.startsWith(key));
};

// 是否有设置价格权限
export const hasSetPriceResource = (resource: string[]) => {
  return hasResource(resource, AUTH_PRICING);
};


// 是否有设置折扣权限
export const hasSetDiscountResource = (resource: string[]) => {
  return hasResource(resource, AUTH_DISCOUNT);
};

// 是否有修改产品权限
export const hasEditProductResource = (resource: string[]) => {
  return hasResource(resource, AUTH_PRODUCT_EDIT);
};

// 是否有删除产品权限
export const hasDeleteProductResource = (resource: string[]) => {
  return hasResource(resource, AUTH_PRODUCT_DELETE);
};

// 是否有删除折扣权限
export const hasDeleteDiscountResource = (resource: string[]) => {
  return hasResource(resource, AUTH_DISCOUNT_DELETE);
};

// 是否有产品折扣是否允许配置活动折扣权限
export const hasDiscountAllowActivityResource = (resource: string[]) => {
  return hasResource(resource, AUTH_DISCOUNT_ALLOW_ACTIVITY);
};

// 是否有对公账单/对公充值权限
export const hasPublicBillResource = (resource: string[]) => {
  return hasResource(resource, AUTH_PUBLIC_Bill);
};
