// 算力产品管理
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Pagination, Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import EditPowerProduct from "./editPowerProduct";
import { useEffect, useState } from "react";
import EditPowerPrice from "../editPowerPrice";
import dayjs from "dayjs";
import {
  getPowerProducts,
  addEditPowerProductInfo,
  delPowerProductInfo,
  setPowerProductPriceInfo,
} from "@/api/business";
import { cloudServiceTypes } from "./constants";
import { displayPriceFormat } from "@/utils";
import { getClusters } from "@/api/business";
import { useNavigate } from "react-router-dom";
import { BusinessType } from "@/constants";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { hasDeleteProductResource, hasEditProductResource, hasSetDiscountResource, hasSetPriceResource } from "@/constants/auth";

export default function PowerProductManage() {
  const navigate = useNavigate();
  const { resource } = useSelector((state: RootState) => state.user);
  const [modalInfo, setModalInfo] = useState({
    showModal: false,
    type: "Add",
    powerProductInfo: {},
  });
  const [resultTable, setResultTable] = useState({ data: [], total: 0 });
  const [params, setParams] = useState({
    pageNo: 1,
    pageSize: 10,
    productId: "",
    productNameOut: "",
    productNameInner: "",
  });
  const [editProductBtnLoading, setEditProductBtnLoading] = useState(false);
  const [editPriceBtnLoading, setEditPriceBtnLoading] = useState(false);
  const [, setRegionList] = useState([]);

  function getTableData(pageNo?: any, pageSize?: any) {
    getPowerProducts({
      ...params,
      pageNo: !isNaN(Number(pageNo)) ? pageNo : params.pageNo,
      pageSize: !isNaN(Number(pageSize)) ? pageSize : params.pageSize,
    }).then((res: any) => {
      console.log(res);
      if (res?.data && res.data.length) {
        res.data.forEach((ele: any) => {
          if (
            ele.price &&
            ele.price.chargingModel &&
            ele.price.chargingModel.length === 0
          ) {
            ele.price.chargingModel = ["capacity"];
          }
        });
      }
      getClusters({ pageNo: 1, pageSize: 1000 }).then((res: any) => {
        console.log(res);
        setRegionList(res.data || []);
      });
      setResultTable({ data: res.data || [], total: Number(res.total) });
      setParams({
        ...params,
        pageNo: isNaN(Number(pageNo)) ? params.pageNo : pageNo,
        pageSize: isNaN(Number(pageSize)) ? params.pageSize : pageSize,
      });
    });
  }
  useEffect(() => {
    getTableData();
  }, []);
  function finishOperate(
    cancelMark: boolean,
    type: string,
    powerProductInfo: any
  ) {
    if (!cancelMark) {
      console.log("cancelMark:", cancelMark);
      console.log("type:", type);
      console.log("powerProductInfo:", powerProductInfo);
      setModalInfo({ ...modalInfo, showModal: false });
    } else {
      console.log("cancelMark:", cancelMark);
      console.log("type:", type);
      console.log("powerProductInfo:", powerProductInfo);
      const paramsObj = { ...powerProductInfo };
      delete paramsObj.price;
      console.log("paramsObj:", paramsObj);
      setEditProductBtnLoading(true);
      addEditPowerProductInfo({
        ...paramsObj,
        sortedWeight: Number(paramsObj.sortedWeight),
        whitelist: paramsObj.isWhitelistEnabled ? (paramsObj.whitelist || []) : [],
        whitelistUsers: paramsObj.isWhitelistEnabled ? (paramsObj.whitelistUsers || []) : [],
      })
        .then(() => {
          message.success("操作成功");
          setModalInfo({ ...modalInfo, showModal: false });
          getTableData();
        })
        .finally(() => {
          setEditProductBtnLoading(false);
        });
    }
  }

  function delPowerProductInfoFun(id: string) {
    // <Modal
    //     title="Modal"
    //     open={delConfirm}
    //     onOk={() => {
    //       delPowerProductInfo({id}).then(_ => {
    //         message.success('操作成功');
    //         setDelConfirm(false)
    //         getTableData();
    //       })}
    //     }
    //     onCancel={() => setDelConfirm(false)}
    //     okText="确认"
    //     cancelText="取消"
    //   >
    //     <p>Bla bla ...</p>
    //     <p>Bla bla ...</p>
    //     <p>Bla bla ...</p>
    //   </Modal>
    Modal.confirm({
      title: "确认",
      icon: <ExclamationCircleOutlined />,
      content: "是否确认删除数据",
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        delPowerProductInfo({ id }).then(() => {
          message.success("操作成功");
          getTableData();
        });
      },
    });
  }

  function setIsSale(record: any) {
    if (
      !record.isSale &&
      (!record.price ||
        !record.price.priceByCapacity ||
        Number(record.price.priceByCapacity) <= 0)
    ) {
      // 上架状态，设置下架
      message.warning("上架需设置价格");
      return;
    }
    const text = record.isSale ? "下架" : "上架";
    Modal.confirm({
      title: "确认",
      icon: <ExclamationCircleOutlined />,
      content: "是否确认" + text,
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        addEditPowerProductInfo({ ...record, isSale: !record.isSale }).then(
          () => {
            message.success("操作成功");
            getTableData();
          }
        );
      },
    });
  }

  function addPowerProductInfo(powerProductInfo: any) {
    console.log("addPowerProductInfo powerProductInfo:", powerProductInfo);
    if (!powerProductInfo) {
      setModalInfo({ showModal: true, type: "Add", powerProductInfo: {} });
    } else {
      setModalInfo({ showModal: true, type: "Edit", powerProductInfo });
    }
  }

  const columns: ColumnsType<any> = [
    {
      title: "产品id",
      key: "productId",
      dataIndex: "productId",
      width: 100,
    },
    {
      title: "产品名称（外部）",
      key: "productNameOut",
      dataIndex: "productNameOut",
      width: 100,
    },
    {
      title: "产品名称（内部）",
      key: "productNameInner",
      dataIndex: "productNameInner",
      width: 100,
    },
    {
      title: "访问控制（白名单）",
      dataIndex: "isWhitelistEnabled",
      render: (_: unknown, record: any) => {
        if (record.isWhitelistEnabled) {
          return <span>{record.whitelistUsers.map((ele: any) => ele.phone).join(",")}</span>;
        } else {
          return <span>未控制</span>
        }
      },
      width: 100,
    },
    {
      title: "显卡型号",
      key: "gpuModelName",
      dataIndex: "gpuModelName",
      width: 100,
    },
    {
      title: "显存",
      key: "gpuSize",
      dataIndex: "gpuSize",
      width: 100,
    },
    {
      title: "单机卡数",
      key: "standAloneGpuNumber",
      dataIndex: "standAloneGpuNumber",
      width: 100,
    },
    {
      title: "CPU核数/卡",
      key: "cpuCoreNumberPerGpu",
      dataIndex: "cpuCoreNumberPerGpu",
      render: (_: any, record: any) => {
        return (
          <div>
            {record.cpuCoreNumberPerGpu + "-" + record.maxCpuCoreNumberPerGpu}
          </div>
        );
      },
      width: 100,
    },
    {
      title: "内存容量/卡",
      key: "memorySizePerGpu",
      dataIndex: "memorySizePerGpu",
      render: (_: any, record: any) => {
        return (
          <div>
            {record.memorySizePerGpu + "-" + record.maxMemorySizePerGpu}
          </div>
        );
      },
      width: 100,
    },
    {
      title: "可用/库存",
      key: "availableInventory",
      dataIndex: "availableInventory",
      render: (_: any, record: any) => {
        return <div>{record.available + "/" + record.inventory}</div>;
      },
      width: 100,
    },
    {
      title: "排序权重",
      key: "sortedWeight",
      dataIndex: "sortedWeight",
      width: 100,
    },
    {
      title: "云服务类型",
      key: "cloudServiceType",
      dataIndex: "cloudServiceType",
      render: (_: any, record: any) => {
        return (
          <div>
            {cloudServiceTypes.find(
              (item) => item.value === record.cloudServiceType
            )?.label || record.cloudServiceType}
          </div>
        );
      },
      width: 100,
    },
    {
      title: "产品价格",
      key: "productPriceInfo",
      dataIndex: "productPriceInfo",
      width: 150,
      render: (_: any, record: any) => {
        return (
          record.pricing && (
            <div>
              <div>
                目录价：{" "}
                <span>
                  {displayPriceFormat(
                    record.pricing.basePrice0,
                    record.pricing.pricePrecision
                  )}
                </span>
              </div>
              {record.pricing.discountType && (
                <div>
                  {record.pricing.discountRate == "0" ? (
                    <span>
                      折扣价：{" "}
                      {displayPriceFormat(
                        record.pricing.priceAfterDiscount,
                        record.pricing.pricePrecision
                      )}
                    </span>
                  ) : (
                    <span>
                      折扣比例：{" "}
                      {!Number.isNaN(Number(record.pricing.discountRate))
                        ? record.pricing.discountRate + "%"
                        : ""}
                    </span>
                  )}
                </div>
              )}
            </div>
          )
        );
      },
    },
    {
      title: "操作",
      width: 250,
      align: "center",
      fixed: "right",
      render: (_: any, record: any) => {
        return (
          <div>
            {hasEditProductResource(resource) &&
              record.isSale && (
                <Button
                  style={{
                    paddingLeft: "0",
                    color: record.isSale ? "red" : "green",
                  }}
                  type="link"
                  onClick={() => setIsSale({ ...record })}
                >
                  设置{record.isSale ? "下架" : "上架"}
                </Button>
              )}
            {hasEditProductResource(resource) &&
              !record.isSale && (
                <Button
                  style={{
                    paddingLeft: "0",
                    color: record.isSale ? "red" : "green",
                  }}
                  type="link"
                  onClick={() => setIsSale({ ...record })}
                >
                  设置{record.isSale ? "下架" : "上架"}
                </Button>
              )}
            {hasSetPriceResource(resource) && (
              <Button
                type="link"
                onClick={() =>
                  navigate(
                    `/pricing/product?productId=${record.productId}&businessType=${BusinessType.GPU_INSTANCE}`
                  )
                }
              >
                设置价格
              </Button>
            )}
            {hasSetDiscountResource(resource) && (
              <Button
                type="link"
                onClick={() =>
                  navigate(
                    `/pricing/discount?productId=${record.productId}&productCategory=${record.productCategory}&businessType=${BusinessType.GPU_INSTANCE}`
                  )
                }
              >
                设置折扣
              </Button>
            )}
            {hasEditProductResource(resource) && (
              <Button type="link" onClick={() => addPowerProductInfo(record)}>
                修改
              </Button>
            )}
            {hasDeleteProductResource(resource) && (
              <Button
                danger
                type="link"
                onClick={() => delPowerProductInfoFun(record.productId)}
              >
                删除
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const [showPrice, setShowPrice] = useState({
    showModal: false,
    type: "Edit",
    powerPriceInfo: {
      productName: "",
      priceId: "1234567890",
      freeCapacity: "",
      chargingModel: "",
      priceByCapacity: "",
      discountRateByCapacity: "",
      discountTimeByCapacityStart: dayjs("2024-01-10"),
      discountTimeByCapacityEnd: "",
      priceAfterDiscount: "",
      dayPrice: "",
      weekPrice: "",
      monthPrice: "",
      yearPrice: "",
      isEffect: "",
    },
  });
  function finishPrice(cancelMark: boolean, type: string, powerPriceInfo: any) {
    if (!cancelMark) {
      console.log("cancelMark:", cancelMark);
      console.log("type:", type);
      console.log("powerPriceInfo:", powerPriceInfo);
      setShowPrice({ ...showPrice, showModal: false });
    } else {
      console.log("cancelMark:", cancelMark);
      console.log("type:", type);
      console.log("powerPriceInfo:", powerPriceInfo);
      setEditPriceBtnLoading(true);
      setPowerProductPriceInfo({
        ...powerPriceInfo,
        freeCapacity: undefined,
        priceId: [powerPriceInfo.priceId],
        priceByCapacity: powerPriceInfo.chargingModel?.includes("capacity")
          ? Math.round(Number(powerPriceInfo.priceByCapacity) * 100000)
          : "0",
        discountRateByCapacity: powerPriceInfo.chargingModel?.includes(
          "capacity"
        )
          ? powerPriceInfo.discountRateByCapacity
          : 0,
        // priceAfterDiscount: powerPriceInfo.chargingModel?.includes("capacity") ? Number(powerPriceInfo.priceAfterDiscount) * 100 : "0",
        priceAfterDiscount: undefined,
        dayPrice: powerPriceInfo.chargingModel?.includes("day")
          ? Math.round(Number(powerPriceInfo.dayPrice) * 100000)
          : "0",
        weekPrice: powerPriceInfo.chargingModel?.includes("week")
          ? Math.round(Number(powerPriceInfo.weekPrice) * 100000)
          : "0",
        monthPrice: powerPriceInfo.chargingModel?.includes("month")
          ? Math.round(Number(powerPriceInfo.monthPrice) * 100000)
          : "0",
        yearPrice: powerPriceInfo.chargingModel?.includes("year")
          ? Math.round(Number(powerPriceInfo.yearPrice) * 100000)
          : "0",
        discountTimeByCapacityStart: powerPriceInfo.chargingModel.includes(
          "capacity"
        )
          ? dayjs(
            powerPriceInfo.ageingDate[0].format("YYYY-MM-DD") + " 00:00:00"
          )
            .unix()
            .toString()
          : "0",
        discountTimeByCapacityEnd: powerPriceInfo.chargingModel.includes(
          "capacity"
        )
          ? dayjs(
            powerPriceInfo.ageingDate[1].format("YYYY-MM-DD") + " 23:59:59"
          )
            .unix()
            .toString()
          : "0",
      })
        .then(() => {
          message.success("操作成功");
          setShowPrice({ ...showPrice, showModal: false });
          getTableData();
        })
        .finally(() => {
          setEditPriceBtnLoading(false);
        });
    }
  }
  // function addPowerPrice(powerPriceInfo: any) {
  //   setShowPrice({
  //     ...showPrice,
  //     showModal: true,
  //     powerPriceInfo: {
  //       ...showPrice.powerPriceInfo,
  //       ...powerPriceInfo,
  //       priceByCapacity: powerPriceInfo.chargingModel?.includes("capacity")
  //         ? dealMoney(Number(powerPriceInfo.priceByCapacity))
  //         : "",
  //       priceAfterDiscount: powerPriceInfo.chargingModel?.includes("capacity")
  //         ? dealMoney(Number(powerPriceInfo.priceAfterDiscount))
  //         : "",
  //       dayPrice: powerPriceInfo.chargingModel?.includes("day")
  //         ? dealMoney(Number(powerPriceInfo.dayPrice))
  //         : "",
  //       weekPrice: powerPriceInfo.chargingModel?.includes("week")
  //         ? dealMoney(Number(powerPriceInfo.weekPrice))
  //         : "",
  //       monthPrice: powerPriceInfo.chargingModel?.includes("month")
  //         ? dealMoney(Number(powerPriceInfo.monthPrice))
  //         : "",
  //       yearPrice: powerPriceInfo.chargingModel?.includes("year")
  //         ? dealMoney(Number(powerPriceInfo.yearPrice))
  //         : "",
  //       discountTimeByCapacityStart:
  //         powerPriceInfo.discountTimeByCapacityStart === "0"
  //           ? ""
  //           : powerPriceInfo.discountTimeByCapacityStart,
  //       discountTimeByCapacityEnd:
  //         powerPriceInfo.discountTimeByCapacityEnd === "0"
  //           ? ""
  //           : powerPriceInfo.discountTimeByCapacityEnd,
  //     },
  //   });
  // }
  function inputSetParamsText(item: string, e: any, valueType = "") {
    const paramsTmp: any = params;
    paramsTmp[item] = valueType === "value" ? e : e.target.value;
    setParams({ ...paramsTmp });
  }
  function changePagination(page: any, pageSize: any) {
    console.log("pageNo:", page);
    console.log("pageSize:", pageSize);
    console.log("params.pageNo:", params.pageNo);
    console.log("params.pageSize:", params.pageSize);
    if (pageSize !== params.pageSize) {
      // setParams({...params, pageNo: 1, pageSize, total: 0})
      getTableData(1, pageSize);
    } else {
      // setParams({...params, pageNo: page, pageSize, total: 0})
      getTableData(page, pageSize);
    }
  }
  function searchData() {
    getTableData(1);
  }
  return (
    <div style={{ padding: "10px" }}>
      <div>
        {/* <span style={{ marginBottom: "20rem", fontSize: "16px", fontWeight: "bolder" }}>
        算力产品列表
      </span> */}
        <div>
          <div>
            <Form>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="产品id：">
                  <Input
                    maxLength={30}
                    onChange={(e: any) => inputSetParamsText("productId", e)}
                    style={{ width: "215px" }}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="产品名称（外部）:">
                  <Input
                    maxLength={30}
                    onChange={(e: any) =>
                      inputSetParamsText("productNameOut", e)
                    }
                    style={{ width: "200px" }}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="产品名称（内部）:">
                  <Input
                    maxLength={30}
                    onChange={(e: any) =>
                      inputSetParamsText("productNameInner", e)
                    }
                    style={{ width: "200px" }}
                  />
                </Form.Item>
              </div>
              <Button
                onClick={searchData}
                style={{ marginRight: "20px" }}
                type="primary"
              >
                查询
              </Button>
              {hasEditProductResource(resource) && (
                <Button
                  style={{ marginRight: "20px" }}
                  type="primary"
                  onClick={() => addPowerProductInfo(null)}
                >
                  新增
                </Button>
              )}

              {/* <Button style={{marginRight: "20px"}} type="primary" onClick={addPowerPrice}>测试修改价格</Button> */}
            </Form>
          </div>
          <Table
            columns={columns}
            dataSource={resultTable.data}
            pagination={false}
            scroll={{ x: 1900 }}
          ></Table>
          {resultTable.total > 0 ? (
            <Pagination
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                float: "right",
              }}
              pageSize={Number(params.pageSize)}
              current={Number(params.pageNo)}
              total={resultTable.total}
              showSizeChanger
              showQuickJumper
              onChange={(page: number, pageSize: number) =>
                changePagination(page, pageSize)
              }
              showTotal={(total) => `共 ${total} 条`}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {modalInfo.showModal ? (
        <EditPowerProduct
          btnLoading={editProductBtnLoading}
          finishOperate={finishOperate}
          showModal={modalInfo.showModal}
          type={modalInfo.type}
          powerProductInfo={modalInfo.powerProductInfo}
        />
      ) : (
        ""
      )}
      {showPrice.showModal ? (
        <EditPowerPrice
          btnLoading={editPriceBtnLoading}
          subType="power"
          finishOperate={finishPrice}
          showModal={showPrice.showModal}
          type={showPrice.type}
          powerPriceInfo={showPrice.powerPriceInfo}
        />
      ) : (
        ""
      )}
    </div>
  );
}
