import styles from "./style.module.css";
import { DatePicker, InputNumber, Radio, Tag } from "antd";
import { EditData } from "./AddConfigModal";
import dayjs from "dayjs";
import { useMemo } from "react";
import { displayPriceFormat, realPriceFormat } from "@/utils";

export function DiscountConfig({
  editData,
  onChange,
  productList,
}: EditData & { productList: any[] }) {
  const isIncludeAll = useMemo(() => {
    return editData.applyProductsCopy.includes("all");
  }, [editData.applyProductsCopy]);

  const productListWithDiscount = useMemo(() => {
    return productList
      .filter((one) => editData.applyProductsCopy.includes(one.productId))
      .map((one) => ({
        ...one,
        discountPrice0:
          editData.discountType === "discount_rate"
            ? editData.discountRate &&
              editData.discountRate !== 0 &&
              one.basePrice0 &&
              !Number.isNaN(Number(one.basePrice0))
              ? (Number(one.basePrice0) * editData.discountRate) / 100
              : 0
            : realPriceFormat(editData.discountPrice0, one.pricePrecision),
        discountPrice1:
          editData.discountType === "discount_rate"
            ? editData.discountRate &&
              editData.discountRate !== 0 &&
              one.basePrice1 &&
              !Number.isNaN(Number(one.basePrice1))
              ? (Number(one.basePrice1) * editData.discountRate) / 100
              : 0
            : realPriceFormat(editData.discountPrice1, one.pricePrecision),
      }));
  }, [
    productList,
    editData.applyProductsCopy,
    editData.discountRate,
    editData.discountPrice0,
    editData.discountPrice1,
    editData.discountType,
  ]);

  const displayPriceUnit = useMemo(() => {
    if (!editData.applyProductsCopy || editData.applyProductsCopy.length === 0)
      return "";
    const product = productList.find(
      (one) => one.productId == editData.applyProductsCopy[0]
    );
    return product ? product?.displayPriceUnit : "";
  }, [editData.applyProductsCopy, productList]);

  return (
    <div className={styles.content}>
      <div>
        <Radio.Group
          value={editData.discountType}
          onChange={(e) => {
            onChange({
              ...editData,
              discountType: e.target.value,
            });
          }}
        >
          <Radio value="discount_rate">按折扣系数</Radio>
          <Radio
            value="discount_price"
            disabled={isIncludeAll || editData.applyProductsCopy?.length > 1}
          >
            按折扣价
          </Radio>
        </Radio.Group>
      </div>
      {editData.discountType === "discount_rate" && (
        <>
          <div className={styles.label2}>
            折扣系数 (折扣价 = 目录价 * 折扣系数)
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <InputNumber
                value={editData.discountRate}
                onChange={(value) => {
                  onChange({
                    ...editData,
                    discountRate: value,
                  });
                }}
                controls={false}
                style={{ width: 100 }}
                min={0}
                max={100}
                step={1}
                addonAfter="%"
                precision={0}
              />
            </div>
          </div>
        </>
      )}
      {editData.discountType === "discount_price" &&
        !isIncludeAll &&
        editData.applyProductsCopy?.length < 2 && (
          <>
            <div className={styles.label2}>折扣价</div>
            {editData.applyProductCategory !== "llm" ? (
              <InputNumber
                value={editData.discountPrice0}
                onChange={(value) => {
                  onChange({
                    ...editData,
                    discountPrice0: value,
                  });
                }}
                controls={false}
                style={{ width: 300 }}
              />
            ) : (
              <>
                <div
                  style={{
                    marginBottom: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ width: 100, fontSize: 13 }}>input tokens</span>
                  <InputNumber
                    value={editData.discountPrice0}
                    onChange={(value) => {
                      onChange({
                        ...editData,
                        discountPrice0: value,
                      });
                    }}
                    controls={false}
                    style={{ width: 200 }}
                    placeholder="input token"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ width: 100, fontSize: 13 }}>
                    output tokens
                  </span>
                  <InputNumber
                    value={editData.discountPrice1}
                    onChange={(value) => {
                      onChange({
                        ...editData,
                        discountPrice1: value,
                      });
                    }}
                    controls={false}
                    style={{ width: 200 }}
                    placeholder="output token"
                  />
                </div>
              </>
            )}
          </>
        )}
      {!isIncludeAll && editData.applyProductsCopy.length > 0 && (
        <>
          <div className={styles.label2}>产品价格预览 {displayPriceUnit}</div>
          <div className={styles.table}>
            <table>
              <thead>
                <tr>
                  <td>产品</td>
                  <td>
                    目录价{" "}
                    {editData.applyProductCategory === "llm" && (
                      <div>input / output</div>
                    )}
                  </td>
                  <td>
                    红线价{" "}
                    {editData.applyProductCategory === "llm" && (
                      <div>input / output</div>
                    )}
                  </td>
                  <td>
                    折扣价{" "}
                    {editData.applyProductCategory === "llm" && (
                      <div>input / output</div>
                    )}
                  </td>
                </tr>
              </thead>
              <tbody>
                {editData.applyProductsCopy.map((one) => {
                  const product = productListWithDiscount.find(
                    (product) => product.productId === one
                  );
                  if (!product) return null;
                  return (
                    <tr key={product.id}>
                      <td>
                        <div>{product?.displayName}</div>
                        {Array.isArray(product?.regionNames) &&
                          product?.regionNames.length > 0 && (
                            <div>
                              {product?.regionNames.map((one: string) => (
                                <Tag key={one} color="blue">
                                  {one}
                                </Tag>
                              ))}
                            </div>
                          )}
                      </td>
                      <td>
                        {editData.applyProductCategory === "llm" ? (
                          <>
                            <span>
                              {displayPriceFormat(
                                product?.basePrice0,
                                product?.pricePrecision
                              )}
                            </span>
                            <span> / </span>
                            <span>
                              {displayPriceFormat(
                                product?.basePrice1,
                                product?.pricePrecision
                              )}
                            </span>
                          </>
                        ) : (
                          displayPriceFormat(
                            product?.basePrice0,
                            product?.pricePrecision
                          )
                        )}
                      </td>
                      <td>
                        {editData.applyProductCategory === "llm" ? (
                          <>
                            <span>
                              {displayPriceFormat(
                                product?.redlinePrice0,
                                product?.pricePrecision
                              )}
                            </span>
                            <span> / </span>
                            <span>
                              {displayPriceFormat(
                                product?.redlinePrice1,
                                product?.pricePrecision
                              )}
                            </span>
                          </>
                        ) : (
                          displayPriceFormat(
                            product?.redlinePrice0,
                            product?.pricePrecision
                          )
                        )}
                      </td>
                      <td>
                        {editData.applyProductCategory === "llm" ? (
                          <>
                            <span>
                              {displayPriceFormat(
                                product?.discountPrice0,
                                product?.pricePrecision
                              )}
                            </span>
                            <span> / </span>
                            <span>
                              {displayPriceFormat(
                                product?.discountPrice1,
                                product?.pricePrecision
                              )}
                            </span>
                          </>
                        ) : (
                          displayPriceFormat(
                            product?.discountPrice0,
                            product?.pricePrecision
                          )
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
      <div className={styles.label2}>生效时间</div>
      <DatePicker.RangePicker
        showTime
        value={editData.range}
        onChange={(value) => {
          onChange({
            ...editData,
            range: value,
          });
        }}
        presets={[
          {
            label: "7天",
            value: [dayjs(), dayjs().add(7, "days")],
          },
          {
            label: "1个月",
            value: [dayjs(), dayjs().add(1, "month")],
          },
          {
            label: "6个月",
            value: [dayjs(), dayjs().add(6, "month")],
          },
          {
            label: "1年",
            value: [dayjs(), dayjs().add(1, "year")],
          },
        ]}
      />
    </div>
  );
}
