// 显卡型号管理
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Table,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import EditCluster from "./editCluster";
import { useEffect, useState } from "react";
import {
  CONTINENT_ITEMS_ALL,
  PROVINCE_ITEMS_ALL,
  CONTINENT_ITEMS,
} from "./constants";
import {
  getClusters,
  addEditClusterInfo,
  delClusterInfo,
} from "@/api/business";
import { useLocation, useNavigate } from "react-router-dom";
import { handleShowResource } from "@/utils";

export default function ClusterManage() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [modalInfo, setModalInfo] = useState({
    showModal: false,
    type: "Add",
    clusterInfo: {},
  });
  const [resultTable, setResultTable] = useState([]);
  const [params, setParams] = useState({
    pageNo: 1,
    pageSize: 10,
    clusterId: "",
    clusterName: "",
    largeCategory: "Domestic",
    continent: "",
    province: "",
    sortedWeight: "",
  });
  const [editClusterBtnLoading, setEditClusterBtnLoading]=useState(false);

  function getTableData() {
    getClusters(params).then((res: any) => {
      console.log(res);
      setResultTable(res.data || []);
    });
  }
  useEffect(() => {
    if (!handleShowResource(pathname)) {
      navigate("/dashboard");
      return;
    }
    getTableData();
  }, []);
  function addCluster(clusterInfo: any) {
    if (!clusterInfo) {
      setModalInfo({ showModal: true, type: "Add", clusterInfo: {} });
    } else {
      setModalInfo({ showModal: true, type: "Edit", clusterInfo });
    }
  }
  function finishOperate(cancelMark: boolean, type: string, clusterInfo: any) {
    if (!cancelMark) {
      console.log("cancelMark:", cancelMark);
      console.log("type:", type);
      console.log("clusterInfo:", clusterInfo);
      setModalInfo({ ...modalInfo, showModal: false });
    } else {
      console.log("cancelMark:", cancelMark);
      console.log("type:", type);
      console.log("clusterInfo:", clusterInfo);
      setEditClusterBtnLoading(true);
      addEditClusterInfo({
        clusterId: clusterInfo.clusterId,
        clusterName: clusterInfo.clusterName,
        largeCategory: clusterInfo.largeCategory,
        continent:
          clusterInfo.largeCategory === "International"
            ? clusterInfo.continent
            : "",
        province:
          clusterInfo.largeCategory === "Domestic" ? clusterInfo.province : "",
        sortedWeight: clusterInfo.sortedWeight,
      }).then(() => {
        message.success("操作成功");
        setModalInfo({ ...modalInfo, showModal: false });
        getTableData();
      }).finally(() => {
        setEditClusterBtnLoading(false);
      });
    }
  }
  function searchData() {
    getTableData();
  }
  function inputSetParamsText(item: string, e: any, valueType = "") {
    const paramsTmp: any = params;
    paramsTmp[item] = valueType === "value" ? e : e.target.value;
    setParams({ ...paramsTmp });
  }
  function delClusterInfoFun(id: any) {
    Modal.confirm({
      title: "确认",
      icon: <ExclamationCircleOutlined />,
      content: "是否确认删除数据",
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        delClusterInfo({ id }).then(() => {
          message.success("操作成功");
          getTableData();
        });
      },
    });
  }
  const columns: ColumnsType<any> = [
    {
      title: "集群id",
      dataIndex: "clusterId",
    },
    {
      title: "集群名称",
      dataIndex: "clusterName",
    },
    {
      title: "大类",
      dataIndex: "largeCategory",
      render: (_: any, record: any) => {
        return (
          <div>
            {record.largeCategory === "International" ? "海外" : "国内"}
          </div>
        );
      },
    },
    {
      title: "洲",
      dataIndex: "continent",
      render: (_: any, record: any) => {
        return (
          <div>
            {CONTINENT_ITEMS.find((item) => item.value === record.continent)
              ?.label || ""}
          </div>
        );
      },
    },
    {
      title: "省份",
      dataIndex: "province",
    },
    {
      title: "排序权重",
      dataIndex: "sortedWeight",
    },
    {
      title: "操作",
      width: 250,
      render: (_: any, record: any) => {
        return (
          <div>
            {handleShowResource("meta-clusterManage-edit") && (
              <Button
                style={{ paddingLeft: "0" }}
                type="link"
                onClick={() => addCluster({ ...record })}
              >
                修改
              </Button>
            )}
            {handleShowResource("meta-clusterManage-del") && (
              <Button
                danger
                type="link"
                onClick={() => delClusterInfoFun(record.clusterId)}
              >
                删除
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ padding: "10px" }}>
      <div>
        <div>
          <div>
            <Form>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="集群id：">
                  <Input
                    onChange={(e: any) => inputSetParamsText("clusterId", e)}
                    style={{ width: "200px" }}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="集群名称：">
                  <Input
                    onChange={(e: any) => inputSetParamsText("clusterName", e)}
                    style={{ width: "200px" }}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="大类：">
                  <Radio.Group
                    onChange={(e: any) =>
                      inputSetParamsText("largeCategory", e)
                    }
                    value={params.largeCategory}
                    defaultValue="Domestic"
                    buttonStyle="solid"
                  >
                    <Radio.Button value="Domestic">国内</Radio.Button>
                    <Radio.Button value="International">海外</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {/* <Input onChange={(e: any) => inputSetParamsText("largeCategory", e)} style={{width: "200px"}}/> */}
              </div>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="洲：">
                  <Select
                    options={CONTINENT_ITEMS_ALL}
                    onChange={(e: any) =>
                      inputSetParamsText("continent", e, "value")
                    }
                    style={{ width: "160px" }}
                  ></Select>
                </Form.Item>
              </div>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="省份：">
                  <Select
                    options={PROVINCE_ITEMS_ALL}
                    onChange={(e: any) =>
                      inputSetParamsText("province", e, "value")
                    }
                    style={{ width: "160px" }}
                  ></Select>
                </Form.Item>
              </div>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="排序权重：">
                  <Input
                    onChange={(e: any) => inputSetParamsText("sortedWeight", e)}
                    style={{ width: "160px" }}
                  />
                </Form.Item>
              </div>
              <Button
                onClick={searchData}
                style={{ marginRight: "20px" }}
                type="primary"
              >
                查询
              </Button>
              {handleShowResource("meta-clusterManage-add") && (
                <Button type="primary" onClick={() => addCluster(null)}>
                  新增
                </Button>
              )}
            </Form>
          </div>
          <Table columns={columns} dataSource={resultTable}></Table>
        </div>
      </div>
      {modalInfo.showModal ? (
        <EditCluster
          btnLoading={editClusterBtnLoading}
          finishOperate={finishOperate}
          showModal={modalInfo.showModal}
          type={modalInfo.type}
          clusterInfo={modalInfo.clusterInfo}
        />
      ) : (
        ""
      )}
    </div>
  );
}
