import { request } from "./fetch";

// llm 模型管理
export function queryModelList(params: any) {
  return request({
    url: "/v1/admin/llm-model/list",
    query: params,
  });
}

// 新增模型
export function addModel(data: any) {
  return request({
    url: "/v1/admin/llm-model",
    method: "POST",
    data,
  });
}

// 编辑模型
export function editModel(data: any) {
  return request({
    url: "/v1/admin/llm-model",
    method: "PUT",
    data,
  });
}

// 删除模型
export function deleteModel(id: string) {
  return request({
    url: `/v1/admin/llm-model`,
    method: "DELETE",
    query: { id },
  });
}

// 获取serverless 产品列表
export function queryServerlessProductList(params: any) {
  return request({
    url: "/v1/admin/serverless-specs/list",
    query: params,
  });
}

// 新增serverless 产品
export function addServerlessProduct(data: any) {
  return request({
    url: "/v1/admin/serverless-specs",
    method: "POST",
    data,
  });
}

// 编辑serverless 产品
export function editServerlessProduct(data: any) {
  return request({
    url: "/v1/admin/serverless-specs",
    method: "PUT",
    data,
  });
}

// 删除serverless 产品
export function deleteServerlessProduct(id: string) {
  return request({
    url: `/v1/admin/serverless-specs`,
    method: "DELETE",
    query: { id },
  });
}

// 更新产品分类排序
export function updateProductTypeSort(data: {
  sorts: Array<{
    id: number;
    sort: number;
  }>;
}) {
  return request({
    url: "/v1/admin/product-category/sort",
    method: "PUT",
    data,
  });
}
