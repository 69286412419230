import { createRoot } from "react-dom/client";
import { registerMicroApps, start } from "qiankun";
import App from "./App.tsx";
import { store } from "./store";
import { Provider } from "react-redux";
import "./index.css";
// import './fetchFilter.ts'
// 设置antd中文
import { ConfigProvider } from "antd";
import zhCN from "antd/locale/zh_CN";
import { getSubAppUrl } from "./config.ts";

const entry = getSubAppUrl();
// const strapiEntry = getStrapiUrl();

registerMicroApps([
  {
    name: "model-api admin",
    entry: entry,
    container: "#subapp-container",
    activeRule: "/model-api",
  },
]);

// registerMicroApps([
//   {
//     name: "strapi",
//     entry: strapiEntry + "/admin",
//     container: "#subapp-strapi",
//     activeRule: "/admin",
//   },
// ]);

start({
  singular: false,
  prefetch: true, // 预先请求，否值直接访问微服务模块会返回404
});

createRoot(document.getElementById("root")!).render(
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>
);
