
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

enum PodStatus {
  // 待创建
  ToBeCreated = "toBeCreated",
  // 创建中
  CreatePending = "pending",
  // 已创建
  Created = "created",
  // 待启动
  ToBeStarted = "toBeStarted",
  // 启动中
  Starting = "starting",
  // 运行中
  Running = "running",
  // 待停止
  ToBeExited = "toBeExited",
  // 停止中
  Stopping = "stopping",
  // 已停止
  Exited = "exited",
  // 待删除
  ToBeTerminated = "toBeTerminated",
  // 删除中
  Terminating = "terminating",
  // 已删除
  Terminated = "terminated",
  // 未知
  Unknown = "unknown",

  toCreate = "toCreate", // 待创建
  creating = "creating", // 创建中
  pulling = "pulling", // 镜像拉取中
  running = "running", // 运行中
  toStart = "toStart", // 待启动
  starting = "starting", // 启动中
  toStop = "toStop", // 待停止
  stopping = "stopping", // 停止中
  exited = "exited", // 已停止
  toRemove = "toRemove", // 待删除
  removing = "removing", // 删除中
  removed = "removed", // 已删除
}
function getPodStateName(state: PodStatus): string {
  switch (state) {
    case PodStatus.ToBeCreated:
    case PodStatus.CreatePending:
      return "创建中";
    case PodStatus.Created:
      return "已创建";
    case PodStatus.ToBeStarted:
    case PodStatus.Starting:
      return "开机中";
    case PodStatus.Running:
      return "运行中";
    case PodStatus.ToBeExited:
    case PodStatus.Stopping:
      return "关机中";
    case PodStatus.Exited:
      return "已关机";
    case PodStatus.ToBeTerminated:
    case PodStatus.Terminating:
      return "释放中";
    case PodStatus.Terminated:
      return "已释放";

    case PodStatus.creating:
      return "创建中";
    case PodStatus.toCreate:
      return "待创建";
    case PodStatus.pulling:
      return "镜像拉取中";
    // case PodStatus.running:
    //   return "运行中";
    case PodStatus.toStart:
      return "待启动";
    case PodStatus.starting:
      return "启动中";
    case PodStatus.toStop:
      return "待停止";
    case PodStatus.stopping:
      return "停止中";
    case PodStatus.exited:
      return "已停止";
    case PodStatus.toRemove:
      return "待删除";
    case PodStatus.removing:
      return "删除中";
    case PodStatus.removed:
      return "已删除";
  }
  return "其他";
}
function stateColor(state: any) {
  switch (state) {
    case PodStatus.Running:
    case PodStatus.running:
      return "#1890ff";
    case PodStatus.ToBeCreated:
    case PodStatus.CreatePending:
    case PodStatus.Created:
    case PodStatus.ToBeStarted:
    case PodStatus.pulling:
    case PodStatus.toStart:
    case PodStatus.toCreate:
      return "#52c410";
    case PodStatus.Exited:
    case PodStatus.Terminated:
    case PodStatus.exited:
    case PodStatus.removed:
      return "darkgrey";
    case PodStatus.Starting:
    case PodStatus.ToBeExited:
    case PodStatus.Stopping:
    case PodStatus.starting:
    case PodStatus.toStop:
    case PodStatus.stopping:
    case PodStatus.creating:
      return "#faad14";
    case PodStatus.ToBeTerminated:
    case PodStatus.Terminating:
    case PodStatus.toRemove:
    case PodStatus.removing:
      return "#ff4d4f";
    default:
      return "red";
  }
  return "#1890ff";
}
export default function PodState(props: any) {
  console.log("PodState props:", props);
  const initColor = props?.errorText ? "red" : "";
  return (
    <>
      <div
        style={{
          color: stateColor(props.state),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span
          className={"text-lg"}
          style={{ fontSize: "31px", lineHeight: "23px", marginRight: "5px" }}
        >
          •
        </span>
        <span>{getPodStateName(props.state)}</span>
        {props.errorText ? (
          <Tooltip
            title={
              <div>
                <div>{props.errorText}</div>
                <div>{props.errorMessage}</div>
              </div>
            }
          >
            <span style={{ marginLeft: "5px", color: initColor }}>
              <QuestionCircleOutlined />
            </span>
          </Tooltip>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
