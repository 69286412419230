export const CONTINENT_ITEMS = [{
  label: "亚洲",
  value: "Asia"
},{
  label: "欧洲",
  value: "Europe"
},{
  label: "大洋洲",
  value: "Oceania"
},{
  label: "南极洲",
  value: "Antarctica"
},{
  label: "非洲",
  value: "Africa"
},{
  label: "北美洲",
  value: "NorthAmerica"
},{
  label: "南美洲",
  value: "SouthAmerica"
},];
export const CONTINENT_ITEMS_ALL = [{
  label: "全部",
  value: ""
}, ...CONTINENT_ITEMS];
export const PROVINCE_LIST = [
    '河北',
    '山西',
    '辽宁',
    '吉林',
    '黑龙江',
    '江苏',
    '浙江',
    '安徽',
    '福建',
    '江西',
    '山东',
    '河南',
    '湖北',
    '湖南',
    '内蒙古',
    '广西',
    '西藏',
    '宁夏',
    '新疆',
    '北京',
    '天津',
    '上海',
    '广东',
    '海南',
    '四川',
    '贵州',
    '云南',
    '陕西',
    '甘肃',
    '青海',
    '台湾',
    '重庆',
    '香港',
    '澳门',
  ]
// export const PROVINCE_LIST_ALL = ['全部', ...PROVINCE_LIST]
  
export const PROVINCE_ITEMS = PROVINCE_LIST.map((item) => ({label: item, value: item}));
export const PROVINCE_ITEMS_ALL = [{label: '全部', value: ""}, ...(PROVINCE_LIST.map((item) => ({label: item, value: item})))];
