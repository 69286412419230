import { useState } from "react";
import { Button, Form, Input, Modal } from "antd";

export default function EditStorageProduct({
  type = "Add",
  showModal = false,
  storageProductInfo = {},
  finishOperate,
  btnLoading,
}: {
  type: string; // "Add" | "Edit",
  showModal: boolean;
  storageProductInfo: any;
  finishOperate: (cancelMark: boolean, type: string, data: any) => void;
  btnLoading: boolean;
}) {
  let dataTmp: any = {
    productName: "",
  };
  if (type !== "Add") {
    dataTmp = { ...storageProductInfo };
  }
  const [storageProductForm, setStorageProductForm] = useState<{
    productId?: any;
    productName: any;
    freeRootfs: number;
    freeLocalStorage: number;
  }>(dataTmp);
  function cancelOperate() {
    finishOperate(false, "", {});
  }
  function operateData() {
    finishOperate(true, type, storageProductForm);
  }
  function inputStorageProductFormText(item: string, e: any, valueType = "") {
    const storageProductFormTmp: any = storageProductForm;
    storageProductFormTmp[item] = valueType === "value" ? e : e.target.value;
    setStorageProductForm({ ...storageProductFormTmp });
  }
  return (
    <>
      <Modal
        open={showModal}
        title={type === "Add" ? "添加存储产品" : "修改存储产品"}
        width={600}
        onCancel={cancelOperate}
        footer={[
          <Button onClick={cancelOperate}>取消</Button>,
          <Button type="primary" loading={btnLoading} onClick={operateData}>
            确定
          </Button>,
        ]}
      >
        <div className="flex flex-row">
          <div style={{ width: "100%" }}>
            <Form
              className="mr-10"
              label-position="right"
              labelCol={{ span: 10 }}
            >
              <Form.Item label="产品id" required>
                {storageProductForm.productId}
              </Form.Item>
              <Form.Item label="产品名称" required>
                <Input
                  onChange={(e: any) =>
                    inputStorageProductFormText("productName", e)
                  }
                  value={storageProductForm.productName}
                />
              </Form.Item>
              <Form.Item label="免费（Root Filesystem）(GB)" required>
                <Input
                  onChange={(e: any) =>
                    inputStorageProductFormText("freeRootfs", e)
                  }
                  value={storageProductForm.freeRootfs}
                />
              </Form.Item>
              <Form.Item label="免费本地存储(GB)" required>
                <Input
                  onChange={(e: any) =>
                    inputStorageProductFormText("freeLocalStorage", e)
                  }
                  value={storageProductForm.freeLocalStorage}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}
