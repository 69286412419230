import { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, InputNumber, Modal, Select, message } from "antd";
import { getGpuInfos } from "@/api/business";
import { cloudServiceTypes } from "./constants";
import { getClusters } from "@/api/business";
import { getProductType } from "@/api/pricing";
import { queryAccountWithoutCatchErr } from "@/api/user";

export default function EditPowerProduct({
  type = "Add",
  showModal = false,
  powerProductInfo = {},
  finishOperate,
  btnLoading,
}: {
  type: string; // "Add" | "Edit",
  showModal: boolean;
  powerProductInfo: any;
  finishOperate: (cancelMark: boolean, type: string, data: any) => void;
  btnLoading: boolean;
}) {
  let dataTmp: any = {
    productNameOut: "",
    productNameInner: "",
    gpuModelId: "",
    gpuModelName: "",
    gpuSize: "",
    standAloneGpuNumber: "",
    cpuCoreNumberPerGpu: "",
    memorySizePerGpu: "",
    availableInventory: "",
    sortedWeight: 0,
    cloudServiceType: "",
    isWhitelistEnabled: false,
    whitelist: [],
    whitelistUsers: []
  };
  if (type !== "Add") {
    dataTmp = { ...powerProductInfo };
    // dataTmp = powerProductInfo;
  }
  const [powerProductForm, setPowerProductForm] = useState<{
    productId?: any;
    productNameOut: any;
    productNameInner: any;
    gpuModelId: any;
    gpuModelName: any;
    gpuSize: any;
    available?: any;
    inventory?: any;
    standAloneGpuNumber: any;
    cpuCoreNumberPerGpu: any;
    maxCpuCoreNumberPerGpu: any;
    memorySizePerGpu: any;
    maxMemorySizePerGpu: any;
    availableInventory: any;
    sortedWeight: any;
    cloudServiceType: any;
    productCategory: any;
    isWhitelistEnabled: boolean;
    whitelist: any[];
    whitelistUsers: any[];
  }>(dataTmp);

  const [productCategoryList, setProductCategoryList] = useState<any[]>([]);
  const [whitelistUsers, setWhitelistUsers] = useState<any[]>(dataTmp.whitelistUsers || []);

  useEffect(() => {
    getProductType({}).then((res) => {
      if (Array.isArray(res.data)) {
        setProductCategoryList(
          res.data
            .filter((one: any) => one.businessType === "gpu_instance")
            .map((one: any) => ({
              value: one.category,
              label: one.category,
            }))
        );
      }
    });
  }, []);

  function cancelOperate() {
    finishOperate(false, "", {});
  }
  const [gpuModels, setGpuModels] = useState([]);
  function getTableData() {
    getGpuInfos({}).then((res: any) => {
      console.log(res);
      setGpuModels(res.data || []);
    });
  }
  const [, setRegionList] = useState([]);
  useEffect(() => {
    getTableData();
    getClusters({ pageNo: 1, pageSize: 1000 }).then((res: any) => {
      console.log(res);
      setRegionList(res.data || []);
    });
  }, []);

  function isNonNegativeInteger(str: any) {
    const pattern = /^[0-9]+$/; // 定义正则表达式
    return pattern.test(str); // 返回true或false
  }
  function operateData() {
    if (
      !powerProductForm.productNameOut ||
      powerProductForm.productNameOut.trim() === "" ||
      !powerProductForm.productNameInner ||
      powerProductForm.productNameInner.trim() === ""
    ) {
      message.warning("请完善内外部产品名称");
      return;
    }
    if (!powerProductForm.gpuModelId && powerProductForm.gpuModelId !== 0) {
      message.warning("请选择显卡型号");
      return;
    }
    if (!isNonNegativeInteger(powerProductForm.gpuSize)) {
      message.warning("请输入合法的显存");
      return;
    }
    if (!isNonNegativeInteger(powerProductForm.standAloneGpuNumber)) {
      message.warning("请输入合法的单机卡数");
      return;
    }
    if (
      !isNonNegativeInteger(powerProductForm.cpuCoreNumberPerGpu) ||
      !isNonNegativeInteger(powerProductForm.maxCpuCoreNumberPerGpu)
    ) {
      message.warning("请输入合法的CPU核数/卡");
      return;
    }
    if (
      Number(powerProductForm.cpuCoreNumberPerGpu) >
      Number(powerProductForm.maxCpuCoreNumberPerGpu)
    ) {
      message.warning("CPU核数/卡最小值不能大于最大值");
      return;
    }
    if (
      !isNonNegativeInteger(powerProductForm.memorySizePerGpu) ||
      !isNonNegativeInteger(powerProductForm.maxMemorySizePerGpu)
    ) {
      message.warning("请输入合法的内存容量/卡");
      return;
    }
    if (
      Number(powerProductForm.memorySizePerGpu) >
      Number(powerProductForm.maxMemorySizePerGpu)
    ) {
      message.warning("内存容量/卡最小值不能大于最大值");
      return;
    }
    if (
      !powerProductForm.cloudServiceType &&
      powerProductForm.cloudServiceType !== 0
    ) {
      message.warning("请选择云服务类型");
      return;
    }
    const whitelistUsersTmp: any[] = [];
    (value || []).forEach((ele: any) => {
      const item = whitelistUsers.find((item: any) => item.uuid === ele);
      if (item) {
        whitelistUsersTmp.push(item);
      }
    });
    finishOperate(true, type, { ...powerProductForm, whitelistUsers: whitelistUsersTmp, whitelist: value });
  }
  function inputPowerProductFormText(item: string, e: any, valueType = "") {
    const powerProductFormTmp: any = powerProductForm;
    powerProductFormTmp[item] = valueType === "value" ? e : e.target.value;
    if (item === "gpuModelId") {
      powerProductFormTmp.gpuModelName =
        (
          gpuModels.find(
            (item: any) => item.id === powerProductFormTmp.gpuModelId
          ) as any
        ).name || "";
    }
    if (item === "gpuModelId" || item === "gpuSize") {
      powerProductFormTmp.productNameOut =
        powerProductFormTmp.gpuModelName +
        " " +
        (powerProductFormTmp.gpuSize || powerProductFormTmp.gpuSize === 0
          ? powerProductFormTmp.gpuSize + "GB"
          : "");
      powerProductFormTmp.productNameInner =
        powerProductFormTmp.gpuModelName +
        " " +
        (powerProductFormTmp.gpuSize || powerProductFormTmp.gpuSize === 0
          ? powerProductFormTmp.gpuSize + "GB"
          : "");
    }
    setPowerProductForm({ ...powerProductFormTmp });
  }
  const [value, setValue] = useState<string[]>(dataTmp.whitelist || []);
  let timeout: ReturnType<typeof setTimeout> | null;
  let currentValue: string;
  
  const fetch = (value: string, callback: (data: any) => void) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
  
    const fake = () => {
      queryAccountWithoutCatchErr(value).then((res: any) => {
        console.log("queryAccountWithoutCatchErr res: ", res);
        if (currentValue === value) {
          callback(res);
        }
      })
    };
    if (value.length > 0) {
      timeout = setTimeout(fake, 300);
    } else {
      callback(null);
    }
  };
  function dealData(data: any) {
    if (!data) {
      return;
    }
    if (whitelistUsers.map((ele: any) => ele.uuid).includes(data.uuid)) {
      return;
    } else {
      const newData = [...whitelistUsers, data];
      setWhitelistUsers(newData);
    }
  }
  const handleSearch = (newValue: string) => {
    fetch(newValue, dealData);
  };

  const handleChangeTmp = (newValue: string[]) => {
    setValue(newValue);
  };
  return (
    <>
      <Modal
        open={showModal}
        width={"820px"}
        title={type === "Add" ? "添加算力产品" : "修改算力产品"}
        onCancel={cancelOperate}
        footer={[
          <Button onClick={cancelOperate}>取消</Button>,
          <Button type="primary" loading={btnLoading} onClick={operateData}>
            确定
          </Button>,
        ]}
      >
        <div className="flex">
          <Form
            className="mr-10"
            label-position="right"
            labelCol={{ span: 12 }}
          >
            <div>
            <div
              style={{ width: "49%", display: "inline-block" }}
            >
              <Form.Item label="产品id">{powerProductForm.productId}</Form.Item>
              <Form.Item label="产品名称（外部）" required>
                <Input
                  onChange={(e: any) =>
                    inputPowerProductFormText("productNameOut", e)
                  }
                  value={powerProductForm.productNameOut}
                />
              </Form.Item>
              <Form.Item label="显卡型号" required>
                <Select
                  options={gpuModels.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  value={powerProductForm.gpuModelId}
                  onChange={(e: any) =>
                    inputPowerProductFormText("gpuModelId", e, "value")
                  }
                ></Select>
                {/* <Input onChange={(e: any) => inputPowerProductFormText("gpuModel", e)} value={powerProductForm.gpuModel}/> */}
              </Form.Item>
              <Form.Item label="单机卡数" required>
                <Input
                  onChange={(e: any) =>
                    inputPowerProductFormText("standAloneGpuNumber", e)
                  }
                  value={powerProductForm.standAloneGpuNumber}
                />
              </Form.Item>
              <Form.Item label="内存容量/卡（最小值）" required>
                <Input
                  onChange={(e: any) =>
                    inputPowerProductFormText("memorySizePerGpu", e)
                  }
                  value={powerProductForm.memorySizePerGpu}
                />
              </Form.Item>
              <Form.Item label="CPU核数/卡（最小值）" required>
                <Input
                  onChange={(e: any) =>
                    inputPowerProductFormText("cpuCoreNumberPerGpu", e)
                  }
                  value={powerProductForm.cpuCoreNumberPerGpu}
                />
              </Form.Item>
              <Form.Item label="云服务类型" required>
                <Select
                  options={cloudServiceTypes}
                  value={powerProductForm.cloudServiceType}
                  onChange={(e: any) =>
                    inputPowerProductFormText("cloudServiceType", e, "value")
                  }
                ></Select>
                {/* <Input onChange={(e: any) => inputPowerProductFormText("cloudServiceType", e)} value={powerProductForm.cloudServiceType}/> */}
              </Form.Item>
            </div>
            <div
              style={{ width: "49%", display: "inline-block" }}
            >
              <Form.Item label="产品类型">
                <Select
                  options={productCategoryList}
                  value={powerProductForm.productCategory}
                  onChange={(e: any) =>
                    inputPowerProductFormText("productCategory", e, "value")
                  }
                ></Select>
              </Form.Item>
              <Form.Item label="产品名称（内部）" required>
                <Input
                  onChange={(e: any) =>
                    inputPowerProductFormText("productNameInner", e)
                  }
                  value={powerProductForm.productNameInner}
                />
              </Form.Item>
              <Form.Item label="显存" required>
                <Input
                  onChange={(e: any) => inputPowerProductFormText("gpuSize", e)}
                  value={powerProductForm.gpuSize}
                />
              </Form.Item>
              <Form.Item label="排序权重" required>
                <InputNumber
                  style={{ width: "175px" }}
                  min={-100}
                  max={100}
                  onChange={(e: any) =>
                    inputPowerProductFormText("sortedWeight", e, "value")
                  }
                  value={powerProductForm.sortedWeight}
                />
              </Form.Item>
              <Form.Item label="内存容量/卡（最大值）" required>
                <Input
                  onChange={(e: any) =>
                    inputPowerProductFormText("maxMemorySizePerGpu", e)
                  }
                  value={powerProductForm.maxMemorySizePerGpu}
                />
              </Form.Item>
              <Form.Item label="CPU核数/卡（最大值）" required>
                <Input
                  onChange={(e: any) =>
                    inputPowerProductFormText("maxCpuCoreNumberPerGpu", e)
                  }
                  value={powerProductForm.maxCpuCoreNumberPerGpu}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item label={<Checkbox checked={powerProductForm.isWhitelistEnabled} onChange={(e: any) => inputPowerProductFormText("isWhitelistEnabled", e.target.checked, "value")}>开启白名单（输入uuid或手机号进行搜索）</Checkbox>}>
                <Select
                  mode="multiple"
                  showSearch
                  value={value}
                  defaultActiveFirstOption={false}
                  suffixIcon={null}
                  filterOption={false}
                  onSearch={handleSearch}
                  onChange={handleChangeTmp}
                  notFoundContent={null}
                  options={(whitelistUsers || []).map((d) => ({
                    value: d.uuid,
                    label: d.phone,
                  }))}
                />
              </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
}
