import {
  Breadcrumb,
  Form,
  Input,
  Select,
  Button,
  Space,
  Table,
  message,
  Modal,
  Tag,
} from "antd";
import styles from "./index.module.css";
import { ProductDiscount } from "./types";
import { useState, useEffect, useCallback, useMemo } from "react";
import { ColumnsType } from "antd/es/table";
import {
  deleteDiscount,
  getDiscountList,
  getProductType,
  updateDiscount,
} from "@/api/pricing";
import { PaginationComp } from "@/components/pagination";
import { displayPriceFormat, removeObjectEmptyValues } from "@/utils";
import { AddDiscountModal } from "./components/AddConfigModal";
import { BusinessLineList } from "@/constants";
import dayjs from "dayjs";
import { UpdateConfigModal } from "./components/updateConfigModal";
import { DisDetail } from "./components/DisDetail";
import { useQuery } from "@/hooks/useQuery";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { hasDeleteDiscountResource, hasSetDiscountResource, hasDiscountAllowActivityResource } from "@/constants/auth";

const { Item } = Form;

export function Discount() {
  const query = useQuery();
  const { resource } = useSelector((state: RootState) => state.user);
  const allowActivity = hasDiscountAllowActivityResource(resource);
  const columns: ColumnsType<ProductDiscount> = [
    {
      title: "折扣名称",
      dataIndex: "name",
    },
    {
      title: "折扣类型",
      dataIndex: "applyRange",
      render(v) {
        return v === "all" ? "活动折扣" : "专属折扣";
      },
    },
    {
      title: "生效用户",
      dataIndex: "applyUser",
      render(_: unknown, record: any) {
        return <div>
          <div>{record?.applyUserInfo?.email ?? record?.applyUserInfo?.uuid}</div>
          <div>
            {record?.applyUserInfo?.phone}
          </div>
        </div>
      },
    },
    {
      title: "业务线",
      dataIndex: "applyBizType",
    },
    {
      title: "产品分类",
      dataIndex: "applyProductCategory",
    },
    {
      title: "产品SKU",
      dataIndex: "displayName",
      render(value: string, record: any) {
        if (Array.isArray(record.regionNames) && record.regionNames.length > 0) {
          return (
            <div>
              {record.displayName}
              <div>
                {record.regionNames.map((one: string) => (
                  <Tag key={one}>
                    {one}
                  </Tag>
                ))}
              </div>
            </div>
          );
        }
        return record.applyProduct === "all" ? "全部产品" : value;
      },
    },
    {
      title: "计费方式",
      dataIndex: "billingMethod",
      render(v: string) {
        if (v == "1") {
          return "Pay-as-you-go";
        }
        if (v == "2") {
          return "Subscription";
        }
        return "";
      },
    },
    {
      title: "目录价",
      dataIndex: "basePrice0",
      render(_: unknown, record: any) {
        if (record.applyProduct === "all") {
          return (
            <Button
              type="link"
              onClick={() => {
                setDetailModal({ open: true, id: record.id });
              }}
              style={{ padding: 0 }}
            >
              查看详情
            </Button>
          );
        }
        if (record.applyProductCategory === "llm") {
          return (
            <div>
              <div>
                input_token_price:{" "}
                {displayPriceFormat(record.basePrice0, record.pricePrecision)}
              </div>
              <div>
                output_token_price:{" "}
                {displayPriceFormat(record.basePrice1, record.pricePrecision)}
              </div>
            </div>
          );
        }
        return displayPriceFormat(record.basePrice0, record.pricePrecision);
      },
    },
    {
      title: "折扣系数",
      dataIndex: "discountRate",
      render(v) {
        return v ? `${v}%` : "";
      },
    },
    {
      title: "折扣价",
      dataIndex: "discountPrice0",
      render(v, record) {
        if (record.applyProduct === "all") {
          return (
            <Button
              type="link"
              onClick={() => {
                setDetailModal({ open: true, id: record.id });
              }}
              style={{ padding: 0 }}
            >
              查看详情
            </Button>
          );
        }
        if (record.applyProductCategory === "llm") {
          return (
            <div>
              <div>
                input_token_price:{" "}
                {displayPriceFormat(
                  record.discountPrice0,
                  record.pricePrecision
                )}
              </div>
              <div>
                output_token_price:{" "}
                {displayPriceFormat(
                  record.discountPrice1,
                  record.pricePrecision
                )}
              </div>
            </div>
          );
        }
        return displayPriceFormat(v, record.pricePrecision);
      },
    },
    {
      title: "生效方式",
      dataIndex: "discountType",
      render(_: unknown, record: any) {
        return record.discountType === "discount_rate" ? "折扣系数" : "折扣价";
      },
    },

    {
      title: "生效时间",
      dataIndex: "applyRange",
      render(_: unknown, record: any) {
        if (record.applyStartTime && record.applyEndTime) {
          return `${dayjs
            .unix(record.applyStartTime)
            .format("YYYY-MM-DD")} - ${dayjs
              .unix(record.applyEndTime)
              .format("YYYY-MM-DD")}`;
        }
      },
    },
    {
      title: "上架状态",
      dataIndex: "status",
      render(_: unknown, record: any) {
        return record.status ? (
          <Tag color="success">已上架</Tag>
        ) : (
          <Tag color="error">已下架</Tag>
        );
      },
    },
    {
      title: "状态",
      dataIndex: "dd",
      render(_: unknown, record: any) {
        const nowUnix = dayjs().unix();
        if (record.applyStartTime && record.applyEndTime) {
          if (nowUnix < Number(record.applyStartTime)) {
            return "未生效";
          }
          if (nowUnix > Number(record.applyEndTime)) {
            return "已过期";
          }
          if (
            nowUnix > Number(record.applyStartTime) &&
            nowUnix < Number(record.applyEndTime)
          ) {
            return "生效中";
          }
        } else {
          return "已过期";
        }
      },
    },
    {
      title: "创建人",
      dataIndex: "createdUser",
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 220,
      fixed: "right",
      render(_: unknown, record: any) {
        return (
          <div>
            {hasSetDiscountResource(resource) && (
              <Button
                type="link"
                onClick={() => {
                  setConfigModal({
                    open: true,
                    type: "edit",
                    defaultData: record,
                  });
                }}
              >
                编辑
              </Button>
            )}
            {hasSetDiscountResource(resource) && (
              <Button
                type="link"
                onClick={() => {
                  // 判断是否在有效期内
                  const nowUnix = dayjs().unix();
                  if (
                    record.applyEndTime &&
                    nowUnix > Number(record.applyEndTime)
                  ) {
                    message.error("折扣已过期，请先修改生效时间");
                    return;
                  }
                  Modal.confirm({
                    title: record.status ? "确认下架" : "确认上架",
                    content: record.status
                      ? "确认下架该折扣吗？"
                      : "确认上架该折扣吗？",
                    onOk: () => {
                      updateDiscount({
                        ...record,
                        status: !record.status,
                      }).then(() => {
                        message.success("操作成功");
                        fetchData();
                      });
                    },
                  });
                }}
              >
                {record.status ? "下架" : "上架"}
              </Button>
            )}
            {hasDeleteDiscountResource(resource) && (
              <Button
                danger
                type="link"
                onClick={() => {
                  handleDelete(record.id);
                }}
              >
                删除
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<ProductDiscount[]>([]);
  const [pageParams, setPageParams] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [selectedBusinessType, setSelectedBusinessType] = useState<string>("");
  const [productCategoryList, setProductCategoryList] = useState<any[]>([]);
  const [configModal, setConfigModal] = useState<{
    open: boolean;
    type: "add" | "edit";
    defaultData: ProductDiscount | null;
    addDefaultData?: any;
  }>({
    open: false,
    type: "add",
    defaultData: null,
    addDefaultData: null,
  });
  const [detailModal, setDetailModal] = useState<{
    open: boolean;
    id: string;
  }>({
    open: false,
    id: "",
  });

  const categoryOptions = useMemo(() => {
    if (!selectedBusinessType || selectedBusinessType === "") {
      return productCategoryList;
    }
    return productCategoryList.filter(
      (one) => one.businessType === selectedBusinessType
    );
  }, [productCategoryList, selectedBusinessType]);

  const resetUrlParams = useCallback(() => {
    query.delete("productId");
    query.delete("productCategory");
    query.delete("businessType");
    window.history.replaceState({}, "", `${window.location.pathname}`);
  }, [query]);

  const fetchData = useCallback(
    (urlParams?: any) => {
      const params = form.getFieldsValue();
      const cleanParams = removeObjectEmptyValues(params);
      const queryParams = {
        ...pageParams,
        ...cleanParams,
        ...urlParams,
      };
      setLoading(true);
      getDiscountList(queryParams)
        .then((res) => {
          if (Array.isArray(res.list)) {
            setDataSource(res.list);
            setTotal(res.total);

            // when has urlParams, and no data
            // open the add modal, and set the default data
            if (
              res.list.length === 0 &&
              urlParams?.applyProduct &&
              urlParams?.applyProductCategory &&
              hasSetDiscountResource(resource)
            ) {
              setConfigModal({
                open: true,
                type: "add",
                defaultData: null,
                addDefaultData: urlParams,
              });
              resetUrlParams();
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [form, pageParams, resetUrlParams, resource]
  );

  const handleDelete = (id: string) => {
    Modal.confirm({
      title: "确认删除",
      content: "确认删除该折扣吗？",
      onOk: () => {
        deleteDiscount(id).then(() => {
          message.success("删除成功");
          fetchData();
        });
      },
    });
  };

  useEffect(() => {
    if (!configModal.open) {
      const productId = query.get("productId");
      const productCategory = query.get("productCategory");
      const applyBizType = query.get("businessType");
      if (productId && productCategory) {
        fetchData({
          applyProduct: productId,
          applyProductCategory: productCategory,
          applyBizType,
        });
      } else {
        fetchData();
      }
    }
  }, [fetchData, configModal.open, query]);

  useEffect(() => {
    getProductType({}).then((res) => {
      if (Array.isArray(res.data)) {
        setProductCategoryList(
          res.data.map((one: any) => ({
            category: one.category,
            businessType: one.businessType,
          }))
        );
      }
    });
  }, []);

  return (
    <div>
      <div>
        <Breadcrumb
          items={[
            {
              title: "平台计费",
            },
            {
              title: "折扣管理",
            },
          ]}
        />
      </div>
      <div className={styles.search_container}>
        <div className={styles.search_form}>
          <Form layout="inline" form={form}>
            <Item label="折扣名称" name="name">
              <Input />
            </Item>
            <Item label="业务线" name="applyBizType">
              <Select
                style={{ width: 160 }}
                options={BusinessLineList}
                onChange={(value) => {
                  setSelectedBusinessType(value);
                }}
                allowClear
              />
            </Item>
            <Item label="产品分类" name="applyProductCategory">
              <Select
                style={{ width: 160 }}
                options={categoryOptions.map((one) => ({
                  label: one.category,
                  value: one.category,
                }))}
                allowClear
              />
            </Item>
            <Item label="生效用户" name="applyUser">
              <Input />
            </Item>
            <Item>
              <Space>
                <Button
                  type="primary"
                  onClick={() => fetchData()}
                  loading={loading}
                >
                  搜索
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields();
                    resetUrlParams();
                    setPageParams({
                      pageIndex: 1,
                      pageSize: 10,
                    });
                    fetchData();
                  }}
                >
                  重置
                </Button>
              </Space>
            </Item>
          </Form>
          <div>
            {hasSetDiscountResource(resource) && (
              <Button
                type="primary"
                onClick={() => {
                  setConfigModal({
                    open: true,
                    type: "add",
                    defaultData: null,
                  });
                }}
              >
                新增折扣
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.table_container}>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowKey="id"
          loading={loading}
          scroll={{ x: "max-content" }}
        />
      </div>
      <div className={styles.pagination_container}>
        <PaginationComp
          total={total}
          pageSize={pageParams.pageSize}
          pageNum={pageParams.pageIndex}
          onChange={(pageNum, pageSize) => {
            setPageParams({ pageIndex: pageNum, pageSize });
          }}
        />
      </div>
      <AddDiscountModal
        open={configModal.open && configModal.type === "add"}
        onCancel={() => setConfigModal({ ...configModal, open: false })}
        productCategoryList={productCategoryList}
        addDefaultData={configModal.addDefaultData}
        allowActivity={allowActivity}
      />
      <UpdateConfigModal
        open={configModal.open && configModal.type === "edit"}
        onCancel={() => setConfigModal({ ...configModal, open: false })}
        defaultValue={configModal.defaultData}
        productCategoryList={productCategoryList}
      />
      <DisDetail
        open={detailModal.open}
        onCancel={() => setDetailModal({ ...detailModal, open: false })}
        id={detailModal.id}
      />
    </div>
  );
}
