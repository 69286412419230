import { getStrapiUrl, NestServerUrl } from "@/config";
import { request } from "./fetch";

export function queryUsers(username: string) {
  const strapiUrl = getStrapiUrl();
  return request({
    baseUrl: NestServerUrl,
    url: "/strapi/generate",
    method: "POST",
    data: {
      username: username,
      strapiUrl: strapiUrl,
    },
  });
}
