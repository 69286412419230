import { queryUsers } from "@/api/strapi";
import { getStrapiUrl } from "@/config";
import { RootState } from "@/store";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./index.module.css";
import { Drawer, message, Skeleton, Space } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
const strapiBaseUrl = getStrapiUrl();

// const passwordFormat = (username: string) => {
//   return `${username}@pplabs.org-PPIO-2014`;
// };

export function StrapiIframe() {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const [userPwd, setUserPwd] = useState<any>({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const queryUserInfo = useCallback(
    (name: string) => {
      if (userPwd.email && userPwd.password) {
        return;
      }
      setLoading(true);
      queryUsers(name)
        .then((resp) => {
          // if (resp?.email) {
          //   iframeRef.current?.contentWindow?.postMessage(
          //     {
          //       type: "login",
          //       payload: {
          //         email: resp.email,
          //         password: passwordFormat(resp.firstname),
          //       },
          //     },
          //     "*"
          //   );
          // }
          console.log("strapi user info", resp);
          const { isActive, password, email } = resp;
          if (!isActive) {
            message.error("获取失败，请联系达达");
          } else {
            setUserPwd({
              email,
              password,
            });
          }
        })
        .catch((err) => {
          console.log("strapi user info error", err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [userPwd]
  );

  useEffect(() => {
    if (drawerOpen && userInfo?.name) {
      queryUserInfo(userInfo.name);
    }
  }, [userInfo?.name, queryUserInfo, drawerOpen]);

  return (
    <div>
      <div className={styles.notice}>
        <span onClick={() => setDrawerOpen(true)}>点我获取帐号密码</span>
      </div>
      <iframe
        ref={iframeRef}
        id="strapi-iframe"
        src={`${strapiBaseUrl}/admin`}
        style={{
          width: "100%",
          height: "calc(100vh - 144px)",
          border: "none",
          boxSizing: "border-box",
        }}
      ></iframe>
      <Drawer
        title={"帐号默认为 editor 权限，如需修改，请联系 达达"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        placement="top"
        height={150}
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <div style={{ marginBottom: 12, cursor: "pointer" }}>
              <CopyToClipboard
                text={userPwd.email}
                onCopy={() => message.success("邮箱已复制")}
              >
                <Space>
                  <span>邮箱: {userPwd.email}</span>
                  <CopyOutlined />
                </Space>
              </CopyToClipboard>
            </div>

            <div style={{ cursor: "pointer" }}>
              <CopyToClipboard
                text={userPwd.password}
                onCopy={() => message.success("密码已复制")}
              >
                <Space>
                  <span>密码：{userPwd.password}</span>
                  <CopyOutlined />
                </Space>
              </CopyToClipboard>
            </div>
          </>
        )}
      </Drawer>
    </div>
  );
}
