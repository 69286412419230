import { useState } from "react";
import { Button,  Form, Input, Modal} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function PresendVoucher({showModal=false, finishPresend, btnLoading}: {
    templateId: string, // "Add" | "Edit",
    showModal: boolean,
    finishPresend: (success: boolean, customerInfo: any, voucherInfo: any) => void,
    btnLoading: boolean
}) {
    const [customerInfoForm, setCustomerInfoForm] = useState<{
        email: string}>({email: ""});
    function cancelOperate() {
        finishPresend(false, "", {});
    }
    function operateData() {
        Modal.confirm({
            title: '确认',
            icon: <ExclamationCircleOutlined />,
            content: '是否确认发放给客户' + customerInfoForm.email,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                finishPresend(true, customerInfoForm, {});
            }});
    }
    function inputCustomerInfoFormText(item: string, e: any, valueType=""){
        const customerInfoFormTmp: any = {...customerInfoForm};
        customerInfoFormTmp[item] = valueType === "value" ? e : e.target.value;
        setCustomerInfoForm({...customerInfoFormTmp});
    }
    return <>
        <Modal open={ showModal } title="发放代金券" onCancel={cancelOperate} footer={[
            <Button onClick={cancelOperate}>取消</Button>,
            <Button type="primary" loading={btnLoading} onClick={operateData}>确定</Button>
        ]}>
            <div className="flex flex-row">
                <div style={{width: "100%"}}>
                    <Form className="mr-10" label-position="right" labelCol={{span: 6}}>
                        <Form.Item label="客户Email" required>
                            <Input onChange={(e: any) => inputCustomerInfoFormText("email", e)} value={customerInfoForm.email}/>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    </>
}
