import { createPublicBill } from "@/api/pricing";
import { Form, Input, InputNumber, message, Modal } from "antd";
import Big from "big.js";
import { useEffect, useState } from "react";

export default function BillingModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [params, setParams] = useState<{
    uuid: string;
    phone: string;
    rechargeAmount: number | null;
  }>({
    uuid: "",
    phone: "",
    rechargeAmount: null,
  });
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    if (params.rechargeAmount === null) {
      message.error("请输入充值金额");
      return;
    }
    if (params.rechargeAmount === 0) {
      message.error("充值金额不能为0");
      return;
    }
    if (params.phone === "" && params.uuid === "") {
      message.error("请输入充值账号");
      return;
    }
    setLoading(true);
    createPublicBill({ ...params, rechargeAmount: Big(params.rechargeAmount).times(100).toNumber() }).then(() => {
      message.success("创建成功");
      onClose();
    })
      .catch(() => { })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (open) {
      setParams({
        uuid: "",
        phone: "",
        rechargeAmount: null,
      });
    }
  }, [open]);
  return (
    <Modal open={open} onCancel={onClose} title="创建对公账单/对公充值" onOk={handleSubmit} confirmLoading={loading}>
      <Form layout="vertical">
        <Form.Item label="充值账号（手机号/uuid二选一）">
          <div style={{ marginBottom: 10 }}>
            <Input placeholder="手机号" style={{ width: 300 }} value={params.phone} onChange={(e) => setParams({ ...params, phone: e.target.value })} />
          </div>
          <div>
            <Input placeholder="uuid" style={{ width: 300 }} value={params.uuid} onChange={(e) => setParams({ ...params, uuid: e.target.value })} />
          </div>
        </Form.Item>
        <Form.Item label="充值金额(¥)">
          <InputNumber min={0} precision={2} style={{ width: 300 }} controls={false} value={params.rechargeAmount} onChange={(value) => setParams({ ...params, rechargeAmount: value })} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
