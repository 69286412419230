import { Form, Input, Select, DatePicker, Button, Table } from "antd";
import { productTypeList, billStateList, billingModeList } from "@/constants";
import styles from "../index.module.scss";
import { useCallback, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { PaginationComp } from "@/components/pagination";
import { getWalletBilling } from "@/api/beacon";

const { Item } = Form;
const { Option } = Select;

const columns = [
  {
    title: "客户Id",
    key: "userId",
    dataIndex: "userId",
  },
  {
    title: "账单周期",
    key: "billingCycle",
    dataIndex: "billingCycle",
    render: (_: any, record: any) => {
      return (
        <div>
          {(record?.startTime &&
          !isNaN(Number(record.startTime)) &&
          Number(record.startTime)
            ? dayjs.unix(record.startTime).format("YYYY-MM-DD HH:mm:ss")
            : "") +
            "-" +
            (record?.endTime &&
            !isNaN(Number(record.endTime)) &&
            Number(record.endTime)
              ? dayjs.unix(record.endTime).format("YYYY-MM-DD HH:mm:ss")
              : "")}
        </div>
      );
    },
  },
  {
    title: "产品",
    key: "productName",
    dataIndex: "productName",
  },
  {
    title: "产品类型",
    key: "productType",
    dataIndex: "productType",
    render: (_: any, record: any) => {
      const item = productTypeList.find(
        (item: any) => item.value === record.productType
      );
      return <div>{item && item.label ? item.label : record.productType}</div>;
    },
  },
  {
    title: "计费模式",
    key: "billingMode",
    dataIndex: "billingMode",
    render: (_: any, record: any) => {
      const item = billingModeList.find(
        (item: any) => item.value === record.billingMode
      );
      return <div>{item && item.label ? item.label : record.billingMode}</div>;
    },
  },
  {
    title: "应付金额",
    key: "payableAmount",
    dataIndex: "payableAmount",
    render: (_: any, record: any) => {
      return (
        <div>
          {Math.round((Number(record.payableAmount) / 100) * 100) / 100}
        </div>
      );
    },
  },
  {
    title: "代金券抵扣",
    key: "voucherDeduction",
    dataIndex: "voucherDeduction",
    render: (_: any, record: any) => {
      return (
        <div>
          {Math.round((Number(record.voucherDeduction) / 100) * 100) / 100}
        </div>
      );
    },
  },
  {
    title: "现金支付",
    key: "cashPayment",
    dataIndex: "cashPayment",
    render: (_: any, record: any) => {
      return (
        <div>{Math.round((Number(record.cashPayment) / 100) * 100) / 100}</div>
      );
    },
  },
  {
    title: "状态",
    key: "state",
    dataIndex: "state",
    // width: 100,
    render: (_: any, record: any) => {
      const stateEnum: any = {
        paying: "账单中",
        payed: "已支付",
        refund: "已退款",
      };
      return <div>{stateEnum[record.state]}</div>;
    },
  },
];

export function CustomerBill() {
  const [dataSource, setDataSource] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageParams, setPageParams] = useState({
    pageNum: 1,
    pageSize: 10,
  });
  const [params, setParams] = useState({
    userId: "",
    productName: "",
    productType: "",
    state: "",
    billingMode: "",
    startTime: dayjs().startOf("month").format("YYYY-MM-DD"),
    endTime: dayjs().endOf("month").format("YYYY-MM-DD"),
    dateRange: [dayjs().startOf("month"), dayjs().endOf("month")] as Dayjs[],
  });
  const fetchData = useCallback(() => {
    const query = {
      pageNo: pageParams.pageNum,
      pageSize: pageParams.pageSize,
      userId: params.userId,
      productName: params.productName,
      state: params.state,
      productType: params.productType,
      billingMode: params.billingMode,
      startTime: params.startTime
        ? dayjs(params.startTime + " 00:00:00").unix()
        : "",
      endTime: params.endTime ? dayjs(params.endTime + " 23:59:59").unix() : "",
    };
    setLoading(true);
    getWalletBilling(query)
      .then((res) => {
        setDataSource(res.data);
        setTotal(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageParams, params]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // params 变化，重置页码
  useEffect(() => {
    setPageParams({ pageNum: 1, pageSize: 10 });
  }, [params]);
  return (
    <div>
      <div className={styles.common_form}>
        <Form layout="inline">
          <Item label="客户ID">
            <Input
              placeholder="请输入客户ID"
              value={params.userId}
              onChange={(e) => {
                setParams({ ...params, userId: e.target.value });
              }}
            />
          </Item>
          <Item label="产品名称">
            <Input
              className={styles.input}
              placeholder="请输入产品名称"
              value={params.productName}
              onChange={(e) => {
                setParams({ ...params, productName: e.target.value });
              }}
            />
          </Item>
          <Item label="产品类型">
            <Select
              className={styles.select}
              allowClear
              placeholder="请选择"
              value={params.productType}
              onChange={(value) => {
                setParams({ ...params, productType: value });
              }}
            >
              {productTypeList.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Item>
          <Item label="计费模式">
            <Select
              className={styles.select}
              allowClear
              placeholder="请选择"
              value={params.billingMode}
              onChange={(value) => {
                setParams({ ...params, billingMode: value });
              }}
            >
              {billingModeList.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Item>
          <Item label="生成日期">
            <DatePicker.RangePicker
              allowClear={false}
              value={params.dateRange as any}
              onChange={(value) => {
                if (value && value[0] && value[1]) {
                  setParams({
                    ...params,
                    dateRange: [value[0], value[1]],
                    startTime: value[0].format("YYYY-MM-DD"),
                    endTime: value[1].format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Item>
          <Item label="账单状态">
            <Select
              className={styles.select}
              allowClear
              placeholder="请选择"
              value={params.state}
              onChange={(value) => {
                setParams({ ...params, state: value });
              }}
            >
              {billStateList.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Item>
          <Item>
            <Button type="primary" onClick={fetchData}>
              查询
            </Button>
          </Item>
        </Form>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={loading}
        />
      </div>
      <div className={styles.common_pagination}>
        <PaginationComp
          total={total}
          pageSize={pageParams.pageSize}
          pageNum={pageParams.pageNum}
          onChange={(pageNum, pageSize) => {
            setPageParams({ pageNum, pageSize });
          }}
        />
      </div>
    </div>
  );
}
