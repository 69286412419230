import { getProductDiscount } from "@/api/pricing";
import { displayPriceFormat } from "@/utils";
import { Modal, Table } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

export function DisDetailModal({
  open,
  onClose,
  productId,
  businessType,
}: {
  open: boolean;
  onClose: () => void;
  productId: string;
  businessType: string;
}) {
  const [discountList, setDiscountList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (productId && businessType && open) {
      setLoading(true);
      getProductDiscount({
        productId,
        businessType,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            setDiscountList(res.list);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [productId, open, businessType]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      onCancel={onClose}
      title="折扣详情"
      width={800}
      footer={null}
    >
      <Table
        dataSource={discountList}
        loading={loading}
        columns={[
          {
            title: "折扣名称",
            dataIndex: "name",
          },
          {
            title: "生效范围",
            dataIndex: "applyRange",
          },
          {
            title: "折扣比例",
            dataIndex: "discountRate",
            render: (_, record) => {
              return record.discountType == "discount_rate"
                ? record.discountRate + "%"
                : "-";
            },
          },
          {
            title: "折扣价",
            dataIndex: "discountPrice",
            render: (_, record) => {
              return record?.applyProduct == "llm" ? (
                <span>
                  input tokens{" "}
                  {displayPriceFormat(
                    record?.discountPrice0,
                    record?.pricePrecision
                  )}
                  {" / "}
                  {record?.displayPriceUnit}
                  {" - "}
                  output tokens{" "}
                  {displayPriceFormat(
                    record?.discountPrice1,
                    record?.pricePrecision
                  )}
                </span>
              ) : (
                <span>
                  {displayPriceFormat(
                    record?.discountPrice0,
                    record?.pricePrecision
                  )}
                  {" / "}
                  {record?.displayPriceUnit}
                </span>
              );
            },
          },
          {
            title: "生效时间",
            dataIndex: "time",
            render: (_, record) => {
              if (!record.applyStartTime || !record.applyEndTime) {
                return "-";
              }
              return (
                <div>
                  {dayjs.unix(record.applyStartTime).format("YYYY-MM-DD")} -{" "}
                  {dayjs.unix(record.applyEndTime).format("YYYY-MM-DD")}
                </div>
              );
            },
          },
        ]}
      />
    </Modal>
  );
}
