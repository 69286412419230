import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";

export function AppIcon({
  icon,
  url,
  label,
  parentLabel,
}: {
  icon: string;
  url: string;
  label: string;
  parentLabel?: string;
}) {
  const navigate = useNavigate();
  const handleClick = (url: string) => {
    console.log("url", url);
    navigate(url);
  };

  return (
    <div
      className={styles.app_icon__container}
      onClick={() => handleClick(url)}
    >
      <div className={styles.icon_wrap}>
        <img src={icon} alt="icon" className={styles.app_icon__icon} />
      </div>
      <div className={styles.app_icon__text}>
        {parentLabel ? `${parentLabel}-${label}` : label}
      </div>
    </div>
  );
}
