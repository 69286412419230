import { getUserCreditLimit, saveCreditLimit } from "@/api/pricing";
import { getEnv, globalEnv_global } from "@/config";
import { Button, Input, InputNumber, message, Modal, Space, Steps } from "antd";
import { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Big from "big.js";
import { balanceFormat } from "@/utils";

export function AddConfigModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const env = getEnv();
  const [step, setStep] = useState(0);
  const [uuid, setUuid] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [creditInfo, setCreditInfo] = useState({
    userBalance: 0,
    totalBalance: 0,
    voucher: {
      gpuInstance: 0,
      modelApi: 0,
      serverless: 0,
    },
    credit: null as number | null,
    uuid: "",
  });

  const queryUserInfo = useCallback(() => {
    setLoading(true);
    getUserCreditLimit({
      uuid: uuid,
      phone: phone,
      email: email,
    })
      .then((res) => {
        setCreditInfo({
          ...res,
          credit: res.credit
            ? new Big(res.credit).div(10_000).toNumber()
            : null,
        });
        setStep(1);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [uuid, phone, email]);

  const save = useCallback(() => {
    if (creditInfo.credit === null || creditInfo.credit === undefined) {
      message.error("请输入信控额度");
      return;
    }
    setLoading(true);
    saveCreditLimit({
      uuid: creditInfo.uuid,
      credit: creditInfo.credit * 100,
    })
      .then(() => {
        message.success("保存成功");
        setCreditInfo({
          userBalance: 0,
          voucher: {
            gpuInstance: 0,
            modelApi: 0,
            serverless: 0,
          },
          totalBalance: 0,
          credit: null as number | null,
          uuid: "",
        });
        setStep(0);
        // 清除输入框
        setUuid("");
        setPhone("");
        setEmail("");
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [creditInfo, onClose]);

  // 关闭弹窗重置状态
  useEffect(() => {
    if (!open) {
      setCreditInfo({
        userBalance: 0,
        voucher: {
          gpuInstance: 0,
          modelApi: 0,
          serverless: 0,
        },
        totalBalance: 0,
        credit: null as number | null,
        uuid: "",
      });
      setStep(0);
      setUuid("");
      setPhone("");
      setEmail("");
    }
  }, [open]);

  return (
    <Modal open={open} onCancel={onClose} width={600} footer={null}>
      <div style={{ marginTop: "30px" }}>
        <Steps current={step}>
          <Steps.Step title="查询用户信息" />
          <Steps.Step title="编辑信控额度" />
        </Steps>
        <div>
          {step === 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
                marginTop: 20,
              }}
            >
              {env.global === globalEnv_global ? (
                <>
                  <div>下面任选其一</div>
                  <div>
                    <Input
                      placeholder="请输入UUID"
                      value={uuid}
                      onChange={(e) => setUuid(e.target.value)}
                    />
                  </div>
                  <div>
                    <Input
                      placeholder="请输入邮箱"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div>下面任选其一</div>
                  <div>
                    <Input
                      placeholder="请输入UUID"
                      value={uuid}
                      onChange={(e) => setUuid(e.target.value)}
                    />
                  </div>
                  <div>
                    <Input
                      placeholder="请输入手机号"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {step === 1 && (
            <div>
              <table className={styles.credit_limit_table}>
                <tbody>
                  <tr>
                    <td>用户UUID</td>
                    <td>{creditInfo.uuid}</td>
                  </tr>
                  <tr>
                    <td>用户余额</td>
                    <td>{balanceFormat(creditInfo.userBalance)}</td>
                  </tr>
                  <tr>
                    <td>券余额</td>
                    <td>
                      <div>
                        <div>
                          GPU 券余额：
                          {balanceFormat(creditInfo?.voucher?.gpuInstance)}
                        </div>
                        <div>
                          Model API 券余额：
                          {balanceFormat(creditInfo?.voucher?.modelApi)}
                        </div>
                        <div>
                          Serverless 券余额：
                          {balanceFormat(creditInfo?.voucher?.serverless)}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>信控额度</td>
                    <td>
                      <InputNumber
                        value={creditInfo.credit}
                        onChange={(e) =>
                          setCreditInfo({
                            ...creditInfo,
                            credit: e,
                          })
                        }
                        controls={false}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div style={{ marginTop: "20px", textAlign: "right" }}>
          {step === 0 && (
            <Button
              type="primary"
              loading={loading}
              onClick={() => {
                queryUserInfo();
              }}
            >
              下一步
            </Button>
          )}
          {step === 1 && (
            <Space>
              <Button onClick={() => setStep(0)}>上一步</Button>
              <Button type="primary" onClick={save} loading={loading}>
                保存
              </Button>
            </Space>
          )}
        </div>
      </div>
    </Modal>
  );
}
