import { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  message,
} from "antd";
import dayjs from "dayjs";
// `
export default function EditPowerPrice({
  type = "Add",
  subType = "",
  showModal = false,
  powerPriceInfo = {},
  finishOperate,
  btnLoading
}: {
  type: string; // "Add" | "Edit",
  subType?: string;
  showModal: boolean;
  powerPriceInfo: any;
  finishOperate: (
    cancelMark: boolean,
    type: string,
    powerPriceForm: any
  ) => void;
  btnLoading: boolean;
}) {
  let dataTmp: any = {
    productName: "",
    freeCapacity: "",
    chargingModel: [],
    priceByCapacity: "",
    discountRateByCapacity: 1,
    discountTimeByCapacityStart: "",
    discountTimeByCapacityEnd: "",
    ageingDate: [],
    priceAfterDiscount: "",
    dayPrice: "",
    weekPrice: "",
    monthPrice: "",
    yearPrice: "",
    isEffect: "",
  };
  if (type !== "Add") {
    dataTmp = { ...powerPriceInfo };
  }
  if (dataTmp.discountTimeByCapacityStart) {
    dataTmp.ageingDate = [
      dayjs.unix(Number(dataTmp.discountTimeByCapacityStart)),
      dayjs.unix(Number(dataTmp.discountTimeByCapacityEnd)),
    ];
  } else {
    dataTmp.ageingDate = [null, null];
  }
  const [powerPriceForm, setPowerPriceForm] = useState<{
    priceId?: any;
    productName: any;
    freeCapacity: any;
    chargingModel: any;
    priceByCapacity: any;
    discountRateByCapacity: any;
    discountTimeByCapacityStart: any;
    discountTimeByCapacityEnd: any;
    ageingDate: any;
    priceAfterDiscount: any;
    dayPrice: any;
    weekPrice: any;
    monthPrice: any;
    yearPrice: any;
    isEffect: any;
  }>(dataTmp);
  function cancelOperate() {
    finishOperate(false, "", {});
  }
  function operateData() {
    // debugger
    if (subType !== "power") {
      if (
        (!powerPriceForm.freeCapacity && powerPriceForm.freeCapacity !== 0) ||
        isNaN(Number(powerPriceForm.freeCapacity)) ||
        Number(powerPriceForm.freeCapacity) < 0
      ) {
        message.warning("请输入合法的免费容量");
        return;
      }
    }
    if (powerPriceForm.chargingModel?.includes("capacity")) {
      if (
        !powerPriceForm.ageingDate ||
        !powerPriceForm.ageingDate[0] ||
        !powerPriceForm.ageingDate[1]
      ) {
        message.warning("按量计费请选择起止日期");
        return;
      }
      if (
        (!powerPriceForm.priceByCapacity &&
          powerPriceForm.priceByCapacity !== 0) ||
        isNaN(Number(powerPriceForm.priceByCapacity)) ||
        Number(powerPriceForm.priceByCapacity) < 0
      ) {
        message.warning("按量计费请输入合法的单价");
        return;
      }
      if (
        powerPriceForm.discountRateByCapacity === null ||
        isNaN(Number(powerPriceForm.discountRateByCapacity)) ||
        Number(powerPriceForm.discountRateByCapacity) < 0 ||
        Number(powerPriceForm.discountRateByCapacity) > 1
      ) {
        message.warning("按量计费请输入合法的折扣比例");
        return;
      }
    }
    if (powerPriceForm.chargingModel?.includes("day")) {
      if (
        (!powerPriceForm.dayPrice && powerPriceForm.dayPrice !== 0) ||
        isNaN(Number(powerPriceForm.dayPrice)) ||
        Number(powerPriceForm.dayPrice) < 0
      ) {
        message.warning("包日计费请输入合法的单价");
        return;
      }
    }
    if (powerPriceForm.chargingModel?.includes("week")) {
      if (
        (!powerPriceForm.weekPrice && powerPriceForm.weekPrice !== 0) ||
        isNaN(Number(powerPriceForm.weekPrice)) ||
        Number(powerPriceForm.weekPrice) < 0
      ) {
        message.warning("包周计费请输入合法的单价");
        return;
      }
    }
    if (powerPriceForm.chargingModel?.includes("month")) {
      if (
        (!powerPriceForm.monthPrice && powerPriceForm.monthPrice !== 0) ||
        isNaN(Number(powerPriceForm.monthPrice)) ||
        Number(powerPriceForm.monthPrice) < 0
      ) {
        message.warning("包月计费请输入合法的单价");
        return;
      }
    }
    if (powerPriceForm.chargingModel?.includes("year")) {
      if (
        (!powerPriceForm.yearPrice && powerPriceForm.yearPrice !== 0) ||
        isNaN(Number(powerPriceForm.yearPrice)) ||
        Number(powerPriceForm.yearPrice) < 0
      ) {
        message.warning("包年计费请输入合法的单价");
        return;
      }
    }
    finishOperate(true, type, powerPriceForm);
  }
  function inputPowerPriceFormText(item: string, e: any, valueType = "") {
    console.log("item:", item);
    console.log("e:", e);
    console.log("valueType:", valueType);
    const powerPriceFormTmp: any = { ...powerPriceForm };
    powerPriceFormTmp[item] = valueType ? e : e.target.value;
    if (item === "priceByCapacity" || item === "discountRateByCapacity") {
      const priceByCapacity = Number(powerPriceFormTmp.priceByCapacity);
      const discountRateByCapacity = Number(
        powerPriceFormTmp.discountRateByCapacity
      );
      if (!isNaN(priceByCapacity) && !isNaN(discountRateByCapacity)) {
        const discountPriceTmp: number =
          priceByCapacity * discountRateByCapacity;
        if (subType !== "power") {
          // 存储产品保留4位小数
          powerPriceFormTmp.priceAfterDiscount =
            Math.round(discountPriceTmp * 10000) / 10000;
        } else {
          // 算力产品保留2位小数
          powerPriceFormTmp.priceAfterDiscount =
            Math.round(discountPriceTmp * 100) / 100;
        }
      }
    }
    console.log("powerPriceFormTmp:", powerPriceFormTmp);
    setPowerPriceForm({
      ...powerPriceFormTmp,
      // discountTimeByCapacityStart: powerPriceFormTmp.discountTimeByCapacityStart === "0" ? "" : moment.unix(powerPriceFormTmp.discountTimeByCapacityStart).format("YYYY-MM-DD"),
      // discountTimeByCapacityEnd: powerPriceFormTmp.discountTimeByCapacityEnd === "0" ? "" : moment.unix(powerPriceFormTmp.discountTimeByCapacityEnd).format("YYYY-MM-DD"),
    });
  }
  return (
    <>
      <Modal
        open={showModal}
        title="修改价格"
        width={"680px"}
        onCancel={cancelOperate}
        footer={[
          <Button onClick={cancelOperate}>取消</Button>,
          <Button onClick={operateData} loading={btnLoading} type="primary">
            确定
          </Button>,
        ]}
      >
        <div>
          <Form className="mr-10" label-position="right" labelCol={{ span: 5 }}>
            <Form.Item label="产品名称" required>
              <Input
                disabled
                onChange={(e: any) => inputPowerPriceFormText("productName", e)}
                defaultValue={powerPriceForm.productName}
              />
            </Form.Item>
            <Form.Item label="价格id" required>
              {powerPriceForm.priceId}
            </Form.Item>
            {subType !== "power" ? (
              <Form.Item label="免费容量GB" required>
                <Input
                  onChange={(e: any) =>
                    inputPowerPriceFormText("freeCapacity", e)
                  }
                  defaultValue={powerPriceForm.freeCapacity}
                />
              </Form.Item>
            ) : (
              ""
            )}
            <Form.Item label="计费模式" required>
              {/* <Checkbox.Group value={powerPriceForm.chargingModel} onChange={(e: any) => inputPowerPriceFormText("chargingModel", e, "value")}> */}
              <div style={{ lineHeight: "30px", gap: "5px" }}>
                {/* <Checkbox value={"capacity"}>按量计费</Checkbox> */}
                {powerPriceForm.chargingModel.includes("capacity") ? (
                  <div style={{ paddingLeft: "60px" }}>
                    <div style={{ lineHeight: "45px" }}>
                      <span style={{ paddingRight: "28px" }}>单价：</span>
                      <InputNumber
                        min={0}
                        precision={2}
                        style={{ width: "140px" }}
                        onChange={(e: any) =>
                          inputPowerPriceFormText("priceByCapacity", e, "value")
                        }
                        value={powerPriceForm.priceByCapacity}
                      />
                      <span style={{ padding: "0 0 0 10px" }}>折扣比例：</span>
                      <InputNumber
                        min={0}
                        max={1}
                        precision={2}
                        style={{ width: "140px" }}
                        onChange={(e: any) =>
                          inputPowerPriceFormText(
                            "discountRateByCapacity",
                            e,
                            "value"
                          )
                        }
                        value={powerPriceForm.discountRateByCapacity}
                      />
                    </div>
                    <div style={{ lineHeight: "45px" }}>
                      <label>折扣时间：</label>
                      <DatePicker.RangePicker
                        style={{ width: "360px" }}
                        onChange={(date: any) =>
                          inputPowerPriceFormText("ageingDate", date, "value")
                        }
                        value={powerPriceForm.ageingDate as any}
                      />
                    </div>
                    <div style={{ lineHeight: "45px" }}>
                      <label>折扣价格：</label>
                      <span>{powerPriceForm.priceAfterDiscount}</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* <div style={{lineHeight: "45px"}}>
                                    <Checkbox value={"day"}>包日</Checkbox>
                                    { powerPriceForm.chargingModel.includes("day") ? <div style={{display: "inline-block"}}>
                                        <span style={{paddingRight: "28px"}}>单价：</span>
                                        <InputNumber min={0} precision={2} style={{width: "140px"}} onChange={(e: any) => inputPowerPriceFormText("dayPrice", e, "value")} value={powerPriceForm.dayPrice} />
                                        <span style={{paddingLeft: "10px"}}>元/日</span>
                                    </div> : "" }
                                </div>
                                <div style={{lineHeight: "45px"}}>
                                    <Checkbox value={"week"}>包周</Checkbox>
                                    { powerPriceForm.chargingModel.includes("week") ? <div style={{display: "inline-block"}}>
                                        <span style={{paddingRight: "28px"}}>单价：</span>
                                        <InputNumber min={0} precision={2} style={{width: "140px"}} onChange={(e: any) => inputPowerPriceFormText("weekPrice", e, "value")} value={powerPriceForm.weekPrice} />
                                        <span style={{paddingLeft: "10px"}}>元/周</span>
                                    </div> : "" }
                                </div>
                                <div style={{lineHeight: "45px"}}>
                                    <Checkbox value={"month"}>包月</Checkbox>
                                    { powerPriceForm.chargingModel.includes("month") ? <div style={{display: "inline-block"}}>
                                        <span style={{paddingRight: "28px"}}>单价：</span>
                                        <InputNumber min={0} precision={2} style={{width: "140px"}} onChange={(e: any) => inputPowerPriceFormText("monthPrice", e, "value")} value={powerPriceForm.monthPrice} />
                                        <span style={{paddingLeft: "10px"}}>元/月</span>
                                    </div> : "" }
                                </div>
                                <div style={{lineHeight: "45px"}}>
                                    <Checkbox value={"year"}>包年</Checkbox>
                                    { powerPriceForm.chargingModel.includes("year") ? <div style={{display: "inline-block"}}>
                                        <span style={{paddingRight: "28px"}}>单价：</span>
                                        <InputNumber min={0} precision={2} style={{width: "140px"}} onChange={(e: any) => inputPowerPriceFormText("yearPrice", e, "value")} value={powerPriceForm.yearPrice} />
                                        <span style={{paddingLeft: "10px"}}>元/年</span>
                                    </div> : "" }
                                </div> */}
              {/* </Checkbox.Group> */}
            </Form.Item>
            <Form.Item label="是否生效" required>
              <Checkbox
                onChange={(e: any) =>
                  inputPowerPriceFormText("isEffect", e.target.checked, "value")
                }
                checked={powerPriceForm.isEffect}
              >
                生效
              </Checkbox>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
