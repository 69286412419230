import {
  Breadcrumb,
  Button,
  Space,
  Table,
  Form,
  Input,
  message,
  Modal,
  Tag,
} from "antd";
import styles from "../index.module.css";
import { useCallback, useEffect, useState } from "react";
import {
  addBalanceMonitor,
  deleteBalanceMonitor,
  getBalanceMonitor,
  updateBalanceMonitor,
  UserBalanceMonitor,
} from "@/api/pricing";
import { PaginationComp } from "@/components/pagination";
import { getEnv, globalEnv_zh } from "@/config";
import { ConfigModal } from "./ConfigModal";
import { Feishu_Uid_Map } from "@/constants/feishu";

const { Item } = Form;

export default function CreditLimit() {
  const { global } = getEnv();
  const columns = [
    {
      title: "UUID",
      dataIndex: "uuid",
      width: 240,
    },
    {
      title: "邮箱",
      dataIndex: "email",
      width: 160,
    },
    {
      title: "公司",
      dataIndex: "companyAlias",
    },
    {
      title: "手机号",
      dataIndex: "phone",
    },
    {
      title: globalEnv_zh === global ? "告警阀值(¥)" : "告警阀值($)",
      dataIndex: "threshold",
    },
    {
      title: "告警次数/天",
      dataIndex: "alertLimitPerDay",
    },
    {
      title: "最小提醒间隔(分钟)",
      dataIndex: "alertMinimum",
    },
    {
      title: "内部通知用户",
      dataIndex: "internalSubscriber",
      with: 200,
      render: (value: string[]) => {
        const arr = value.map((v) => {
          if (Feishu_Uid_Map[v]) {
            return Feishu_Uid_Map[v];
          }
          return v;
        });
        return (
          <div>
            {arr.map((one) => (
              <Tag key={one}>{one}</Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "是否开启",
      dataIndex: "enable",
      render: (value: boolean) => {
        return value ? "是" : "否";
      },
    },
    {
      title: "操作",
      dataIndex: "action",
      render: (_: any, record: UserBalanceMonitor) => (
        <Space>
          <Button
            type="link"
            onClick={() => {
              setOpen(true);
              setType("edit");
              setEditData(record);
            }}
            style={{
              padding: 0,
            }}
          >
            编辑
          </Button>
          <Button
            type="link"
            danger
            onClick={() => {
              handleDelete(record.uuid);
            }}
          >
            删除
          </Button>
        </Space>
      ),
    },
  ];
  const [pageParams, setPageParams] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  const [searchParams, setSearchParams] = useState({
    email: "",
    phone: "",
    uuid: "",
  });
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<UserBalanceMonitor[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<"add" | "edit">("add");
  const [editData, setEditData] = useState<
    UserBalanceMonitor | Record<string, any>
  >({});

  const fetchData = useCallback(() => {
    setLoading(true);
    getBalanceMonitor({
      ...pageParams,
      ...searchParams,
    })
      .then((res) => {
        if (Array.isArray(res.list)) {
          setData(res.list);
          setTotal(res.total);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageParams, searchParams]);

  const handleAdd = (values: any) => {
    addBalanceMonitor(values).then(() => {
      message.success("新增成功");
      fetchData();
      setOpen(false);
    });
  };

  const handleEdit = (values: any) => {
    updateBalanceMonitor(values).then(() => {
      message.success("编辑成功");
      fetchData();
      setOpen(false);
    });
  };

  const handleDelete = (uuid: string) => {
    Modal.confirm({
      title: "删除",
      content: "确定删除吗？",
      onOk: () => {
        deleteBalanceMonitor(uuid).then(() => {
          message.success("删除成功");
          fetchData();
        });
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, open]);

  return (
    <div>
      <div>
        <Breadcrumb
          items={[
            {
              title: "平台计费",
            },
            {
              title: "余额告警规则",
            },
          ]}
        />
      </div>
      <div className={styles.search_container}>
        <Form layout="inline">
          <Item label="邮箱">
            <Input
              placeholder="请输入邮箱"
              value={searchParams.email}
              onChange={(e) => {
                setSearchParams({
                  ...searchParams,
                  email: e.target.value,
                });
              }}
            />
          </Item>
          <Item label="手机号">
            <Input
              placeholder="请输入手机号"
              value={searchParams.phone}
              onChange={(e) => {
                setSearchParams({
                  ...searchParams,
                  phone: e.target.value,
                });
              }}
            />
          </Item>
          <Item label="UUID">
            <Input
              placeholder="请输入UUID"
              value={searchParams.uuid}
              onChange={(e) => {
                setSearchParams({
                  ...searchParams,
                  uuid: e.target.value,
                });
              }}
            />
          </Item>
          <Item>
            <Button
              type="primary"
              onClick={() => {
                setPageParams({
                  ...pageParams,
                  pageIndex: 1,
                });
              }}
            >
              查询
            </Button>
          </Item>
        </Form>
        <div style={{ margin: "16px 0" }}>
          <Button
            type="primary"
            onClick={() => {
              setOpen(true);
              setType("add");
              setEditData({});
            }}
          >
            新增余额告警规则
          </Button>
        </div>
      </div>
      <div className={styles.table_container}>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey={(record) => record.uuid}
          pagination={false}
        />
      </div>
      <div className={styles.pagination_container}>
        <PaginationComp
          total={total}
          pageNum={pageParams.pageIndex}
          pageSize={pageParams.pageSize}
          onChange={(pageIndex, pageSize) => {
            setPageParams({
              ...pageParams,
              pageIndex,
              pageSize,
            });
          }}
        />
      </div>
      <ConfigModal
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        type={type}
        onConfirm={(values) => {
          type === "add" ? handleAdd(values) : handleEdit(values);
        }}
        editData={editData}
        loading={loading}
        isZH={globalEnv_zh === global}
      />
    </div>
  );
}
