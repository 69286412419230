import { RootState } from "@/store";
import { Result } from "antd";
import { useSelector } from "react-redux";

export default function NoAuth() {
  const { resourceLoading } = useSelector((state: RootState) => state.user);
  return (
    <Result
      status="403"
      title={resourceLoading ? "loading..." : "403"}
      subTitle={
        resourceLoading
          ? "Permissions are being verified..."
          : "Sorry, you are not authorized to access this page."
      }
    />
  );
}
