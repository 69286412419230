import Sdk from "casdoor-js-sdk";
import { getIamConfig } from "../api/fetch";

export const initSSO = async () => {
  const iamData = await getIamConfig();
  const sdk = new Sdk({
    serverUrl: iamData.endpoint,
    clientId: iamData.clientId,
    appName: iamData.appName,
    organizationName: iamData.organization,
    redirectPath: "/",
  });

  return sdk;
};
