import { UserBalanceMonitor } from "@/api/pricing";
import { Feishu_Uid_Map } from "@/constants/feishu";
import { Modal, Form, Input, InputNumber, Divider, Select, Switch } from "antd";
import { useEffect } from "react";

export function ConfigModal({
  open,
  onCancel,
  type,
  onConfirm,
  isZH,
  loading,
  editData,
}: {
  open: boolean;
  onCancel: () => void;
  type: "add" | "edit";
  onConfirm: (values: any) => void;
  loading: boolean;
  isZH: boolean;
  editData: UserBalanceMonitor | Record<string, any>;
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    if (type === "edit") {
      form.setFieldsValue(editData);
    } else {
      form.resetFields();
    }
  }, [type, editData, form]);
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={type === "add" ? "添加" : "编辑"}
      confirmLoading={loading}
      onOk={() => {
        form.validateFields().then((values) => {
          console.log(values);
          onConfirm(values);
        });
      }}
    >
      <Form layout="vertical" form={form}>
        <div style={{ marginBottom: "20px", fontWeight: 500 }}>
          下面任选其一即可
        </div>
        {isZH ? (
          <>
            <Form.Item label="UUID" name="uuid">
              <Input />
            </Form.Item>
            <Form.Item label="手机号" name="phone">
              <Input />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item label="UUID" name="uuid">
              <Input />
            </Form.Item>
            <Form.Item label="邮箱" name="email">
              <Input />
            </Form.Item>
          </>
        )}
        <Divider style={{ margin: "30px 0" }} />
        <Form.Item label="阈值" name="threshold">
          <Input />
        </Form.Item>
        <Form.Item label="每天提醒次数" name="alertLimitPerDay">
          <InputNumber controls={false} />
        </Form.Item>
        <Form.Item label="最小提醒间隔(分钟)" name="alertMinimum">
          <InputNumber controls={false} />
        </Form.Item>
        <Form.Item label="内部通知用户" name="internalSubscriber">
          <Select
            mode="multiple"
            allowClear
            showSearch
            filterOption={(input, option) =>
              ((option?.label as string) ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            options={Object.entries(Feishu_Uid_Map).map(([value, label]) => {
              return {
                value,
                label,
              };
            })}
          ></Select>
        </Form.Item>
        <Form.Item label="是否开启" name="enable">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
}
