// 显卡型号管理
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Pagination, Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import EditGpuModel from "./editGpuModel";
import { useEffect, useState } from "react";
import { handleShowResource } from "@/utils";
import { getGpuInfos, addEditGupInfo, deleteGupInfo } from "@/api/business";

export default function GpuModelManage() {
  const [modalInfo, setModalInfo] = useState({
    showModal: false,
    type: "Add",
    gpuModelInfo: {},
  });
  const [resultTable, setResultTable] = useState({ data: [], total: 0 });
  const [params, setParams] = useState({
    pageNo: 1,
    pageSize: 10,
    name: "",
    accuracy: "",
  });
  const [editGpuModelBtnLoading, setEditGpuModelBtnLoading] = useState(false);

  function getTableData(pageNo?: any, pageSize?: any) {
    console.log(params);
    getGpuInfos({
      ...params,
      pageNo: !isNaN(Number(pageNo)) ? pageNo : params.pageNo,
      pageSize: !isNaN(Number(pageSize)) ? pageSize : params.pageSize,
    }).then((res: any) => {
      console.log(res);
      setResultTable({ data: res.data || [], total: Number(res.total) });
      setParams({
        ...params,
        pageNo: isNaN(Number(pageNo)) ? params.pageNo : pageNo,
        pageSize: isNaN(Number(pageSize)) ? params.pageSize : pageSize,
      });
    });
  }
  
  useEffect(() => {
    getTableData();
  }, []);

  function addGpuModel(gpuModelInfo: any) {
    if (!gpuModelInfo) {
      setModalInfo({ showModal: true, type: "Add", gpuModelInfo: {} });
    } else {
      setModalInfo({ showModal: true, type: "Edit", gpuModelInfo });
    }
  }
  function finishOperate(
    cancelMark: boolean,
    type: string,
    gpuModelInfo: {
      id?: any;
      name: any;
      accuracy: any;
    }
  ) {
    if (!cancelMark) {
      console.log("cancelMark:", cancelMark);
      console.log("type:", type);
      console.log("gpuModelInfo:", gpuModelInfo);
      setModalInfo({ ...modalInfo, showModal: false });
    } else {
      console.log("cancelMark:", cancelMark);
      console.log("type:", type);
      console.log("gpuModelInfo:", gpuModelInfo);
      setEditGpuModelBtnLoading(true);
      addEditGupInfo({
        id: gpuModelInfo.id,
        name: gpuModelInfo.name,
        accuracy: gpuModelInfo.accuracy,
      }).then(() => {
        message.success("操作成功");
        setModalInfo({ ...modalInfo, showModal: false });
        getTableData();
      }).finally(() => {
        setEditGpuModelBtnLoading(false);
      });
    }
  }
  function searchData() {
    getTableData(1);
  }

  function inputSetParamsText(item: string, e: any, valueType = "") {
    const paramsTmp: any = params;
    paramsTmp[item] = valueType === "value" ? e : e.target.value;
    setParams({ ...paramsTmp });
  }
  const columns: ColumnsType<any> = [
    {
      title: "显卡名称",
      width: 300,
      dataIndex: "name",
    },
    {
      title: "描述",
      width: 300,
      dataIndex: "accuracy",
    },
    {
      title: "操作",
      width: 100,
      render: (_: any, record: any) => {
        return (
          <div>
            {
              handleShowResource("meta-bussiness-graphic-edit") && <Button
                style={{ paddingLeft: "0" }}
                type="link"
                onClick={() => addGpuModel(record)}
              >
                修改
              </Button>
            }
            {
              handleShowResource("meta-bussiness-graphic-delete") && <Button
                  onClick={() => {
                    Modal.confirm({
                      width: "460px",
                      title: "删除",
                      icon: <ExclamationCircleOutlined />,
                      content: (
                        <>
                          <span style={{ color: "#303133" }}>确认删除当前显卡型号（{record?.name}）？</span>
                        </>
                      ),
                      okText: "删除",
                      cancelText: "取消",
                      onOk: () => {
                        deleteGupInfo({ id: record?.id }).then((res: any) => {
                          console.log("deleteGupInfo res: ", res);
                          message.success("删除成功");
                          getTableData(1);
                        }).catch((err: any) => {
                          console.log("error:", err);
                        });
                      },
                    });
                  }}
                type="primary" danger ghost>
                删除
              </Button>
            }
          </div>
        );
      },
    },
  ];
  function changePagination(page: any, pageSize: any) {
    console.log("pageNo:", page);
    console.log("pageSize:", pageSize);
    console.log("params.pageNo:", params.pageNo);
    console.log("params.pageSize:", params.pageSize);
    if (pageSize !== params.pageSize) {
      // setParams({...params, pageNo: 1, pageSize, total: 0})
      getTableData(1, pageSize);
    } else {
      // setParams({...params, pageNo: page, pageSize, total: 0})
      getTableData(page, pageSize);
    }
  }
  return (
    <div style={{ padding: "10px" }}>
      <div>
        {/* <span style={{ marginBottom: "20rem", fontSize: "16px", fontWeight: "bolder" }}>
        显卡型号管理
      </span> */}
        <div>
          <div>
            <Form>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="显卡名称：">
                  <Input
                    onChange={(e: any) => inputSetParamsText("name", e)}
                    style={{ width: "200px" }}
                  />
                </Form.Item>
              </div>
              {/* <div style={{display: "inline-block", marginRight: "20px"}}>
                <Form.Item label="精度：">
                  <Input maxLength={30} style={{width: "200px"}} onChange={(e: any) => inputSetParamsText("accuracy", e)} />
                </Form.Item>
              </div> */}
              <Button
                onClick={searchData}
                style={{ marginRight: "20px" }}
                type="primary"
              >
                查询
              </Button>
              {
                handleShowResource("meta-business-graphics-add") && <Button type="primary" onClick={addGpuModel}>
                  新增
                </Button>
              }
            </Form>
          </div>
          <Table
            columns={columns}
            dataSource={resultTable.data}
            pagination={false}
          ></Table>
          {resultTable.total > 0 ? (
            <Pagination
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                float: "right",
              }}
              pageSize={Number(params.pageSize)}
              current={Number(params.pageNo)}
              total={resultTable.total}
              showSizeChanger
              showQuickJumper
              onChange={(page: number, pageSize: number) =>
                changePagination(page, pageSize)
              }
              showTotal={(total) => `共 ${total} 条`}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {modalInfo.showModal ? (
        <EditGpuModel
          btnLoading={editGpuModelBtnLoading}
          finishOperate={finishOperate}
          showModal={modalInfo.showModal}
          type={modalInfo.type}
          gpuModelInfo={modalInfo.gpuModelInfo}
        />
      ) : (
        ""
      )}
    </div>
  );
}
