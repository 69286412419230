import { getBaseUrl } from "@/config";
import { message } from "antd";
import Cookies from "js-cookie";

export function request({
  url,
  method = "GET",
  data = undefined,
  query = undefined,
  baseUrl,
  token,
  cache,
  catchErr = true,
}: {
  url: string;
  method?: string;
  data?: any;
  query?: any;
  baseUrl?: string;
  token?: string;
  cache?: string;
  catchErr?: boolean;
}) {
  let base_url = getBaseUrl();
  if (baseUrl) {
    base_url = baseUrl;
  }
  const CookiesToken = Cookies.get("token");
  let fetchUrl = base_url + url;
  if (query) {
    fetchUrl += "?" + new URLSearchParams(query).toString();
  }
  return fetch(fetchUrl, {
    method: method,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-auth-type": "iam",
      Authorization:
        token !== undefined
          ? token
          : CookiesToken
          ? `Bearer ${CookiesToken}`
          : "",
      "Cache-Control": cache ? cache : "no-cache",
    },
    cache: cache ? "default" : "no-cache",
    body: data == undefined ? undefined : JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode === 429) {
        catchErr && message.error("请求过于频繁，请稍后再试");
        return Promise.reject(429);
      }
      if (res.code === 401) {
        Cookies.remove("token");
        window.location.href = "/";
        return Promise.reject(401);
      }
      if (res.code >= 500 || (res.code >= 400 && res.code < 500)) {
        catchErr && message.error(res.message);
        return Promise.reject(res.message);
      }
      return Promise.resolve(res);
    })
    .catch((err) => {
      console.log("fetch error", err);
      return Promise.reject(err);
    });
}

// auth
export function getIamConfig() {
  return request({
    url: "/api/v1/admin/iam/config",
  });
}

export function authLogin(params: { code: string; state: string }) {
  return request({
    url: "/api/v1/user/code2token",
    method: "POST",
    data: {
      ...params,
    },
  });
}

export function getUserResource() {
  return request({
    url: "/api/v1/admin/resource",
    cache: "max-age=10",
  });
}
