import { useState } from "react";
import { Button, Form, Input, Modal, Radio, Select } from "antd";
import { CONTINENT_ITEMS, PROVINCE_ITEMS } from "./constants"

export default function EditCluster({type="Add", showModal=false, clusterInfo={}, finishOperate, btnLoading}: {
    type: string, // "Add" | "Edit",
    showModal: boolean,
    clusterInfo: any,
    finishOperate: (success: boolean, type: string, data: any) => void,
    btnLoading: boolean
}) {
    let dataTmp: any = {
        clusterName: "",
        largeCategory: "Domestic",
        continent: "",
        province: "",
        sortedWeight: ""
    };
    if (type !== "Add") {
        dataTmp = clusterInfo;
    }
    const [clusterForm, setClusterForm] = useState<{
        clusterId?: any,
        clusterName: any,
        largeCategory: any,
        continent: any,
        province: any,
        sortedWeight: any}>(dataTmp)
    function cancelOperate() {
        finishOperate(false, "", {});
    }
    function operateData() {
        finishOperate(true, type, clusterForm);
    }
    function inputClusterFormText(item: string, e: any, valueType=""){
        const clusterFormTmp: any = clusterForm;
        clusterFormTmp[item] = valueType === "value" ? e : e.target.value;
        setClusterForm({...clusterFormTmp});
    }
    return <>
        <Modal open={ showModal } title={type === "Add" ? "添加集群" : "修改集群"} width={"400px"} onCancel={cancelOperate} footer={[
            <Button onClick={cancelOperate}>取消</Button>,
            <Button type="primary" loading={btnLoading} onClick={operateData}>确定</Button>
        ]}>
            <div className="flex flex-row">
                <div style={{width: "100%"}}>
                    <Form className="mr-10" label-position="right" labelCol={{span: 6}}>
                        <Form.Item label="集群名称" required>
                            <Input maxLength={30} onChange={(e: any) => inputClusterFormText("clusterName", e)} value={clusterForm.clusterName}/>
                        </Form.Item>
                        <Form.Item label="大类" required>
                            <Radio.Group onChange={(e: any) => inputClusterFormText("largeCategory", e)} value={clusterForm.largeCategory} defaultValue="Domestic" buttonStyle="solid">
                                <Radio.Button value="Domestic">国内</Radio.Button>
                                <Radio.Button value="International">海外</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        {
                            clusterForm.largeCategory === "International" ? <Form.Item label="洲" required>
                            <Select options={CONTINENT_ITEMS} onChange={(e: any) => inputClusterFormText("continent", e, "value")} value={clusterForm.continent}></Select>
                        </Form.Item> : "" }
                        {
                            clusterForm.largeCategory === "Domestic" ? <Form.Item label="省份" required>
                            {/* <Input onChange={(e: any) => inputClusterFormText("province", e)} value={clusterForm.province}/> */}
                            <Select options={PROVINCE_ITEMS} onChange={(e: any) => inputClusterFormText("province", e, "value")} value={clusterForm.province}></Select>
                        </Form.Item> : "" }
                        <Form.Item label="排序权重" required>
                            <Input onChange={(e: any) => inputClusterFormText("sortedWeight", e)} value={clusterForm.sortedWeight}/>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    </>
}
