import { Checkbox, Form, Input, message, Modal, Select } from "antd";
import { ServerlessProductForm } from "../type";
import { useEffect, useState } from "react";
import {
  addServerlessProduct,
  editServerlessProduct,
} from "@/api/product";
import { queryAccountWithoutCatchErr } from "@/api/user";
import { getEnv, globalEnv_global } from "@/config"

export function EditModal({
  open,
  onClose,
  type,
  data,
}: {
  open: boolean;
  onClose: () => void;
  type: "add" | "edit";
  data: ServerlessProductForm;
}) {
  const { global } = getEnv();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  function getWhitelistUsers(fields: any) {
    const whitelistUsersTmp: any[] = [];
    (fields.whitelist || []).forEach((ele: any) => {
      const item = whitelistUsers.find((item: any) => item.uuid === ele);
      if (item) {
        whitelistUsersTmp.push(item);
      }
    });
    return whitelistUsersTmp;
  }

  const addProduct = async () => {
    const fields = form.getFieldsValue();
    try {
      setLoading(true);
      let whitelistUsersTmp: any[] = [];
      if (fields.isWhitelistEnabled) {
        whitelistUsersTmp = getWhitelistUsers(fields);
      }
      await addServerlessProduct({
        ...fields,
        whitelist: fields.isWhitelistEnabled ? (fields.whitelist || []) : [],
        whitelistUsers: fields.isWhitelistEnabled ? (whitelistUsersTmp || []) : [],
      });
      message.success("新增成功");
      onClose();
    } catch (error) {
      console.error(error);
      message.error("新增失败");
    } finally {
      setLoading(false);
    }
  };

  const editProduct = async () => {
    const fields = form.getFieldsValue();
    try {
      setLoading(true);
      let whitelistUsersTmp: any[] = [];
      if (fields.isWhitelistEnabled) {
        whitelistUsersTmp = getWhitelistUsers(fields);
      }
      await editServerlessProduct({
        ...fields,
        id: data.id,
        whitelist: fields.isWhitelistEnabled ? (fields.whitelist || []) : [],
        whitelistUsers: fields.isWhitelistEnabled ? (whitelistUsersTmp || []) : [],
      });
      message.success("编辑成功");
      onClose();
    } catch (error) {
      console.error(error);
      message.error("编辑失败");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type === "edit") {
      form.setFieldsValue({
        ...data
      });
    } else {
      form.resetFields();
    }
    setValue(data.whitelist || []);
    setWhitelistUsers(data.whitelistUsers || []);
  }, [data, form, type]);

  const handleSubmit = () => {
    if (type === "add") {
      addProduct();
    } else {
      editProduct();
    }
  };
  const [value, setValue] = useState<string[]>(data.whitelist || []);
  const [whitelistUsers, setWhitelistUsers] = useState<any[]>(data.whitelistUsers || []);
  let timeout: ReturnType<typeof setTimeout> | null;
  let currentValue: string;
  
  const fetch = (value: string, callback: (data: any) => void) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
  
    const fake = () => {
      queryAccountWithoutCatchErr(value).then((res: any) => {
        console.log("queryAccountWithoutCatchErr res: ", res);
        if (currentValue === value) {
          callback(res);
        }
      })
    };
    if (value.length > 0) {
      timeout = setTimeout(fake, 300);
    } else {
      callback(null);
    }
  };
  function dealData(data: any) {
    if (!data) {
      return;
    }
    if (whitelistUsers.map((ele: any) => ele.uuid).includes(data.uuid)) {
      return;
    } else {
      const newData = [...whitelistUsers, data];
      setWhitelistUsers(newData);
    }
  }
  const handleSearch = (newValue: string) => {
    fetch(newValue, dealData);
  };

  const handleChangeTmp = (newValue: string[]) => {
    setValue(newValue);
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={type === "add" ? "新增产品" : "编辑产品"}
      onOk={handleSubmit}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="名称" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="GPU 名称" name="gpuName">
          <Input />
        </Form.Item>
        <Form.Item label="描述" name="models">
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="isWhitelistEnabled" valuePropName="checked">
          <Checkbox>开启白名单（输入uuid或{global === globalEnv_global ? "email地址" : "手机号"}进行搜索）</Checkbox>
        </Form.Item>
        <Form.Item label="白名单" name="whitelist">
          <Select
            mode="multiple"
            showSearch
            value={value}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChangeTmp}
            notFoundContent={null}
            options={(whitelistUsers || []).map((d) => ({
            value: d.uuid,
            label:  global === globalEnv_global ? d.email : d.phone,
          }))}
          />
        </Form.Item>
        <Form.Item label="GPU 大小" name="gpuSize">
          <Input />
        </Form.Item>
        <Form.Item label="Infra GPU 名称" name="infraGpuName">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
