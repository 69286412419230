import {
  getEnv,
  globalEnv_global,
  globalEnv_zh,
  runEnv_prod,
  runEnv_test,
  setEnv,
} from "@/config";
import { logout } from "@/store/slice/userSlice";
import { Modal, Radio } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export function ConfigModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const dispatch = useDispatch();
  const [gEnv, setGEnv] = useState(globalEnv_global);
  const [rEnv, setREnv] = useState(runEnv_test);

  useEffect(() => {
    const { global, run } = getEnv();
    global && setGEnv(global);
    run && setREnv(run);
  }, []);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="环境配置"
      onOk={() => {
        const { run } = getEnv();
        setEnv(gEnv, rEnv);
        if (run !== rEnv) {
          dispatch(logout());
          window.localStorage.removeItem("user_resource");
          window.location.reload();
          return;
        } else {
          onCancel();
          window.location.reload();
        }
      }}
    >
      <div style={{ marginTop: 20 }}>
        <Radio.Group
          onChange={(e) => {
            setGEnv(e.target.value);
          }}
          value={gEnv}
        >
          <Radio.Button value={globalEnv_global}>海外</Radio.Button>
          <Radio.Button value={globalEnv_zh}>国内</Radio.Button>
        </Radio.Group>
      </div>
      <div style={{ marginTop: 20 }}>
        <Radio.Group
          onChange={(e) => {
            setREnv(e.target.value);
          }}
          value={rEnv}
        >
          <Radio.Button value={runEnv_test}>测试</Radio.Button>
          <Radio.Button value={runEnv_prod}>生产</Radio.Button>
        </Radio.Group>
      </div>
    </Modal>
  );
}
