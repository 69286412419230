import { Breadcrumb, Button, Form, Input, Select, Space, Table, Tag } from "antd";
import styles from "./index.module.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ProductPrice } from "./types";
import { ColumnType } from "antd/es/table";
import { BusinessLineList } from "@/constants";
import { getProductSummary, getProductType } from "@/api/pricing";
import { displayPriceFormat, removeObjectEmptyValues } from "@/utils";
import { PaginationComp } from "@/components/pagination";
import { UpdatePriceModal } from "./components/updatePriceModal";
import { useQuery } from "@/hooks/useQuery";
import { hasSetPriceResource } from "@/constants/auth";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
const { Item } = Form;

export function ProductManager() {
  const query = useQuery();
  const { resource } = useSelector((state: RootState) => state.user);
  const columns: ColumnType<ProductPrice>[] = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "产品ID",
      dataIndex: "productId",
    },
    {
      title: "产品名称",
      dataIndex: "displayName",
      render(_: unknown, record: ProductPrice) {
        if (Array.isArray(record.regionNames) && record.regionNames.length > 0) {
          return (
            <div>
              {record.displayName}
              <div>
                {record.regionNames.map((one) => (
                  <Tag key={one}>
                    {one}
                  </Tag>
                ))}
              </div>
            </div>
          );
        }
        return record.displayName;
      },
    },
    {
      title: "业务线",
      dataIndex: "businessType",
    },
    {
      title: "产品分类",
      dataIndex: "productCategory",
    },
    {
      title: "目录价格",
      dataIndex: "basePrice",
      render(_: unknown, record: ProductPrice) {
        if (record.productCategory === "llm") {
          return (
            <div>
              <div>input / output ({record.displayPriceUnit})</div>
              <span>
                {displayPriceFormat(record.basePrice0, record.pricePrecision)}
                {" / "}
                {displayPriceFormat(record.basePrice1, record.pricePrecision)}
              </span>
            </div>
          );
        }
        return `${displayPriceFormat(record.basePrice0, record.pricePrecision)} 
                ${record.displayPriceUnit}`;
      },
    },
    {
      title: "红线价格",
      dataIndex: "redlinePrice",
      render(_: unknown, record: ProductPrice) {
        if (record.productCategory === "llm") {
          return (
            <div>
              <div>input / output ({record.displayPriceUnit})</div>
              <span>
                {displayPriceFormat(
                  record.redlinePrice0,
                  record.pricePrecision
                )}
                {" / "}
                {displayPriceFormat(
                  record.redlinePrice1,
                  record.pricePrecision
                )}
              </span>
            </div>
          );
        }
        return `${displayPriceFormat(
          record.redlinePrice0,
          record.pricePrecision
        )} 
        ${record.displayPriceUnit}`;
      },
    },
    {
      title: "操作",
      dataIndex: "action",
      width: 160,
      render: (_: unknown, record: ProductPrice) => {
        return (
          <div className={styles.action_container}>
            {hasSetPriceResource(resource) && (
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => {
                  setUpdatePriceModal({
                    open: true,
                    defaultValues: record,
                  });
                }}
              >
                修改价格
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<ProductPrice[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageParams, setPageParams] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [selectedBusinessType, setSelectedBusinessType] = useState<string>("");
  const [productCategoryList, setProductCategoryList] = useState<any[]>([]);
  const [updatePriceModal, setUpdatePriceModal] = useState<{
    open: boolean;
    defaultValues: ProductPrice | null;
  }>({
    open: false,
    defaultValues: null,
  });

  const isFirstQuery = useRef(true);

  const categoryOptions = useMemo(() => {
    if (!selectedBusinessType || selectedBusinessType === "") {
      return productCategoryList;
    }
    return productCategoryList.filter(
      (one) => one.businessType === selectedBusinessType
    );
  }, [productCategoryList, selectedBusinessType]);

  const resetUrlParams = useCallback(() => {
    query.delete("productId");
    query.delete("businessType");
    window.history.replaceState({}, "", `${window.location.pathname}`);
  }, [query]);

  const fetchData = useCallback(
    (query?: Record<string, string>) => {
      setLoading(true);
      const params = form.getFieldsValue();
      const cleanParams = removeObjectEmptyValues(params);
      const urlParams = removeObjectEmptyValues(query ?? {});
      getProductSummary({
        ...urlParams,
        ...pageParams,
        ...cleanParams,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            setDataSource(res.list);
            setTotal(res.total);
            if (
              res.list.length === 1 &&
              query?.productId &&
              isFirstQuery.current &&
              hasSetPriceResource(resource)
            ) {
              setUpdatePriceModal({
                open: true,
                defaultValues: res.list[0],
              });
              isFirstQuery.current = false;
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [pageParams, form, resource]
  );

  useEffect(() => {
    if (!updatePriceModal.open) {
      const productId = query.get("productId");
      const businessType = query.get("businessType");
      fetchData({
        productId: productId ?? "",
        businessType: businessType ?? "",
      });
    }
  }, [fetchData, updatePriceModal.open, query]);

  useEffect(() => {
    getProductType({}).then((res) => {
      if (Array.isArray(res.data)) {
        setProductCategoryList(
          res.data.map((one: any) => ({
            category: one.category,
            businessType: one.businessType,
          }))
        );
      }
    });
  }, []);

  return (
    <div>
      <div>
        <Breadcrumb
          items={[
            {
              title: "平台计费",
            },
            {
              title: "产品价格",
            },
          ]}
        />
      </div>
      <div className={styles.search_container}>
        <div className={styles.search_form}>
          <Form layout="inline" form={form}>
            <Item label="产品名称" name="displayName">
              <Input />
            </Item>
            <Item label="业务线" name="businessType">
              <Select
                style={{ width: 160 }}
                options={BusinessLineList}
                allowClear
                value={selectedBusinessType}
                onChange={(value) => {
                  setSelectedBusinessType(value);
                }}
              />
            </Item>
            <Item label="产品分类" name="productCategory">
              <Select
                style={{ width: 160 }}
                options={categoryOptions.map((one) => ({
                  label: one.category,
                  value: one.category,
                }))}
                allowClear
              />
            </Item>
            <Item>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    fetchData();
                  }}
                >
                  搜索
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields();
                    setPageParams({
                      pageIndex: 1,
                      pageSize: 10,
                    });
                    resetUrlParams();
                    fetchData();
                  }}
                >
                  重置
                </Button>
              </Space>
            </Item>
          </Form>
        </div>
      </div>
      <div className={styles.table_container}>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={loading}
        />
      </div>
      <div className={styles.pagination_container}>
        <PaginationComp
          total={total}
          pageSize={pageParams.pageSize}
          pageNum={pageParams.pageIndex}
          onChange={(page, pageSize) => {
            setPageParams({
              pageIndex: page,
              pageSize,
            });
          }}
        />
      </div>
      <UpdatePriceModal
        open={updatePriceModal.open}
        onClose={() => {
          setUpdatePriceModal({ open: false, defaultValues: null });
        }}
        defaultValues={updatePriceModal.defaultValues}
      />
    </div>
  );
}
