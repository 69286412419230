import { useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
} from "antd";
import dayjs from "dayjs";

export default function EditVoucherTemplate({
  type = "Add",
  showModal = false,
  voucherTemplateInfo = {},
  finishOperate,
  btnLoading,
}: {
  type: string; // "Add" | "Edit",
  showModal: boolean;
  voucherTemplateInfo: any;
  finishOperate: (
    success: boolean,
    type: string,
    voucherTemplateForm: any
  ) => void;
  btnLoading: boolean;
}) {
  let dataTmp: any = {
    name: "",
    ageing: "",
    days: "",
    startDate: "",
    endDate: "",
    amountMoney: "",
    availableStatus: false,
    ageingDate: [null, null],
  };
  if (type !== "Add") {
    dataTmp = voucherTemplateInfo;
  }
  // debugger
  console.log("dataTmp:", dataTmp);
  if (dataTmp.startDate) {
    dataTmp.ageingDate = [
      dayjs.unix(Number(dataTmp.startDate)),
      dayjs.unix(Number(dataTmp.endDate)),
    ];
  } else {
    dataTmp.ageingDate = [null, null];
  }
  const [voucherTemplateForm, setVoucherTemplateForm] = useState<{
    id?: any;
    name: any;
    ageing: any;
    days: any;
    startDate: any;
    endDate: any;
    amountMoney: any;
    availableStatus: any;
    ageingDate: any;
    businessTypes: any;
  }>(dataTmp);
  function cancelOperate() {
    finishOperate(false, "", {});
  }
  function operateData() {
    finishOperate(true, type, voucherTemplateForm);
  }
  function inputVoucherTemplateFormText(item: string, e: any, valueType = "") {
    const voucherTemplateFormTmp: any = { ...voucherTemplateForm };
    voucherTemplateFormTmp[item] = valueType === "value" ? e : e.target.value;
    setVoucherTemplateForm({ ...voucherTemplateFormTmp });
  }
  return (
    <>
      <Modal
        open={showModal}
        title={type === "Add" ? "添加代金券模板" : "修改代金券模板"}
        onCancel={cancelOperate}
        footer={[
          <Button onClick={cancelOperate}>取消</Button>,
          <Button loading={btnLoading} type="primary" onClick={operateData}>
            确定
          </Button>,
        ]}
      >
        <div className="flex flex-row">
          <div style={{ width: "100%" }}>
            <Form
              className="mr-10"
              label-position="right"
              labelCol={{ span: 6 }}
            >
              <Form.Item label="代金券名称" required>
                <Input
                  onChange={(e: any) => inputVoucherTemplateFormText("name", e)}
                  value={voucherTemplateForm.name}
                />
              </Form.Item>
              <Form.Item label="代金券时效" required>
                {/* <Input onChange={(e: any) => inputVoucherTemplateFormText("ageing", e)} value={voucherTemplateForm.ageing}/> */}
                <Radio.Group
                  value={voucherTemplateForm.ageing}
                  onChange={(e: any) =>
                    inputVoucherTemplateFormText("ageing", e)
                  }
                >
                  <Radio value={"dynamic"}>动态时段</Radio>
                  <Radio value={"fixed"}>固定时段</Radio>
                </Radio.Group>
              </Form.Item>
              {voucherTemplateForm.ageing === "dynamic" ? (
                <Form.Item label="">
                  {/* <Input onChange={(e: any) => inputVoucherTemplateFormText("ageing", e)} value={voucherTemplateForm.ageing}/> */}
                  <span style={{ float: "right", marginLeft: "10px" }}>天</span>
                  <Input
                    style={{ width: "70%", float: "right" }}
                    placeholder="从代金券发放之日起设定有效天数内有效"
                    onChange={(e: any) =>
                      inputVoucherTemplateFormText("days", e)
                    }
                    value={voucherTemplateForm.days}
                  />
                </Form.Item>
              ) : (
                ""
              )}
              {voucherTemplateForm.ageing === "fixed" ? (
                <Form.Item label="">
                  {/* <Input onChange={(e: any) => inputVoucherTemplateFormText("ageing", e)} value={voucherTemplateForm.ageing}/> */}
                  <div style={{ width: "75%", float: "right" }}>
                    <div>设置具体的起止日期； 起止时段均是闭区间；</div>
                    <DatePicker.RangePicker
                      style={{ width: "354px" }}
                      value={voucherTemplateForm.ageingDate}
                      onChange={(date: any) =>
                        inputVoucherTemplateFormText(
                          "ageingDate",
                          date,
                          "value"
                        )
                      }
                    />
                  </div>
                </Form.Item>
              ) : (
                ""
              )}
              <Form.Item label="代金券金额" required>
                <Input
                  onChange={(e: any) =>
                    inputVoucherTemplateFormText("amountMoney", e)
                  }
                  value={voucherTemplateForm.amountMoney}
                />
              </Form.Item>
              <Form.Item label="可用状态" required>
                <Checkbox
                  onChange={(e: any) =>
                    inputVoucherTemplateFormText(
                      "availableStatus",
                      e.target.checked,
                      "value"
                    )
                  }
                  checked={voucherTemplateForm.availableStatus}
                >
                  可用
                </Checkbox>
              </Form.Item>
              <Form.Item label="业务类型" required>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  defaultValue={voucherTemplateForm.businessTypes || []}
                  onChange={(e: any) =>
                    inputVoucherTemplateFormText("businessTypes", e, "value")
                  }
                  options={[
                    {
                      label: "All",
                      value: "all",
                    },
                    {
                      label: "gpu_instance",
                      value: "gpu_instance",
                    },
                    {
                      label: "model_api",
                      value: "model_api",
                    },
                    {
                      label: "serverless",
                      value: "serverless",
                    },
                  ]}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}
