import { getDiscountDetail } from "@/api/pricing";
import { displayPriceFormat } from "@/utils";
import { Modal, Table } from "antd";
import { useCallback, useEffect, useState } from "react";

export function DisDetail({
  open,
  onCancel,
  id,
}: {
  open: boolean;
  onCancel: () => void;
  id: string;
}) {
  const columns = [
    {
      title: "产品Id",
      dataIndex: "productId",
    },
    {
      title: "产品名称",
      dataIndex: "displayName",
    },
    {
      title: "业务类型",
      dataIndex: "businessType",
    },
    {
      title: "产品类型",
      dataIndex: "productCategory",
    },
    {
      title: "目录价",
      dataIndex: "basePrice0",
      render(_: unknown, record: any) {
        return displayPriceFormat(record.basePrice0, record.pricePrecision);
      },
    },
    {
      title: "折扣价",
      dataIndex: "discountPrice0",
      render(v: string, record: any) {
        return displayPriceFormat(v, record.pricePrecision);
      },
    },
  ];
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getDiscountDetail(id);
      if (Array.isArray(res.list)) {
        setDataSource(res.list);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [id]);
  useEffect(() => {
    if (id && open) {
      fetchData();
    }
  }, [id, open, fetchData]);
  return (
    <Modal
      title="折扣详情"
      open={open}
      onCancel={onCancel}
      width={1000}
      confirmLoading={loading}
      onOk={onCancel}
      footer={null}
    >
      <div
        style={{
          maxHeight: 500,
          overflowY: "auto",
        }}
      >
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={loading}
        />
      </div>
    </Modal>
  );
}
