import React, { useCallback, useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  Menu,
  MenuProps,
  message,
  Spin,
  theme,
} from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./Layout.module.css";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { RootState } from "../store";
import { useQuery } from "../hooks/useQuery";
import {
  getResource,
  logout,
  setEmail,
  setIsLogin,
  setUserInfo,
} from "../store/slice/userSlice";
import { authLogin } from "../api/fetch";
import { findRoute, getPermissionRouteList } from "../routes";
import { initSSO } from "../utils/sso";
import AvatarIcon from "@/assets/common/avatar.png";
import { ConfigModal } from "@/components/env-config/configModal";
import { getEnvText } from "@/config";

const { Header, Sider, Content } = Layout;

interface LayoutProps {
  children?: React.ReactNode;
  noPadding?: boolean;
}

const Main: React.FC<LayoutProps> = ({
  children,
  noPadding = false,
}: LayoutProps) => {
  const routes = getPermissionRouteList();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const query = useQuery();
  const email = useSelector((state: RootState) => state.user.email);
  const isLogin = useSelector((state: RootState) => state.user.isLogin);
  const [loading, setLoading] = useState(false);
  const resourceLoading = useSelector(
    (state: RootState) => state.user.resourceLoading
  );
  const envText = getEnvText();

  const [collapsed, setCollapsed] = useState(false);
  const [activeKey, setActiveKey] = useState(routes[0].key);
  const [configModalOpen, setConfigModalOpen] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const items: MenuProps["items"] = [
    {
      key: "logout",
      label: (
        <div
          className={styles.menu_item}
          onClick={() => {
            dispatch(logout());
            navigate("/login");
          }}
        >
          退出登录
        </div>
      ),
    },
  ];

  const handleSSO = useCallback(async () => {
    const code = query.get("code");
    const state = query.get("state");
    const token = Cookies.get("token");
    if (isLogin && token) {
      return;
    }
    const sdk = await initSSO();
    if (token) {
      try {
        setLoading(true);
        const userInfo: any = await sdk.getUserInfo(token);
        dispatch(setEmail(userInfo.preferred_username));
        dispatch(setIsLogin(true));
        dispatch(getResource() as any);
        dispatch(setUserInfo(userInfo));
      } catch (error) {
        console.error(error);
        Cookies.remove("token");
      } finally {
        setLoading(false);
      }
    } else if (code && state) {
      try {
        setLoading(true);
        const res = await authLogin({ code, state });
        if (res?.accessToken) {
          Cookies.set("token", res.accessToken, { expires: 7 });
          const userInfo: any = await sdk.getUserInfo(res.accessToken);
          dispatch(setEmail(userInfo.preferred_username));
          dispatch(setIsLogin(true));
          dispatch(getResource() as any);
          dispatch(setUserInfo(userInfo));
          navigate("/");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      navigate("/login");
    }
  }, [query, navigate, dispatch, isLogin]);

  useEffect(() => {
    handleSSO();
  }, [handleSSO]);

  useEffect(() => {
    // find routes
    const item = findRoute(pathname);
    if (item) {
      setActiveKey([item.key]);
    }
  }, [pathname]);

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={activeKey}
          items={routes}
          onSelect={({ key }) => {
            if (loading || resourceLoading) {
              message.info("请稍等，资源加载中...");
              return;
            }
            const route = findRoute(key);
            if (route) {
              navigate(route.path);
              setActiveKey([key]);
            }
          }}
        />
      </Sider>
      <Layout>
        <Header
          style={{ padding: 0, background: colorBgContainer }}
          className={styles.header}
        >
          <div>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "20px",
                width: 64,
                height: 64,
              }}
            />
            <Button
              type="text"
              icon={<SettingOutlined />}
              style={{
                fontSize: "20px",
                width: 64,
                height: 64,
              }}
              onClick={() => setConfigModalOpen(true)}
            />
          </div>
          <div style={{ fontSize: "18px", fontWeight: 700 }}>{envText}</div>
          <div className={styles.avatar}>
            {email ? (
              <Dropdown menu={{ items }} arrow>
                <div>
                  <Avatar src={AvatarIcon} /> <span>{email}</span>
                </div>
              </Dropdown>
            ) : (
              <Button onClick={() => navigate("/login")}>去登录</Button>
            )}
          </div>
        </Header>
        <Spin spinning={resourceLoading || loading}>
          <Content
            style={{
              margin: "24px 16px",
              padding: noPadding ? 0 : 24,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              height: "calc(100vh - 64px - 48px)",
              overflow: "auto",
            }}
          >
            {children ? children : <Outlet />}
          </Content>
        </Spin>
        <ConfigModal
          open={configModalOpen}
          onCancel={() => setConfigModalOpen(false)}
        />
      </Layout>
    </Layout>
  );
};

export default Main;
