import { request } from "./fetch";

export function getGpuModelList(params: any) {
  return request({
    url: "/v1/admin/gpu/model/list",
    query: params,
  });
}

export function getGpuInfos(params: any) {
  return request({
    url: "/v1/admin/metadata/gpuinfos",
    query: params,
  });
}

export function deleteGupInfo(params: any) {
  return request({
    url: "/v1/admin/metadata/gpuinfo/delete",
    method: "POST",
    data: params,
  });
}

export function addEditGupInfo(params: any) {
  return request({
    url: "/v1/admin/metadata/gpuinfo",
    method: "POST",
    data: params,
  });
}

export function getClusters(params: any) {
  return request({
    url: "/v1/admin/metadata/clusters",
    query: params,
  });
}

export function getPowerProducts(params: any) {
  return request({
    url: "/v1/admin/metadata/gpuspecs",
    query: params,
  });
}

export function addEditPowerProductInfo(params: any) {
  return request({
    url: "/v1/admin/metadata/gpuspec",
    method: "POST",
    data: params,
  });
}

export function setPowerProductPriceInfo(params: any) {
  return request({
    url: "/v1/admin/metadata/gpu/price",
    method: "POST",
    data: params,
  });
}

export function delPowerProductInfo(params: any) {
  return request({
    url: "/v1/admin/metadata/gpuspec/delete",
    method: "POST",
    data: params,
  });
}

export function getStorageProducts(params: any) {
  return request({
    url: "/v1/admin/metadata/storagespecs",
    query: params,
  });
}

export function addEditStorageProductInfo(params: any) {
  return request({
    url: "/v1/admin/metadata/storagespec",
    method: "POST",
    data: params,
  });
}

export function setStorageProductPriceInfo(params: any) {
  return request({
    url: "/v1/admin/metadata/storage/price",
    method: "POST",
    data: params,
  });
}

export function delStorageProductInfo(params: any) {
  return request({
    url: "/v1/admin/metadata/storagespec/delete",
    method: "POST",
    data: params,
  });
}

export function addEditClusterInfo(params: any) {
  return request({
    url: "/v1/admin/metadata/cluster",
    method: "POST",
    data: params,
  });
}

export function delClusterInfo(params: any) {
  return request({
    url: "/v1/admin/metadata/cluster/delete",
    method: "POST",
    data: params,
  });
}
