import { request } from "./fetch";

// 获取价格模版
export function getPricingTemplate() {
  return request({
    url: "/v1/admin/price/templates",
  });
}

// 获取args参数
export function getArgsConfig() {
  return request({
    url: "/v1/admin/price/args",
  });
}

// 获取产品信息
export function getProductInfo() {
  return request({
    url: "/v1/admin/price/products",
  });
}

// 获取计价信息
export function getPricingInfo(params: { template_id: string }) {
  return request({
    url: "/v1/admin/price/info",
    query: params,
  });
}

interface UpdatePricingInfoParams {
  template_id: string;
  base_price: number;
  price_unit: number;
  price_precision: string;
  extra_cost: number;
}

// 修改计价信息
export function updatePricingInfo(params: UpdatePricingInfoParams) {
  return request({
    url: "/v1/admin/price/infos",
    method: "POST",
    data: params,
  });
}

// 获取信控配置
export function getCreditLimit(params: any) {
  return request({
    url: "/v1/admin/credit/list",
    query: params,
  });
}

// 查询用户信控配置
export function getUserCreditLimit(params: {
  uuid: string;
  phone: string;
  email: string;
}) {
  return request({
    url: "/v1/admin/userBalance",
    query: params,
  });
}

// 保存信控配置
export function saveCreditLimit(params: { uuid: string; credit: number }) {
  return request({
    url: "/v1/admin/credit",
    method: "PUT",
    data: params,
  });
}

export interface UserBalanceMonitor {
  id: string;
  uuid: string;
  phone: string;
  email: string;
  // 阈值
  threshold: number;
  // 每天提醒次数
  alertLimitPerDay: number;
  // 最小提醒间隔，单位分钟
  alertMinimum: number;
  // 是否开启
  enable: boolean;
  // 内部用户
  internalSubscriber: string[];
  // 优惠券数据
  voucher: {
    modelApi: number;
    gpuInstance: number;
    serverless: number;
  };
}

// 余额监控查询
export function getBalanceMonitor(params: any): Promise<{
  list: UserBalanceMonitor[];
  total: number;
}> {
  return request({
    url: "/v1/admin/balance-monitor-rule/list",
    query: params,
  });
}

// 新增余额监控
export function addBalanceMonitor(params: any) {
  return request({
    url: "/v1/admin/balance-monitor-rule",
    method: "POST",
    data: params,
  });
}

// 修改余额监控
export function updateBalanceMonitor(params: any) {
  return request({
    url: "/v1/admin/balance-monitor-rule",
    method: "PUT",
    data: params,
  });
}

// 删除余额监控
export function deleteBalanceMonitor(uuid: string) {
  return request({
    url: "/v1/admin/balance-monitor-rule",
    method: "DELETE",
    query: {
      uuid,
    },
  });
}

// 获取产品价格
export function getProductSummary(params: any) {
  return request({
    url: "/v1/admin/product-summary/list",
    query: params,
  });
}

// 更新产品价格
export function updateProductSummary(params: any) {
  return request({
    url: "/v1/admin/product-summary",
    method: "PUT",
    data: params,
  });
}

// 创建产品分类
export function createProductType(params: any) {
  return request({
    url: "/v1/admin/product-category",
    method: "POST",
    data: params,
  });
}

// 查询产品分类
export function getProductType(params: any) {
  return request({
    url: "/v1/admin/product-category/list",
    query: params,
  }).then((res) => {
    if (Array.isArray(res.data)) {
      res.data.sort((a: any[], b: any[]) => Number(a.sort) - Number(b.sort));
    }
    return res;
  });
}

// 删除产品分类
export function deleteProductType(id: string) {
  return request({
    url: "/v1/admin/product-category",
    method: "DELETE",
    query: { id },
  });
}

// 获取折扣列表
export function getDiscountList(params: any) {
  return request({
    url: "/v1/admin/product-discount/list",
    query: params,
  });
}

// 创建折扣
export function createDiscount(params: any) {
  return request({
    url: "/v1/admin/product-discount",
    method: "POST",
    data: params,
  });
}

// 修改折扣
export function updateDiscount(params: any) {
  return request({
    url: "/v1/admin/product-discount",
    method: "PUT",
    data: params,
  });
}

// 删除折扣
export function deleteDiscount(id: string) {
  return request({
    url: "/v1/admin/product-discount",
    method: "DELETE",
    query: { id },
  });
}

// 获取折扣详情
export function getDiscountDetail(id: string) {
  return request({
    url: "/v1/admin/product-discount/detail",
    query: { id },
  });
}

// 查询当前产品有多少折扣生效
export function getProductDiscount(params: any) {
  return request({
    url: "/v1/admin/product-info/discount",
    query: { ...params },
  });
}

// 创建对公账单/对公充值
export function createPublicBill(params: any) {
  return request({
    url: "/v1/admin/order/public-remittance-recharge",
    method: "POST",
    data: params,
  });
}
