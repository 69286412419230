import Big from "big.js";

export function dealMoney(originPrice = 0, accuracy = 2, type?: string) {
  if (type === "ceil") {
    return (
      Math.ceil((originPrice / 100000) * Math.pow(10, accuracy)) /
      Math.pow(10, accuracy)
    );
  } else {
    return (
      Math.round((originPrice / 100000) * Math.pow(10, accuracy)) /
      Math.pow(10, accuracy)
    );
  }
}
export enum BillingType {
  HourlyBilling = "HourlyBilling",
  Afterusage = "afterusage",
  PrepaidByDay = "prepaidByDay",
  PrepaidByWeek = "prepaidByWeek",
  PrepaidByMonth = "prepaidByMonth",
  PrepaidByYear = "prepaidByYear",
}

export function getBillingTypeName(type: BillingType): string {
  switch (type) {
    case BillingType.HourlyBilling:
    case BillingType.Afterusage:
      return "按量付费";
    case BillingType.PrepaidByDay:
      return "包日";
    case BillingType.PrepaidByWeek:
      return "包周";
    case BillingType.PrepaidByMonth:
      return "包月";
    case BillingType.PrepaidByYear:
      return "包年";
  }
  return "其他";
}

export const balanceFormat = (value: number) => {
  return new Big(value).div(10_000).toString();
};

export function handleShowResource(key: string) {
  if (typeof window === "undefined") {
    return false;
  }
  const resourceList = JSON.parse(
    localStorage.getItem("user_resource") || "{}"
  );
  return (
    resourceList?.resource?.includes(key) ||
    !!resourceList?.resource?.find((item: string) => item.startsWith(key))
  );
}

export function removeObjectEmptyValues(obj: Record<string, any>) {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value != null && value !== "")
  );
}

export const displayPriceFormat = (
  value: number | null | undefined | string,
  unit: number | null = 1
) => {
  if (value === null || value === undefined) return 0;
  if (unit === null || unit === undefined || Number(unit) === 0) return 0;
  if (Number.isNaN(Number(value)) || Number.isNaN(Number(unit))) return value;
  return new Big(value)
    .div(10000)
    .div(unit ?? 1)
    .toString();
};

export const realPriceFormat = (
  value: number | null | undefined,
  unit: number | null = 1
) => {
  if (value === null || value === undefined) return 0;
  if (Number.isNaN(Number(value)) || Number.isNaN(Number(unit))) return value;
  return new Big(value)
    .mul(10000)
    .mul(unit ?? 1)
    .toString();
};
