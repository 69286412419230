import { DiscountType } from "../types";
import styles from "./style.module.css";
import { Radio } from "antd";

export function SelectDiscountType({
  applyRange,
  applyRangeChange,
  allowActivity,
}: {
  applyRange: DiscountType;
  applyRangeChange: (value: DiscountType) => void;
  allowActivity: boolean;
}) {
  return (
    <div className={styles.content}>
      <Radio.Group
        value={applyRange}
        onChange={(e) => {
          applyRangeChange(e.target.value);
        }}
      >
        <Radio value="exclusive">专属折扣</Radio>
        <Radio value="all" disabled={!allowActivity}>活动折扣</Radio>
      </Radio.Group>
    </div>
  );
}
