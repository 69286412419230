import { Form, InputNumber, message, Modal } from "antd";
import { BusinessType, ProductPrice } from "../types";
import { useCallback, useEffect, useState } from "react";
import {
  displayPriceFormat,
  realPriceFormat,
  removeObjectEmptyValues,
} from "@/utils";
import { updateProductSummary } from "@/api/pricing";

export function UpdatePriceModal({
  open,
  onClose,
  defaultValues,
}: {
  open: boolean;
  onClose: () => void;
  defaultValues: ProductPrice | null;
}) {
  const { businessType } = defaultValues || {};
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (defaultValues) {
      form.setFieldsValue({
        ...defaultValues,
        basePrice0: displayPriceFormat(
          defaultValues.basePrice0,
          defaultValues.pricePrecision
        ),
        redlinePrice0: displayPriceFormat(
          defaultValues.redlinePrice0,
          defaultValues.pricePrecision
        ),
        basePrice1: displayPriceFormat(
          defaultValues.basePrice1,
          defaultValues.pricePrecision
        ),
        redlinePrice1: displayPriceFormat(
          defaultValues.redlinePrice1,
          defaultValues.pricePrecision
        ),
      });
    }
  }, [defaultValues, form]);

  const updateProduct = useCallback(() => {
    const values = form.getFieldsValue();
    const cleanValues = removeObjectEmptyValues(values);

    if (!cleanValues.basePrice0 && cleanValues.basePrice0 != 0) {
      message.info("请输入目录价格");
      return;
    }

    if (!cleanValues.redlinePrice0 && cleanValues.redlinePrice0 != 0) {
      message.info("请输入红线价格");
      return;
    }

    if (cleanValues.basePrice0 < cleanValues.redlinePrice0) {
      message.info("目录价格必须大于红线价格");
      return;
    }

    if (cleanValues?.basePrice1 < cleanValues?.redlinePrice1) {
      message.info("目录价格必须大于红线价格");
      return;
    }

    setLoading(true);
    updateProductSummary({
      ...defaultValues,
      ...cleanValues,
      basePrice0: realPriceFormat(
        cleanValues.basePrice0,
        defaultValues?.pricePrecision
      ),
      redlinePrice0: realPriceFormat(
        cleanValues.redlinePrice0,
        defaultValues?.pricePrecision
      ),
      basePrice1: realPriceFormat(
        cleanValues.basePrice1,
        defaultValues?.pricePrecision
      ),
      redlinePrice1: realPriceFormat(
        cleanValues.redlinePrice1,
        defaultValues?.pricePrecision
      ),
    })
      .then(() => {
        onClose();
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, [defaultValues, form, onClose]);

  if (!defaultValues) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`修改 ${defaultValues.displayName} 价格`}
      onOk={updateProduct}
      onCancel={onClose}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical">
        {businessType !== BusinessType.ModelApi ? (
          <>
            <Form.Item
              name="basePrice0"
              label={`目录价格 （${defaultValues.displayPriceUnit}）`}
              rules={[{ required: true }]}
            >
              <InputNumber min={0} controls={false} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="redlinePrice0"
              label={`红线价格 （${defaultValues.displayPriceUnit}）`}
              rules={[{ required: true }]}
            >
              <InputNumber min={0} controls={false} style={{ width: "100%" }} />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              name="basePrice0"
              label={`input_token 价格 （${defaultValues.displayPriceUnit}）`}
              rules={[{ required: true }]}
            >
              <InputNumber min={0} controls={false} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="basePrice1"
              label={`output_token 价格 （${defaultValues.displayPriceUnit}）`}
              rules={[{ required: true }]}
            >
              <InputNumber min={0} controls={false} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="redlinePrice0"
              label={`input_token 红线价格 （${defaultValues.displayPriceUnit}）`}
              rules={[{ required: true }]}
            >
              <InputNumber min={0} controls={false} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="redlinePrice1"
              label={`output_token 红线价格 （${defaultValues.displayPriceUnit}）`}
              rules={[{ required: true }]}
            >
              <InputNumber min={0} controls={false} style={{ width: "100%" }} />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
}
