import { Breadcrumb, Button, Form, Input, Table } from "antd";
import styles from "../index.module.css";
import { useCallback, useEffect, useState } from "react";
import { getCreditLimit } from "@/api/pricing";
import Big from "big.js";
import { ConfigModal } from "./configModal";
import { AddConfigModal } from "./addConfigModal";

const { Item } = Form;

export default function CreditLimit() {
  const columns = [
    {
      title: "UUID",
      dataIndex: "uuid",
      width: 340,
    },
    {
      title: "邮箱",
      dataIndex: "email",
      width: 260,
    },
    {
      title: "公司",
      dataIndex: "companyAlias",
    },
    {
      title: "手机号",
      dataIndex: "phone",
    },
    {
      title: "自动充值配置",
      dataIndex: "autoRecharge",
      render: (value: Record<string, any>) => {
        return value?.isAutoRecharge
          ? `小于$${value?.threshold}, 自动充值到$${value?.rechargeAmount}`
          : "未开启";
      },
    },
    {
      title: (
        <span
          style={{
            color: "red",
            fontWeight: 700,
          }}
        >
          用户可用额度
        </span>
      ),
      width: 200,
      dataIndex: "_userBalance",
      render: (_: any, record: any) => {
        // 用户额度 + 信控额度 + 优惠券额度
        return (
          <div>
            <div>
              <span
                style={{
                  display: "inline-block",
                  width: 80,
                  marginRight: 10,
                }}
              >
                GPU:
              </span>
              {new Big(record?.voucher?.gpuInstance ?? 0)
                .add(record?.credit)
                .add(record?.userBalance)
                .div(10_000)
                .toString()}
            </div>
            <div>
              <span
                style={{
                  display: "inline-block",
                  width: 80,
                  marginRight: 10,
                }}
              >
                Model API:
              </span>
              {new Big(record?.voucher?.modelApi ?? 0)
                .add(record?.credit)
                .add(record?.userBalance)
                .div(10_000)
                .toString()}
            </div>
            <div>
              <span
                style={{
                  display: "inline-block",
                  width: 80,
                  marginRight: 10,
                }}
              >
                Serverless:
              </span>
              {new Big(record?.voucher?.serverless ?? 0)
                .add(record?.credit)
                .add(record?.userBalance)
                .div(10_000)
                .toString()}
            </div>
          </div>
        );
      },
    },
    {
      title: "信控额度",
      dataIndex: "credit",
      render: (value: string) => {
        return Big(value).div(10_000).toString();
      },
    },
    {
      title: "优惠券额度",
      dataIndex: "voucherBalance",
      render: (_: any, record: any) => {
        return (
          <div>
            <div>
              GPU:
              {new Big(record?.voucher?.gpuInstance).div(10_000).toString()}
            </div>
            <div>
              Model API:
              {new Big(record?.voucher?.modelApi).div(10_000).toString()}
            </div>
            <div>
              Serverless:
              {new Big(record?.voucher?.serverless).div(10_000).toString()}
            </div>
          </div>
        );
      },
    },
    {
      title: "用户余额",
      dataIndex: "userBalance",
      render: (value: string) => {
        return Big(value).div(10000).toString();
      },
    },

    {
      title: "操作",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <Button
          type="link"
          onClick={() => {
            setEditData(record);
            setOpen(true);
          }}
          style={{
            padding: 0,
          }}
        >
          编辑
        </Button>
      ),
    },
  ];
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [editData, setEditData] = useState<any>(null);

  const [searchParams, setSearchParams] = useState({
    email: "",
    phone: "",
    uuid: "",
  });

  const fetchData = useCallback(() => {
    setLoading(true);
    getCreditLimit(searchParams)
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams]);

  useEffect(() => {
    fetchData();
  }, [fetchData, open, addOpen]);

  return (
    <div>
      <div>
        <Breadcrumb
          items={[
            {
              title: "平台计费",
            },
            {
              title: "信控额度",
            },
          ]}
        />
      </div>
      <div className={styles.search_container}>
        <Form layout="inline">
          <Item label="邮箱">
            <Input
              placeholder="请输入邮箱"
              value={searchParams.email}
              onChange={(e) => {
                setSearchParams({
                  ...searchParams,
                  email: e.target.value,
                });
              }}
            />
          </Item>
          <Item label="手机号">
            <Input
              placeholder="请输入手机号"
              value={searchParams.phone}
              onChange={(e) => {
                setSearchParams({
                  ...searchParams,
                  phone: e.target.value,
                });
              }}
            />
          </Item>
          <Item label="UUID">
            <Input
              placeholder="请输入UUID"
              value={searchParams.uuid}
              onChange={(e) => {
                setSearchParams({
                  ...searchParams,
                  uuid: e.target.value,
                });
              }}
            />
          </Item>
          <Item>
            <Button
              type="primary"
              onClick={() => {
                fetchData();
              }}
            >
              查询
            </Button>
          </Item>
        </Form>
        <div style={{ margin: "16px 0" }}>
          <Button
            type="primary"
            onClick={() => {
              setAddOpen(true);
            }}
          >
            新增信控配置
          </Button>
        </div>
      </div>

      <div className={styles.table_container}>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey={(record) => record.uuid}
          scroll={{ x: "max-content" }}
        />
      </div>
      <ConfigModal
        open={open}
        onClose={() => setOpen(false)}
        editData={editData}
      />
      <AddConfigModal open={addOpen} onClose={() => setAddOpen(false)} />
    </div>
  );
}
