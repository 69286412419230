// 国内还是海外
// global, zh
export const globalEnv = "_GLOBAL_DEV";
export const globalEnv_zh = "zh";
export const globalEnv_global = "global";

// 生产还是测试
// test, prod
export const runEnv = "_RUN_DEV";
export const runEnv_test = "test";
export const runEnv_prod = "prod";

// 是否开发环境
export const IS_DEV = process.env.NODE_ENV === "development";

// export const NestServerUrl = "http://localhost:3000";
export const NestServerUrl = "https://nestjs-service-novita-ai.vercel.app";

export function initEnv() {
  if (typeof window !== "undefined") {
    const origin = window.location.origin;
    const isDefaultProd = origin.includes("prod");
    const global = window.localStorage.getItem(globalEnv);
    if (!global) {
      window.localStorage.setItem(globalEnv, globalEnv_global);
    }
    const run = window.localStorage.getItem(runEnv);
    if (!run) {
      window.localStorage.setItem(
        runEnv,
        isDefaultProd ? runEnv_prod : runEnv_test
      );
    }
    // 设置title
    document.title = getEnvText() + " - 管理后台";
  }
}

export function getBaseUrl() {
  const gEnv = window.localStorage.getItem(globalEnv);
  const rEnv = window.localStorage.getItem(runEnv);

  if (gEnv === globalEnv_global) {
    if (rEnv === runEnv_test) {
      return "https://dev-api-server.novita.ai";
    } else if (rEnv === runEnv_prod) {
      return "https://api-server.novita.ai";
    }
  } else if (gEnv === globalEnv_zh) {
    if (rEnv === runEnv_test) {
      return "https://dev-api-admin.ppinfra.com";
    } else if (rEnv === runEnv_prod) {
      return "https://api-admin.ppinfra.com";
    }
  }

  return "https://dev-api-server.novita.ai";
}

export function getStrapiUrl() {
  const gEnv = window.localStorage.getItem(globalEnv);
  const rEnv = window.localStorage.getItem(runEnv);

  // if (process.env.NODE_ENV === "development") {
  //   return "http://localhost:1337";
  // }

  if (gEnv === globalEnv_global) {
    if (rEnv === runEnv_test) {
      return "https://dev-strapi.pplabs.tech";
    } else if (rEnv === runEnv_prod) {
      return "https://strapi.pplabs.tech";
    }
  } else if (gEnv === globalEnv_zh) {
    if (rEnv === runEnv_test) {
      return "https://dev-strapi.paigod.work";
    } else if (rEnv === runEnv_prod) {
      return "https://prod-strapi.paigod.work";
    }
  }

  return "https://dev-strapi.pplabs.tech";
}

export function getSubAppUrl() {
  const rEnv = window.localStorage.getItem(runEnv);

  if (rEnv === runEnv_test) {
    return "https://dev-admin.novita.ai";
  }

  return "https://dev-admin.novita.ai";
}

export function getEnvText() {
  const gEnv = window.localStorage.getItem(globalEnv);
  const rEnv = window.localStorage.getItem(runEnv);

  const origin = gEnv === globalEnv_global ? "novita.ai" : "ppinfra";
  const env = rEnv === runEnv_test ? "测试" : "生产";

  return `${origin} - ${env}`;
}

export function getEnv() {
  const gEnv = window.localStorage.getItem(globalEnv);
  const rEnv = window.localStorage.getItem(runEnv);

  return {
    global: gEnv,
    run: rEnv,
  };
}

export function setEnv(global: string, run: string) {
  window.localStorage.setItem(globalEnv, global);
  window.localStorage.setItem(runEnv, run);
}
