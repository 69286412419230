export enum BusinessType {
  ModelApi = "model_api",
  GPU = "gpu_instance",
  Serverless = "serverless",
}

export interface ProductPrice {
  id: string;
  // 业务线
  businessType: BusinessType;
  // 产品分类
  productCategory: string;
  // 产品 ID
  productId: string;
  // 内部展示名
  innerName: string;
  // 对外展示名
  displayName: string;
  // 基础价格
  basePrice0: number;
  // 基础价格 1
  basePrice1?: number | null;
  // 基础价格 2
  basePrice2?: number | null;
  // 基础价格 3
  basePrice3?: number | null;
  // 基础价格 4
  basePrice4?: number | null;
  // 红线价格 0
  redlinePrice0: number | null;
  // 红线价格 1
  redlinePrice1?: number | null;
  // 红线价格 2
  redlinePrice2?: number | null;
  // 红线价格 3
  redlinePrice3?: number | null;
  // 红线价格 4
  redlinePrice4?: number | null;
  // 价格单位
  priceUnit: number | null;
  // 价格精度
  pricePrecision: number | null;
  // 价格展示单位
  displayPriceUnit: string | null;
  // 上架 / 下架
  status: boolean;
  // 关联集群
  regionNames: string[];
}
