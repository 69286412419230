import { displayPriceFormat } from "@/utils";
import { ErrorBoundary } from "react-error-boundary";

export function TablePricingColumn({
  pricing,
  onClick,
  isLLM,
}: {
  pricing: any;
  onClick: (productId: string) => void;
  isLLM?: boolean;
}) {
  return (
    <ErrorBoundary fallback={<div>价格信息获取失败</div>}>
      {!pricing ? null : (
        <div
          onClick={() => {
            if (pricing.discountType) {
              onClick(pricing.productId);
            }
          }}
          style={{
            cursor: "pointer",
            color: pricing.discountType ? "#1677ff" : "#000",
            fontWeight: pricing.discountType ? 500 : "",
          }}
        >
          <div>
            目录价：{" "}
            <span>
              {displayPriceFormat(pricing.basePrice0, pricing.pricePrecision)}{" "}
              {isLLM ? (
                <span>
                  {" / "}
                  {displayPriceFormat(
                    pricing.basePrice1,
                    pricing.pricePrecision
                  )}
                </span>
              ) : null}
            </span>
          </div>
          {pricing.discountType && (
            <div>
              {pricing.discountType != "discount_rate" ? (
                <span>
                  折扣价：{" "}
                  {displayPriceFormat(
                    pricing.discountPrice0,
                    pricing.pricePrecision
                  )}
                  {isLLM ? (
                    <span>
                      {" / "}
                      {displayPriceFormat(
                        pricing.discountPrice1,
                        pricing.pricePrecision
                      )}
                    </span>
                  ) : null}
                </span>
              ) : (
                <span>
                  折扣比例：{" "}
                  {!Number.isNaN(Number(pricing.discountRate))
                    ? pricing.discountRate + "%"
                    : ""}
                </span>
              )}
            </div>
          )}
        </div>
      )}
    </ErrorBoundary>
  );
}
