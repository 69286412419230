import { request } from "./fetch";

export function queryAccount(account: string) {
  return request({
    url: "/v1/admin/user-account",
    query: { account },
  });
}

export function queryAccountWithoutCatchErr(account: string) {
  return request({
    url: "/v1/admin/user-account",
    query: { account },
    catchErr: false,
  });
}