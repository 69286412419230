import { useEffect, useState } from "react";
import { getPermissionRouteList } from "../../routes";
import { AppIcon } from "./AppIcon";
import styles from "./index.module.css";

export default function Home() {
  const [routerList, setRouterList] = useState<any[]>([]);
  const routes = getPermissionRouteList();

  useEffect(() => {
    // 递归获取所有route
    // 如果存在children, 那么只渲染children部分
    const getRouterList = (routes: any[]): any[] => {
      return routes.reduce((pre, route) => {
        if (Array.isArray(route.children)) {
          return pre.concat(
            getRouterList([
              ...route.children.map((one: any) => ({
                ...one,
                parentLabel: route.label,
              })),
            ])
          );
        }
        if (route.key === "dashboard") {
          // 跳过
          return pre;
        }
        return pre.concat(route);
      }, []);
    };

    const routerList = getRouterList(routes);
    setRouterList(routerList);
  }, [routes]);

  return (
    <div className={styles.bg}>
      <div className={styles.app_container}>
        {routerList.map((route) => (
          <AppIcon
            key={route.key}
            icon={route.iconurl}
            url={route.path}
            label={route.label}
            parentLabel={route.parentLabel}
          />
        ))}
      </div>
    </div>
  );
}
