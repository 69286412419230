import Cookies from "js-cookie";
import { useEffect } from "react";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { initSSO } from "../../utils/sso";


export default function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      navigate("/dashboard");
    } else {
      initSSO().then((sdk) => {
        window.location.href = sdk.getSigninUrl();
      });
    }
  }, [navigate]);

  return (
    <div className={styles.container}>
      <h1>Novita.ai Login page</h1>
    </div>
  );
}
