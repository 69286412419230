import { Pagination } from "antd";

export function PaginationComp({
  total,
  pageSize,
  pageNum,
  onChange,
}: {
  total: number;
  pageSize: number;
  pageNum: number;
  onChange: (pageNum: number, pageSize: number) => void;
}) {
  return (
    <Pagination
      total={total}
      pageSize={pageSize}
      current={pageNum}
      onChange={onChange}
      showSizeChanger
      showQuickJumper
      showTotal={(total) => `共 ${total} 条`}
    />
  );
}
