import {
  Breadcrumb,
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Table,
} from "antd";
import styles from "./index.module.css";
import { BusinessLineList } from "@/constants";
import { useCallback, useEffect, useState } from "react";
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  createProductType,
  deleteProductType,
  getProductType,
} from "@/api/pricing";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { hasDeleteProductResource, hasEditProductResource } from "@/constants/auth";
import { updateProductTypeSort } from "@/api/product";


interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row: React.FC<Readonly<RowProps>> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

export function ProductTypeManager() {
  const { resource } = useSelector((state: RootState) => state.user);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [businessType, setBusinessType] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [isDrag, setIsDrag] = useState(false);
  const [form] = Form.useForm();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setIsDrag(true);
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.id === active.id);
        const overIndex = prev.findIndex((i) => i.id === over?.id);
        const newData = arrayMove(prev, activeIndex, overIndex);
        return newData;
      })
    }
  };

  const fetchData = useCallback(() => {
    getProductType({
      businessType: businessType ? businessType : "",
    })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setDataSource(res.data.sort((a: any[], b: any[]) => Number(a.sort) - Number(b.sort)));
        }
      })
      .finally(() => {
      });
  }, [businessType]);

  const handleDelete = useCallback(
    (id: string) => {
      Modal.confirm({
        title: "确认删除",
        content: "确认删除该产品类型吗？",
        onOk: () => {
          deleteProductType(id).then(() => {
            message.success("删除成功");
            fetchData();
          });
        },
      });
    },
    [fetchData]
  );

  const handleOk = useCallback(() => {
    form.validateFields().then((values) => {
      createProductType(values).then(() => {
        message.success("新增成功");
        fetchData();
        form.resetFields();
        setOpen(false);
      });
    });
  }, [form, fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (isDrag) {
      updateProductTypeSort({
        sorts: dataSource.map((i, index) => ({ id: Number(i.id), sort: index })),
      }).then(() => {
        message.success("更新排序成功");
      });
    }
  }, [dataSource, isDrag]);

  return (
    <div>
      <div>
        <Breadcrumb
          items={[
            {
              title: "元数据",
            },
            {
              title: "产品类型",
            },
          ]}
        />
      </div>
      <div className={styles.search_container}>
        <div className={styles.search_form}>
          <Space>
            <Select
              placeholder="请选择业务类型"
              options={BusinessLineList}
              style={{ width: 200 }}
              value={businessType}
              onChange={(value) => {
                setBusinessType(value);
              }}
            ></Select>
            <Button type="primary" onClick={fetchData}>
              查询
            </Button>
            <Button onClick={() => setBusinessType(undefined)}>重置</Button>
          </Space>
          <div>
            {hasEditProductResource(resource) && (
              <Button type="primary" onClick={() => setOpen(true)}>
                新增
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.table_container}>
        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={dataSource.map((i) => i.id)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              rowKey="id"
              components={{
                body: { row: Row },
              }}
              columns={[
                {
                  title: "业务类型",
                  dataIndex: "businessType",
                },
                {
                  title: "产品类型",
                  dataIndex: "category",
                },
                {
                  title: "操作",
                  render: (_: unknown, record: any) => {
                    return hasDeleteProductResource(resource) && (
                      <Button danger onClick={() => handleDelete(record.id)}>删除</Button>
                    );
                  },
                },
              ]}
              dataSource={dataSource}
              pagination={false}
            ></Table>
          </SortableContext>
        </DndContext>
      </div>
      <Modal open={open} onCancel={() => setOpen(false)} onOk={handleOk}>
        <Form layout="vertical" form={form}>
          <Form.Item
            label="业务类型"
            name="businessType"
            rules={[{ required: true, message: "请选择业务类型" }]}
          >
            <Select
              options={BusinessLineList}
              style={{ width: 200 }}
              placeholder="请选择业务类型"
            ></Select>
          </Form.Item>
          <Form.Item
            label="产品类型"
            name="category"
            rules={[{ required: true, message: "请输入产品类型" }]}
          >
            <Input style={{ width: 200 }} placeholder="请输入产品类型" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
